.slider-- .dot{
    
    width: 1rem;
    height: 1rem;
    border-radius: 10px;
}
.slider-- .line{
    height: 4.25rem;
    width: .25rem;
    
}
.slider-- .status-success{
    background-color: var(--bs-primary);
}
.slider-- .status-pending{
    background-color: var(--bs-gray-400);
}
.slider-- .status-content{
    width: 100%;

}