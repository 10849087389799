.referral-copy {
    padding: 4px;
    flex-direction: row-reverse;
    display: flex;
    justify-content: space-around;
    background: var(--bs-gray-500);
    height: 2rem;
    width: 10rem;
    border-radius: 4px;
}

.status-slider {
    height: 7px;
    border-radius: 10px;
}

.letter-avatar {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 5px;
}

@media screen and (min-width: 992px) {
    .referral_banner {
        max-width: 24rem !important;
    }

    .referral-container {
        display: flex;
        align-items: end;
    }
}

@media screen and (max-width:992px) {

    .referral-container {
        display: flex;
        flex-direction: column;
        /* align-items: baseline; */
    }
}

@media screen and (min-width:767px) {
    .profile-card-container {
        height: 25rem;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}