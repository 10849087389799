@import url(https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&amp;display=swap);

.accordion {
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 2px
}

.accordion__item+.accordion__item {
    border-top: 1px solid rgba(0, 0, 0, .1)
}

.accordion__button {
    background-color: #f4f4f4;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none
}

.accordion__button:hover {
    background-color: #ddd
}

.accordion__button:before {
    display: inline-block;
    content: "";
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.accordion__button[aria-expanded=true]:before, .accordion__button[aria-selected=true]:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

[hidden] {
    display: none
}

.accordion__panel {
    padding: 20px;
    -webkit-animation: fadein .35s ease-in;
    animation: fadein .35s ease-in
}

@-webkit-keyframes fadein {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes fadein {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

:root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #0095C7;
    --bs-secondary: #eafaff;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 13, 110, 253;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, hsla(0, 0%, 100%, 0.15), hsla(0, 0%, 100%, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff
}

*, :after, :before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

@media(prefers-reduced-motion:no-preference) {
    :root {
        scroll-behavior: smooth
    }
}

body {
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

hr {
    margin: 1rem 0;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: .25
}

hr:not([size]) {
    height: 1px
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: .5rem;
    font-weight: 500
}

.h1, h1 {
    font-size: -webkit-calc(1.375rem + 1.5vw);
    font-size: calc(1.375rem + 1.5vw)
}

@media(min-width:1200px) {
    .h1, h1 {
        font-size: 2.5rem
    }
}

.h2, h2 {
    font-size: -webkit-calc(1.325rem + .9vw);
    font-size: calc(1.325rem + .9vw)
}

@media(min-width:1200px) {
    .h2, h2 {
        font-size: 2rem
    }
}

.h3, h3 {
    font-size: -webkit-calc(1.3rem + .6vw);
    font-size: calc(1.3rem + .6vw)
}

@media(min-width:1200px) {
    .h3, h3 {
        font-size: 1.75rem
    }
}

.h4, h4 {
    font-size: -webkit-calc(1.275rem + .3vw);
    font-size: calc(1.275rem + .3vw)
}

@media(min-width:1200px) {
    .h4, h4 {
        font-size: 1.5rem
    }
}

.h5, h5 {
    font-size: 1.25rem
}

.h6, h6 {
    font-size: 1rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[data-bs-original-title], abbr[title] {
    -moz-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    text-decoration-skip-ink: none
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

ol, ul {
    padding-left: 2rem
}

dl, ol, ul {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol, ol ul, ul ol, ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b, strong {
    font-weight: bolder
}

.small, small {
    font-size: .875em
}

.mark, mark {
    padding: .2em;
    background-color: #fcf8e3
}

sub, sup {
    position: relative;
    font-size: .75em;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: #0d6efd;
    text-decoration: underline
}

a:hover {
    color: var(--bs-primary)
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
    /* color: inherit; */
    text-decoration: none
}

code, kbd, pre, samp {
    font-family: var(--bs-font-monospace);
    font-size: 1em;
    direction: ltr;
    unicode-bidi: bidi-override
}

pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: .875em
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
}

code {
    font-size: .875em;
    color: #d63384;
    word-wrap: break-word
}

a>code {
    color: inherit
}

kbd {
    padding: .2rem .4rem;
    font-size: .875em;
    color: #fff;
    background-color: #212529;
    border-radius: .2rem
}

kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700
}

figure {
    margin: 0 0 1rem
}

img, svg {
    vertical-align: middle
}

table {
    caption-side: bottom;
    border-collapse: collapse
}

caption {
    padding-top: .5rem;
    padding-bottom: .5rem;
    color: #6c757d;
    text-align: left
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent
}

tbody, td, tfoot, th, thead, tr {
    border: 0 solid;
    border-color: inherit
}

label {
    display: inline-block
}

button {
    border-radius: 0
}

button:focus:not(:focus-visible) {
    outline: 0
}

button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button, select {
    text-transform: none
}

[role=button] {
    cursor: pointer
}

select {
    word-wrap: normal
}

select:disabled {
    opacity: 1
}

[list]::-webkit-calendar-picker-indicator {
    display: none
}

[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer
}

::-moz-focus-inner {
    padding: 0;
    border-style: none
}

textarea {
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: -webkit-calc(1.275rem + .3vw);
    font-size: calc(1.275rem + .3vw);
    line-height: inherit
}

@media(min-width:1200px) {
    legend {
        font-size: 1.5rem
    }
}

legend+* {
    clear: left
}

::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-year-field {
    padding: 0
}

::-webkit-inner-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: textfield
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-color-swatch-wrapper {
    padding: 0
}

::file-selector-button {
    font: inherit
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

iframe {
    border: 0
}

summary {
    display: list-item;
    cursor: pointer
}

progress {
    vertical-align: baseline
}

[hidden] {
    display: none !important
}

.lead {
    font-size: 1.25rem;
    font-weight: 300
}

.display-1 {
    font-size: -webkit-calc(1.625rem + 4.5vw);
    font-size: calc(1.625rem + 4.5vw);
    font-weight: 300;
    line-height: 1.2
}

@media(min-width:1200px) {
    .display-1 {
        font-size: 5rem
    }
}

.display-2 {
    font-size: -webkit-calc(1.575rem + 3.9vw);
    font-size: calc(1.575rem + 3.9vw);
    font-weight: 300;
    line-height: 1.2
}

@media(min-width:1200px) {
    .display-2 {
        font-size: 4.5rem
    }
}

.display-3 {
    font-size: -webkit-calc(1.525rem + 3.3vw);
    font-size: calc(1.525rem + 3.3vw);
    font-weight: 300;
    line-height: 1.2
}

@media(min-width:1200px) {
    .display-3 {
        font-size: 4rem
    }
}

.display-4 {
    font-size: -webkit-calc(1.475rem + 2.7vw);
    font-size: calc(1.475rem + 2.7vw);
    font-weight: 300;
    line-height: 1.2
}

@media(min-width:1200px) {
    .display-4 {
        font-size: 3.5rem
    }
}

.display-5 {
    font-size: -webkit-calc(1.425rem + 2.1vw);
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 300;
    line-height: 1.2
}

@media(min-width:1200px) {
    .display-5 {
        font-size: 3rem
    }
}

.display-6 {
    font-size: -webkit-calc(1.375rem + 1.5vw);
    font-size: calc(1.375rem + 1.5vw);
    font-weight: 300;
    line-height: 1.2
}

@media(min-width:1200px) {
    .display-6 {
        font-size: 2.5rem
    }
}

.list-inline, .list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: .875em;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem
}

.blockquote>:last-child {
    margin-bottom: 0
}

.blockquote-footer {
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-size: .875em;
    color: #6c757d
}

.blockquote-footer:before {
    content: "— "
}

.img-fluid, .img-thumbnail {
    max-width: 100%;
    height: auto
}

.img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: .25rem
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: .5rem;
    line-height: 1
}

.figure-caption {
    font-size: .875em;
    color: #6c757d
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    width: 100%;
    padding-right: var(--bs-gutter-x, .75rem);
    padding-left: var(--bs-gutter-x, .75rem);
    margin-right: auto;
    margin-left: auto
}

@media(min-width:576px) {
    .container, .container-sm {
        max-width: 540px
    }
}

@media(min-width:768px) {
    .container, .container-md, .container-sm {
        max-width: 720px
    }
}

@media(min-width:992px) {
    .container, .container-lg, .container-md, .container-sm {
        max-width: 960px
    }
}

@media(min-width:1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1140px
    }
}

@media(min-width:1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1320px
    }
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: calc(-1*var(--bs-gutter-y));
    margin-right: calc(-.5*var(--bs-gutter-x));
    margin-left: calc(-.5*var(--bs-gutter-x))
}

.row>* {
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x)*.5);
    padding-left: calc(var(--bs-gutter-x)*.5);
    margin-top: var(--bs-gutter-y)
}

.col {
    -webkit-flex: 1 0;
    flex: 1 0
}

.row-cols-auto>* {
    width: auto
}

.row-cols-1>*, .row-cols-auto>* {
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto
}

.row-cols-1>* {
    width: 100%
}

.row-cols-2>* {
    width: 50%
}

.row-cols-2>*, .row-cols-3>* {
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto
}

.row-cols-3>* {
    width: 33.3333333333%
}

.row-cols-4>* {
    width: 25%
}

.row-cols-4>*, .row-cols-5>* {
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto
}

.row-cols-5>* {
    width: 20%
}

.row-cols-6>* {
    width: 16.6666666667%
}

.col-auto, .row-cols-6>* {
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto
}

.col-auto {
    width: auto
}

.col-1 {
    width: 8.33333333%
}

.col-1, .col-2 {
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto
}

.col-2 {
    width: 16.66666667%
}

.col-3 {
    width: 25%
}

.col-3, .col-4 {
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto
}

.col-4 {
    width: 33.33333333%
}

.col-5 {
    width: 41.66666667%
}

.col-5, .col-6 {
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto
}

.col-6 {
    width: 50%
}

.col-7 {
    width: 58.33333333%
}

.col-7, .col-8 {
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto
}

.col-8 {
    width: 66.66666667%
}

.col-9 {
    width: 75%
}

.col-9, .col-10 {
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto
}

.col-10 {
    width: 83.33333333%
}

.col-11 {
    width: 91.66666667%
}

.col-11, .col-12 {
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto
}

.col-12 {
    width: 100%
}

.offset-1 {
    margin-left: 8.33333333%
}

.offset-2 {
    margin-left: 16.66666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333333%
}

.offset-5 {
    margin-left: 41.66666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333333%
}

.offset-8 {
    margin-left: 66.66666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333333%
}

.offset-11 {
    margin-left: 91.66666667%
}

.g-0, .gx-0 {
    --bs-gutter-x: 0
}

.g-0, .gy-0 {
    --bs-gutter-y: 0
}

.g-1, .gx-1 {
    --bs-gutter-x: 0.25rem
}

.g-1, .gy-1 {
    --bs-gutter-y: 0.25rem
}

.g-2, .gx-2 {
    --bs-gutter-x: 0.5rem
}

.g-2, .gy-2 {
    --bs-gutter-y: 0.5rem
}

.g-3, .gx-3 {
    --bs-gutter-x: 1rem
}

.g-3, .gy-3 {
    --bs-gutter-y: 1rem
}

.g-4, .gx-4 {
    --bs-gutter-x: 1.5rem
}

.g-4, .gy-4 {
    --bs-gutter-y: 1.5rem
}

.g-5, .gx-5 {
    --bs-gutter-x: 3rem
}

.g-5, .gy-5 {
    --bs-gutter-y: 3rem
}

@media(min-width:576px) {
    .col-sm {
        -webkit-flex: 1 0;
        flex: 1 0
    }

    .row-cols-sm-auto>* {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-sm-1>* {
        width: 100%
    }

    .row-cols-sm-1>*, .row-cols-sm-2>* {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .row-cols-sm-2>* {
        width: 50%
    }

    .row-cols-sm-3>* {
        width: 33.3333333333%
    }

    .row-cols-sm-3>*, .row-cols-sm-4>* {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .row-cols-sm-4>* {
        width: 25%
    }

    .row-cols-sm-5>* {
        width: 20%
    }

    .row-cols-sm-5>*, .row-cols-sm-6>* {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .row-cols-sm-6>* {
        width: 16.6666666667%
    }

    .col-sm-auto {
        width: auto
    }

    .col-sm-1, .col-sm-auto {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-sm-1 {
        width: 8.33333333%
    }

    .col-sm-2 {
        width: 16.66666667%
    }

    .col-sm-2, .col-sm-3 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-sm-3 {
        width: 25%
    }

    .col-sm-4 {
        width: 33.33333333%
    }

    .col-sm-4, .col-sm-5 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-sm-5 {
        width: 41.66666667%
    }

    .col-sm-6 {
        width: 50%
    }

    .col-sm-6, .col-sm-7 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-sm-7 {
        width: 58.33333333%
    }

    .col-sm-8 {
        width: 66.66666667%
    }

    .col-sm-8, .col-sm-9 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-sm-9 {
        width: 75%
    }

    .col-sm-10 {
        width: 83.33333333%
    }

    .col-sm-10, .col-sm-11 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-sm-11 {
        width: 91.66666667%
    }

    .col-sm-12 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.33333333%
    }

    .offset-sm-2 {
        margin-left: 16.66666667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.33333333%
    }

    .offset-sm-5 {
        margin-left: 41.66666667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.33333333%
    }

    .offset-sm-8 {
        margin-left: 66.66666667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.33333333%
    }

    .offset-sm-11 {
        margin-left: 91.66666667%
    }

    .g-sm-0, .gx-sm-0 {
        --bs-gutter-x: 0
    }

    .g-sm-0, .gy-sm-0 {
        --bs-gutter-y: 0
    }

    .g-sm-1, .gx-sm-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-sm-1, .gy-sm-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-sm-2, .gx-sm-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-sm-2, .gy-sm-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-sm-3, .gx-sm-3 {
        --bs-gutter-x: 1rem
    }

    .g-sm-3, .gy-sm-3 {
        --bs-gutter-y: 1rem
    }

    .g-sm-4, .gx-sm-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-sm-4, .gy-sm-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-sm-5, .gx-sm-5 {
        --bs-gutter-x: 3rem
    }

    .g-sm-5, .gy-sm-5 {
        --bs-gutter-y: 3rem
    }
}

@media(min-width:768px) {
    .col-md {
        -webkit-flex: 1 0;
        flex: 1 0
    }

    .row-cols-md-auto>* {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-md-1>* {
        width: 100%
    }

    .row-cols-md-1>*, .row-cols-md-2>* {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .row-cols-md-2>* {
        width: 50%
    }

    .row-cols-md-3>* {
        width: 33.3333333333%
    }

    .row-cols-md-3>*, .row-cols-md-4>* {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .row-cols-md-4>* {
        width: 25%
    }

    .row-cols-md-5>* {
        width: 20%
    }

    .row-cols-md-5>*, .row-cols-md-6>* {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .row-cols-md-6>* {
        width: 16.6666666667%
    }

    .col-md-auto {
        width: auto
    }

    .col-md-1, .col-md-auto {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-md-1 {
        width: 8.33333333%
    }

    .col-md-2 {
        width: 16.66666667%
    }

    .col-md-2, .col-md-3 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-md-3 {
        width: 25%
    }

    .col-md-4 {
        width: 33.33333333%
    }

    .col-md-4, .col-md-5 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-md-5 {
        width: 41.66666667%
    }

    .col-md-6 {
        width: 50%
    }

    .col-md-6, .col-md-7 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-md-7 {
        width: 58.33333333%
    }

    .col-md-8 {
        width: 66.66666667%
    }

    .col-md-8, .col-md-9 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-md-9 {
        width: 75%
    }

    .col-md-10 {
        width: 83.33333333%
    }

    .col-md-10, .col-md-11 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-md-11 {
        width: 91.66666667%
    }

    .col-md-12 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333333%
    }

    .offset-md-2 {
        margin-left: 16.66666667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333333%
    }

    .offset-md-5 {
        margin-left: 41.66666667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333333%
    }

    .offset-md-8 {
        margin-left: 66.66666667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333333%
    }

    .offset-md-11 {
        margin-left: 91.66666667%
    }

    .g-md-0, .gx-md-0 {
        --bs-gutter-x: 0
    }

    .g-md-0, .gy-md-0 {
        --bs-gutter-y: 0
    }

    .g-md-1, .gx-md-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-md-1, .gy-md-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-md-2, .gx-md-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-md-2, .gy-md-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-md-3, .gx-md-3 {
        --bs-gutter-x: 1rem
    }

    .g-md-3, .gy-md-3 {
        --bs-gutter-y: 1rem
    }

    .g-md-4, .gx-md-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-md-4, .gy-md-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-md-5, .gx-md-5 {
        --bs-gutter-x: 3rem
    }

    .g-md-5, .gy-md-5 {
        --bs-gutter-y: 3rem
    }
}

@media(min-width:992px) {
    .col-lg {
        -webkit-flex: 1 0;
        flex: 1 0
    }

    .row-cols-lg-auto>* {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-lg-1>* {
        width: 100%
    }

    .row-cols-lg-1>*, .row-cols-lg-2>* {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .row-cols-lg-2>* {
        width: 50%
    }

    .row-cols-lg-3>* {
        width: 33.3333333333%
    }

    .row-cols-lg-3>*, .row-cols-lg-4>* {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .row-cols-lg-4>* {
        width: 25%
    }

    .row-cols-lg-5>* {
        width: 20%
    }

    .row-cols-lg-5>*, .row-cols-lg-6>* {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .row-cols-lg-6>* {
        width: 16.6666666667%
    }

    .col-lg-auto {
        width: auto
    }

    .col-lg-1, .col-lg-auto {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-lg-1 {
        width: 8.33333333%
    }

    .col-lg-2 {
        width: 16.66666667%
    }

    .col-lg-2, .col-lg-3 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-lg-3 {
        width: 25%
    }

    .col-lg-4 {
        width: 33.33333333%
    }

    .col-lg-4, .col-lg-5 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-lg-5 {
        width: 41.66666667%
    }

    .col-lg-6 {
        width: 50%
    }

    .col-lg-6, .col-lg-7 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-lg-7 {
        width: 58.33333333%
    }

    .col-lg-8 {
        width: 66.66666667%
    }

    .col-lg-8, .col-lg-9 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-lg-9 {
        width: 75%
    }

    .col-lg-10 {
        width: 83.33333333%
    }

    .col-lg-10, .col-lg-11 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-lg-11 {
        width: 91.66666667%
    }

    .col-lg-12 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333333%
    }

    .offset-lg-2 {
        margin-left: 16.66666667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333333%
    }

    .offset-lg-5 {
        margin-left: 41.66666667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333333%
    }

    .offset-lg-8 {
        margin-left: 66.66666667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333333%
    }

    .offset-lg-11 {
        margin-left: 91.66666667%
    }

    .g-lg-0, .gx-lg-0 {
        --bs-gutter-x: 0
    }

    .g-lg-0, .gy-lg-0 {
        --bs-gutter-y: 0
    }

    .g-lg-1, .gx-lg-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-lg-1, .gy-lg-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-lg-2, .gx-lg-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-lg-2, .gy-lg-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-lg-3, .gx-lg-3 {
        --bs-gutter-x: 1rem
    }

    .g-lg-3, .gy-lg-3 {
        --bs-gutter-y: 1rem
    }

    .g-lg-4, .gx-lg-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-lg-4, .gy-lg-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-lg-5, .gx-lg-5 {
        --bs-gutter-x: 3rem
    }

    .g-lg-5, .gy-lg-5 {
        --bs-gutter-y: 3rem
    }
}

@media(min-width:1200px) {
    .col-xl {
        -webkit-flex: 1 0;
        flex: 1 0
    }

    .row-cols-xl-auto>* {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xl-1>* {
        width: 100%
    }

    .row-cols-xl-1>*, .row-cols-xl-2>* {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .row-cols-xl-2>* {
        width: 50%
    }

    .row-cols-xl-3>* {
        width: 33.3333333333%
    }

    .row-cols-xl-3>*, .row-cols-xl-4>* {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .row-cols-xl-4>* {
        width: 25%
    }

    .row-cols-xl-5>* {
        width: 20%
    }

    .row-cols-xl-5>*, .row-cols-xl-6>* {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .row-cols-xl-6>* {
        width: 16.6666666667%
    }

    .col-xl-auto {
        width: auto
    }

    .col-xl-1, .col-xl-auto {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-xl-1 {
        width: 8.33333333%
    }

    .col-xl-2 {
        width: 16.66666667%
    }

    .col-xl-2, .col-xl-3 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-xl-3 {
        width: 25%
    }

    .col-xl-4 {
        width: 33.33333333%
    }

    .col-xl-4, .col-xl-5 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-xl-5 {
        width: 41.66666667%
    }

    .col-xl-6 {
        width: 50%
    }

    .col-xl-6, .col-xl-7 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-xl-7 {
        width: 58.33333333%
    }

    .col-xl-8 {
        width: 66.66666667%
    }

    .col-xl-8, .col-xl-9 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-xl-9 {
        width: 75%
    }

    .col-xl-10 {
        width: 83.33333333%
    }

    .col-xl-10, .col-xl-11 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-xl-11 {
        width: 91.66666667%
    }

    .col-xl-12 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333333%
    }

    .offset-xl-2 {
        margin-left: 16.66666667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333333%
    }

    .offset-xl-5 {
        margin-left: 41.66666667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333333%
    }

    .offset-xl-8 {
        margin-left: 66.66666667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333333%
    }

    .offset-xl-11 {
        margin-left: 91.66666667%
    }

    .g-xl-0, .gx-xl-0 {
        --bs-gutter-x: 0
    }

    .g-xl-0, .gy-xl-0 {
        --bs-gutter-y: 0
    }

    .g-xl-1, .gx-xl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xl-1, .gy-xl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xl-2, .gx-xl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xl-2, .gy-xl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xl-3, .gx-xl-3 {
        --bs-gutter-x: 1rem
    }

    .g-xl-3, .gy-xl-3 {
        --bs-gutter-y: 1rem
    }

    .g-xl-4, .gx-xl-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-xl-4, .gy-xl-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-xl-5, .gx-xl-5 {
        --bs-gutter-x: 3rem
    }

    .g-xl-5, .gy-xl-5 {
        --bs-gutter-y: 3rem
    }
}

@media(min-width:1400px) {
    .col-xxl {
        -webkit-flex: 1 0;
        flex: 1 0
    }

    .row-cols-xxl-auto>* {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xxl-1>* {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xxl-2>* {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xxl-3>* {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-xxl-4>* {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xxl-5>* {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xxl-6>* {
        width: 16.6666666667%
    }

    .col-xxl-auto, .row-cols-xxl-6>* {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-xxl-auto {
        width: auto
    }

    .col-xxl-1 {
        width: 8.33333333%
    }

    .col-xxl-1, .col-xxl-2 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-xxl-2 {
        width: 16.66666667%
    }

    .col-xxl-3 {
        width: 25%
    }

    .col-xxl-3, .col-xxl-4 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-xxl-4 {
        width: 33.33333333%
    }

    .col-xxl-5 {
        width: 41.66666667%
    }

    .col-xxl-5, .col-xxl-6 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-xxl-6 {
        width: 50%
    }

    .col-xxl-7 {
        width: 58.33333333%
    }

    .col-xxl-7, .col-xxl-8 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-xxl-8 {
        width: 66.66666667%
    }

    .col-xxl-9 {
        width: 75%
    }

    .col-xxl-9, .col-xxl-10 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-xxl-10 {
        width: 83.33333333%
    }

    .col-xxl-11 {
        width: 91.66666667%
    }

    .col-xxl-11, .col-xxl-12 {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .col-xxl-12 {
        width: 100%
    }

    .offset-xxl-0 {
        margin-left: 0
    }

    .offset-xxl-1 {
        margin-left: 8.33333333%
    }

    .offset-xxl-2 {
        margin-left: 16.66666667%
    }

    .offset-xxl-3 {
        margin-left: 25%
    }

    .offset-xxl-4 {
        margin-left: 33.33333333%
    }

    .offset-xxl-5 {
        margin-left: 41.66666667%
    }

    .offset-xxl-6 {
        margin-left: 50%
    }

    .offset-xxl-7 {
        margin-left: 58.33333333%
    }

    .offset-xxl-8 {
        margin-left: 66.66666667%
    }

    .offset-xxl-9 {
        margin-left: 75%
    }

    .offset-xxl-10 {
        margin-left: 83.33333333%
    }

    .offset-xxl-11 {
        margin-left: 91.66666667%
    }

    .g-xxl-0, .gx-xxl-0 {
        --bs-gutter-x: 0
    }

    .g-xxl-0, .gy-xxl-0 {
        --bs-gutter-y: 0
    }

    .g-xxl-1, .gx-xxl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xxl-1, .gy-xxl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xxl-2, .gx-xxl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xxl-2, .gy-xxl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xxl-3, .gx-xxl-3 {
        --bs-gutter-x: 1rem
    }

    .g-xxl-3, .gy-xxl-3 {
        --bs-gutter-y: 1rem
    }

    .g-xxl-4, .gx-xxl-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-xxl-4, .gy-xxl-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-xxl-5, .gx-xxl-5 {
        --bs-gutter-x: 3rem
    }

    .g-xxl-5, .gy-xxl-5 {
        --bs-gutter-y: 3rem
    }
}

.table {
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #212529;
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: #212529;
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: #212529;
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    vertical-align: top;
    border-color: #dee2e6
}

.table>:not(caption)>*>* {
    padding: .5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    -webkit-box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg)
}

.table>tbody {
    vertical-align: inherit
}

.table>thead {
    vertical-align: bottom
}

.table>:not(:first-child) {
    border-top: 2px solid
}

.caption-top {
    caption-side: top
}

.table-sm>:not(caption)>*>* {
    padding: .25rem
}

.table-bordered>:not(caption)>* {
    border-width: 1px 0
}

.table-bordered>:not(caption)>*>* {
    border-width: 0 1px
}

.table-borderless>:not(caption)>*>* {
    border-bottom-width: 0
}

.table-borderless>:not(:first-child) {
    border-top-width: 0
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color)
}

.table-active {
    --bs-table-accent-bg: var(--bs-table-active-bg);
    color: var(--bs-table-active-color)
}

.table-hover>tbody>tr:hover>* {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color)
}

.table-primary {
    --bs-table-bg: #cfe2ff;
    --bs-table-striped-bg: #c5d7f2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bacbe6;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bfd1ec;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #bacbe6
}

.table-secondary {
    --bs-table-bg: #e2e3e5;
    --bs-table-striped-bg: #d7d8da;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #cbccce;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #d1d2d4;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #cbccce
}

.table-success {
    --bs-table-bg: #d1e7dd;
    --bs-table-striped-bg: #c7dbd2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bcd0c7;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #c1d6cc;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #bcd0c7
}

.table-info {
    --bs-table-bg: #cff4fc;
    --bs-table-striped-bg: #c5e8ef;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #badce3;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bfe2e9;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #badce3
}

.table-warning {
    --bs-table-bg: #fff3cd;
    --bs-table-striped-bg: #f2e7c3;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #e6dbb9;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #ece1be;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #e6dbb9
}

.table-danger {
    --bs-table-bg: #f8d7da;
    --bs-table-striped-bg: #eccccf;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #dfc2c4;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e5c7ca;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #dfc2c4
}

.table-light {
    --bs-table-bg: #f8f9fa;
    --bs-table-striped-bg: #ecedee;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #dfe0e1;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e5e6e7;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #dfe0e1
}

.table-dark {
    --bs-table-bg: #212529;
    --bs-table-striped-bg: #2c3034;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #373b3e;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #323539;
    --bs-table-hover-color: #fff;
    color: #fff;
    border-color: #373b3e
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

@media(max-width:575.98px) {
    .table-responsive-sm {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media(max-width:767.98px) {
    .table-responsive-md {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media(max-width:991.98px) {
    .table-responsive-lg {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media(max-width:1199.98px) {
    .table-responsive-xl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media(max-width:1399.98px) {
    .table-responsive-xxl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

.form-label {
    margin-bottom: .5rem
}

.col-form-label {
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5
}

.col-form-label-lg {
    padding-top: calc(.5rem + 1px);
    padding-bottom: calc(.5rem + 1px);
    font-size: 1.25rem
}

.col-form-label-sm {
    padding-top: calc(.25rem + 1px);
    padding-bottom: calc(.25rem + 1px);
    font-size: .875rem
}

.form-text {
    margin-top: .25rem;
    font-size: .875em;
    color: #6c757d
}

.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .form-control {
        -webkit-transition: none;
        transition: none
    }
}

.form-control[type=file] {
    overflow: hidden
}

.form-control[type=file]:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    -webkit-box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-control::-webkit-date-and-time-value {
    height: 1.5em
}

.form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control::placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1
}

.form-control::file-selector-button {
    padding: .375rem .75rem;
    margin: -.375rem -.75rem;
    -webkit-margin-end: .75rem;
    -moz-margin-end: .75rem;
    margin-inline-end: .75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border: 0 solid;
    border-color: inherit;
    border-inline-end-width: 1px;
    border-radius: 0;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .form-control::file-selector-button {
        -webkit-transition: none;
        transition: none
    }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3
}

.form-control::-webkit-file-upload-button {
    padding: .375rem .75rem;
    margin: -.375rem -.75rem;
    -webkit-margin-end: .75rem;
    margin-inline-end: .75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border: 0 solid;
    border-color: inherit;
    border-inline-end-width: 1px;
    border-radius: 0;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .form-control::-webkit-file-upload-button {
        -webkit-transition: none;
        transition: none
    }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: .375rem 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0
}

.form-control-plaintext.form-control-lg, .form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm {
    min-height: -webkit-calc(1.5em + .5rem + 2px);
    min-height: calc(1.5em + .5rem + 2px);
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .2rem
}

.form-control-sm::file-selector-button {
    padding: .25rem .5rem;
    margin: -.25rem -.5rem;
    -webkit-margin-end: .5rem;
    -moz-margin-end: .5rem;
    margin-inline-end: .5rem
}

.form-control-sm::-webkit-file-upload-button {
    padding: .25rem .5rem;
    margin: -.25rem -.5rem;
    -webkit-margin-end: .5rem;
    margin-inline-end: .5rem
}

.form-control-lg {
    min-height: -webkit-calc(1.5em + 1rem + 2px);
    min-height: calc(1.5em + 1rem + 2px);
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: .3rem
}

.form-control-lg::file-selector-button {
    padding: .5rem 1rem;
    margin: -.5rem -1rem;
    -webkit-margin-end: 1rem;
    -moz-margin-end: 1rem;
    margin-inline-end: 1rem
}

.form-control-lg::-webkit-file-upload-button {
    padding: .5rem 1rem;
    margin: -.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem
}

textarea.form-control {
    min-height: -webkit-calc(1.5em + .75rem + 2px);
    min-height: calc(1.5em + .75rem + 2px)
}

textarea.form-control-sm {
    min-height: -webkit-calc(1.5em + .5rem + 2px);
    min-height: calc(1.5em + .5rem + 2px)
}

textarea.form-control-lg {
    min-height: -webkit-calc(1.5em + 1rem + 2px);
    min-height: calc(1.5em + 1rem + 2px)
}

.form-control-color {
    width: 3rem;
    height: auto;
    padding: .375rem
}

.form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control-color::-moz-color-swatch {
    height: 1.5em;
    border-radius: .25rem
}

.form-control-color::-webkit-color-swatch {
    height: 1.5em;
    border-radius: .25rem
}

.form-select {
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    -moz-padding-start: calc(.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

@media(prefers-reduced-motion:reduce) {
    .form-select {
        -webkit-transition: none;
        transition: none
    }
}

.form-select:focus {
    border-color: #86b7fe;
    outline: 0;
    -webkit-box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: .75rem;
    background-image: none
}

.form-select:disabled {
    background-color: #e9ecef
}

.form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #212529
}

.form-select-sm {
    padding-top: .25rem;
    padding-bottom: .25rem;
    padding-left: .5rem;
    font-size: .875rem;
    border-radius: .2rem
}

.form-select-lg {
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    font-size: 1.25rem;
    border-radius: .3rem
}

.form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: .125rem
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.5em
}

.form-check-input {
    width: 1em;
    height: 1em;
    margin-top: .25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, .25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact
}

.form-check-input[type=checkbox] {
    border-radius: .25em
}

.form-check-input[type=radio] {
    border-radius: 50%
}

.form-check-input:active {
    -webkit-filter: brightness(90%);
    filter: brightness(90%)
}

.form-check-input:focus {
    border-color: #86b7fe;
    outline: 0;
    -webkit-box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd
}

.form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
}

.form-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")
}

.form-check-input[type=checkbox]:indeterminate {
    background-color: #0d6efd;
    border-color: #0d6efd;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")
}

.form-check-input:disabled {
    pointer-events: none;
    -webkit-filter: none;
    filter: none;
    opacity: .5
}

.form-check-input:disabled~.form-check-label, .form-check-input[disabled]~.form-check-label {
    opacity: .5
}

.form-switch {
    padding-left: 2.5em
}

.form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: 0;
    border-radius: 2em;
    -webkit-transition: background-position .15s ease-in-out;
    transition: background-position .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .form-switch .form-check-input {
        -webkit-transition: none;
        transition: none
    }
}

.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e")
}

.form-switch .form-check-input:checked {
    background-position: 100%;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}

.form-check-inline {
    display: inline-block;
    margin-right: 1rem
}

.btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.btn-check:disabled+.btn, .btn-check[disabled]+.btn {
    pointer-events: none;
    -webkit-filter: none;
    filter: none;
    opacity: .65
}

.form-range {
    width: 100%;
    height: 1.5rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.form-range:focus {
    outline: 0
}

.form-range:focus::-webkit-slider-thumb {
    -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(13, 110, 253, .25);
    box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-range::-moz-focus-outer {
    border: 0
}

.form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none
}

@media(prefers-reduced-motion:reduce) {
    .form-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none
    }
}

.form-range::-webkit-slider-thumb:active {
    background-color: #b6d4fe
}

.form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem
}

.form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-appearance: none;
    appearance: none
}

@media(prefers-reduced-motion:reduce) {
    .form-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none
    }
}

.form-range::-moz-range-thumb:active {
    background-color: #b6d4fe
}

.form-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem
}

.form-range:disabled {
    pointer-events: none
}

.form-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd
}

.form-range:disabled::-moz-range-thumb {
    background-color: #adb5bd
}

.form-floating {
    position: relative
}

.form-floating>.form-control, .form-floating>.form-select {
    height: -webkit-calc(3.5rem + 2px);
    height: calc(3.5rem + 2px);
    line-height: 1.25
}

.form-floating>label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem .75rem;
    pointer-events: none;
    border: 1px solid transparent;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition: opacity .1s ease-in-out, -webkit-transform .1s ease-in-out;
    transition: opacity .1s ease-in-out, -webkit-transform .1s ease-in-out;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out, -webkit-transform .1s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .form-floating>label {
        -webkit-transition: none;
        transition: none
    }
}

.form-floating>.form-control {
    padding: 1rem .75rem
}

.form-floating>.form-control::-webkit-input-placeholder {
    color: transparent
}

.form-floating>.form-control::-moz-placeholder {
    color: transparent
}

.form-floating>.form-control:-ms-input-placeholder {
    color: transparent
}

.form-floating>.form-control::placeholder {
    color: transparent
}

.form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-select {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
    opacity: .65;
    -webkit-transform: scale(.85) translateY(-.5rem) translateX(.15rem);
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control:-webkit-autofill~label {
    opacity: .65;
    -webkit-transform: scale(.85) translateY(-.5rem) translateX(.15rem);
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.input-group {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: stretch;
    align-items: stretch;
    width: 100%
}

.input-group>.form-control, .input-group>.form-select {
    position: relative;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0
}

.input-group>.form-control:focus, .input-group>.form-select:focus {
    z-index: 3
}

.input-group .btn {
    position: relative;
    z-index: 2
}

.input-group .btn:focus {
    z-index: 3
}

.input-group-text {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem
}

.input-group-lg>.btn, .input-group-lg>.form-control, .input-group-lg>.form-select, .input-group-lg>.input-group-text {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: .3rem
}

.input-group-sm>.btn, .input-group-sm>.form-control, .input-group-sm>.form-select, .input-group-sm>.input-group-text {
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .2rem
}

.input-group-lg>.form-select, .input-group-sm>.form-select {
    padding-right: 3rem
}

.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4), .input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu), .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: #198754
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    color: #fff;
    background-color: rgba(25, 135, 84, .9);
    border-radius: .25rem
}

.is-valid~.valid-feedback, .is-valid~.valid-tooltip, .was-validated :valid~.valid-feedback, .was-validated :valid~.valid-tooltip {
    display: block
}

.form-control.is-valid, .was-validated .form-control:valid {
    border-color: #198754;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right -webkit-calc(.375em + .1875rem) center;
    background-position: right calc(.375em + .1875rem) center;
    background-size: -webkit-calc(.75em + .375rem) -webkit-calc(.75em + .375rem);
    background-size: calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
    border-color: #198754;
    -webkit-box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25);
    box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25)
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
    padding-right: calc(1.5em + .75rem);
    background-position: top -webkit-calc(.375em + .1875rem) right -webkit-calc(.375em + .1875rem);
    background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
}

.form-select.is-valid, .was-validated .form-select:valid {
    border-color: #198754
}

.form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"], .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right .75rem center, center right 2.25rem;
    background-size: 16px 12px, -webkit-calc(.75em + .375rem) -webkit-calc(.75em + .375rem);
    background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-select.is-valid:focus, .was-validated .form-select:valid:focus {
    border-color: #198754;
    -webkit-box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25);
    box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25)
}

.form-check-input.is-valid, .was-validated .form-check-input:valid {
    border-color: #198754
}

.form-check-input.is-valid:checked, .was-validated .form-check-input:valid:checked {
    background-color: #198754
}

.form-check-input.is-valid:focus, .was-validated .form-check-input:valid:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25);
    box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25)
}

.form-check-input.is-valid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label {
    color: #198754
}

.form-check-inline .form-check-input~.valid-feedback {
    margin-left: .5em
}

.input-group .form-control.is-valid, .input-group .form-select.is-valid, .was-validated .input-group .form-control:valid, .was-validated .input-group .form-select:valid {
    z-index: 1
}

.input-group .form-control.is-valid:focus, .input-group .form-select.is-valid:focus, .was-validated .input-group .form-control:valid:focus, .was-validated .input-group .form-select:valid:focus {
    z-index: 3
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: #dc3545
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    color: #fff;
    background-color: rgba(220, 53, 69, .9);
    border-radius: .25rem
}

.is-invalid~.invalid-feedback, .is-invalid~.invalid-tooltip, .was-validated :invalid~.invalid-feedback, .was-validated :invalid~.invalid-tooltip {
    display: block
}

.form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right -webkit-calc(.375em + .1875rem) center;
    background-position: right calc(.375em + .1875rem) center;
    background-size: -webkit-calc(.75em + .375rem) -webkit-calc(.75em + .375rem);
    background-size: calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25);
    box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25)
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
    padding-right: calc(1.5em + .75rem);
    background-position: top -webkit-calc(.375em + .1875rem) right -webkit-calc(.375em + .1875rem);
    background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
}

.form-select.is-invalid, .was-validated .form-select:invalid {
    border-color: #dc3545
}

.form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"], .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-position: right .75rem center, center right 2.25rem;
    background-size: 16px 12px, -webkit-calc(.75em + .375rem) -webkit-calc(.75em + .375rem);
    background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-select.is-invalid:focus, .was-validated .form-select:invalid:focus {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25);
    box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25)
}

.form-check-input.is-invalid, .was-validated .form-check-input:invalid {
    border-color: #dc3545
}

.form-check-input.is-invalid:checked, .was-validated .form-check-input:invalid:checked {
    background-color: #dc3545
}

.form-check-input.is-invalid:focus, .was-validated .form-check-input:invalid:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25);
    box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25)
}

.form-check-input.is-invalid~.form-check-label, .was-validated .form-check-input:invalid~.form-check-label {
    color: #dc3545
}

.form-check-inline .form-check-input~.invalid-feedback {
    margin-left: .5em
}

.input-group .form-control.is-invalid, .input-group .form-select.is-invalid, .was-validated .input-group .form-control:invalid, .was-validated .input-group .form-select:invalid {
    z-index: 2
}

.input-group .form-control.is-invalid:focus, .input-group .form-select.is-invalid:focus, .was-validated .input-group .form-control:invalid:focus, .was-validated .input-group .form-select:invalid:focus {
    z-index: 3
}

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .btn {
        -webkit-transition: none;
        transition: none
    }
}

.btn:hover {
    color: #212529
}

.btn-check:focus+.btn, .btn:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
    pointer-events: none;
    opacity: .65
}

.btn-primary {
    color: #fff;
    background-color: var(--bs-primary) !important;
    border-color: var(--bs-primary) !important;

}

.btn-check:focus+.btn-primary, .btn-primary:focus, .btn-primary:hover {
    color: #fff;
    background-color: #0b5ed7;
    border-color: var(--bs-primary)
}

.btn-check:focus+.btn-primary, .btn-primary:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(49, 132, 253, .5);
    box-shadow: 0 0 0 .25rem rgba(49, 132, 253, .5)
}

.btn-check:active+.btn-primary, .btn-check:checked+.btn-primary, .btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: var(--bs-primary);
    border-color: #0a53be
}

.btn-check:active+.btn-primary:focus, .btn-check:checked+.btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show>.btn-primary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(49, 132, 253, .5);
    box-shadow: 0 0 0 .25rem rgba(49, 132, 253, .5)
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.btn-secondary2 {
    color: #000;
    width: 90%;
    background-color: #fff;
    border-radius: 10px;
    -webkit-box-shadow: 0 40px 80px 0 rgba(2, 2, 26, .14);
    box-shadow: 0 40px 80px 0 rgba(2, 2, 26, .14);
    z-index: 11;
}

.btn-check:focus+.btn-secondary2, .btn-secondary2:focus, .btn-secondary2:hover {
    color: #000;
    border-color: #0d6efd;
    width: 80%;
    background-color: #fff;
}   

.ver {
    display: flex;
    justify-content: center;
    margin-bottom: 200;
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-check:focus+.btn-secondary, .btn-secondary:focus, .btn-secondary:hover {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64
}

.btn-check:focus+.btn-secondary, .btn-secondary:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(130, 138, 145, .5);
    box-shadow: 0 0 0 .25rem rgba(130, 138, 145, .5)
}

.btn-check:active+.btn-secondary, .btn-check:checked+.btn-secondary, .btn-secondary.active, .btn-secondary:active, .show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #565e64;
    border-color: #51585e
}

.btn-check:active+.btn-secondary:focus, .btn-check:checked+.btn-secondary:focus, .btn-secondary.active:focus, .btn-secondary:active:focus, .show>.btn-secondary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(130, 138, 145, .5);
    box-shadow: 0 0 0 .25rem rgba(130, 138, 145, .5)
}

.btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-success {
    color: #fff;
    background-color: #198754;
    border-color: #198754
}

.btn-check:focus+.btn-success, .btn-success:focus, .btn-success:hover {
    color: #fff;
    background-color: #157347;
    border-color: #146c43
}

.btn-check:focus+.btn-success, .btn-success:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(60, 153, 110, .5);
    box-shadow: 0 0 0 .25rem rgba(60, 153, 110, .5)
}

.btn-check:active+.btn-success, .btn-check:checked+.btn-success, .btn-success.active, .btn-success:active, .show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #146c43;
    border-color: #13653f
}

.btn-check:active+.btn-success:focus, .btn-check:checked+.btn-success:focus, .btn-success.active:focus, .btn-success:active:focus, .show>.btn-success.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(60, 153, 110, .5);
    box-shadow: 0 0 0 .25rem rgba(60, 153, 110, .5)
}

.btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #198754;
    border-color: #198754
}

.btn-info {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0
}

.btn-check:focus+.btn-info, .btn-info:focus, .btn-info:hover {
    color: #000;
    background-color: #31d2f2;
    border-color: #25cff2
}

.btn-check:focus+.btn-info, .btn-info:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(11, 172, 204, .5);
    box-shadow: 0 0 0 .25rem rgba(11, 172, 204, .5)
}

.btn-check:active+.btn-info, .btn-check:checked+.btn-info, .btn-info.active, .btn-info:active, .show>.btn-info.dropdown-toggle {
    color: #000;
    background-color: #3dd5f3;
    border-color: #25cff2
}

.btn-check:active+.btn-info:focus, .btn-check:checked+.btn-info:focus, .btn-info.active:focus, .btn-info:active:focus, .show>.btn-info.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(11, 172, 204, .5);
    box-shadow: 0 0 0 .25rem rgba(11, 172, 204, .5)
}

.btn-info.disabled, .btn-info:disabled {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0
}

.btn-warning {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-check:focus+.btn-warning, .btn-warning:focus, .btn-warning:hover {
    color: #000;
    background-color: #ffca2c;
    border-color: #ffc720
}

.btn-check:focus+.btn-warning, .btn-warning:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(217, 164, 6, .5);
    box-shadow: 0 0 0 .25rem rgba(217, 164, 6, .5)
}

.btn-check:active+.btn-warning, .btn-check:checked+.btn-warning, .btn-warning.active, .btn-warning:active, .show>.btn-warning.dropdown-toggle {
    color: #000;
    background-color: #ffcd39;
    border-color: #ffc720
}

.btn-check:active+.btn-warning:focus, .btn-check:checked+.btn-warning:focus, .btn-warning.active:focus, .btn-warning:active:focus, .show>.btn-warning.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(217, 164, 6, .5);
    box-shadow: 0 0 0 .25rem rgba(217, 164, 6, .5)
}

.btn-warning.disabled, .btn-warning:disabled {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-check:focus+.btn-danger, .btn-danger:focus, .btn-danger:hover {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37
}

.btn-check:focus+.btn-danger, .btn-danger:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(225, 83, 97, .5);
    box-shadow: 0 0 0 .25rem rgba(225, 83, 97, .5)
}

.btn-check:active+.btn-danger, .btn-check:checked+.btn-danger, .btn-danger.active, .btn-danger:active, .show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #b02a37;
    border-color: #a52834
}

.btn-check:active+.btn-danger:focus, .btn-check:checked+.btn-danger:focus, .btn-danger.active:focus, .btn-danger:active:focus, .show>.btn-danger.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(225, 83, 97, .5);
    box-shadow: 0 0 0 .25rem rgba(225, 83, 97, .5)
}

.btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-light {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-check:focus+.btn-light, .btn-light:focus, .btn-light:hover {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb
}

.btn-check:focus+.btn-light, .btn-light:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(211, 212, 213, .5);
    box-shadow: 0 0 0 .25rem rgba(211, 212, 213, .5)
}

.btn-check:active+.btn-light, .btn-check:checked+.btn-light, .btn-light.active, .btn-light:active, .show>.btn-light.dropdown-toggle {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb
}

.btn-check:active+.btn-light:focus, .btn-check:checked+.btn-light:focus, .btn-light.active:focus, .btn-light:active:focus, .show>.btn-light.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(211, 212, 213, .5);
    box-shadow: 0 0 0 .25rem rgba(211, 212, 213, .5)
}

.btn-light.disabled, .btn-light:disabled {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-dark {
    color: #fff;
    background-color: #212529;
    border-color: #212529
}

.btn-check:focus+.btn-dark, .btn-dark:focus, .btn-dark:hover {
    color: #fff;
    background-color: #1c1f23;
    border-color: #1a1e21
}

.btn-check:focus+.btn-dark, .btn-dark:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(66, 70, 73, .5);
    box-shadow: 0 0 0 .25rem rgba(66, 70, 73, .5)
}

.btn-check:active+.btn-dark, .btn-check:checked+.btn-dark, .btn-dark.active, .btn-dark:active, .show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1a1e21;
    border-color: #191c1f
}

.btn-check:active+.btn-dark:focus, .btn-check:checked+.btn-dark:focus, .btn-dark.active:focus, .btn-dark:active:focus, .show>.btn-dark.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(66, 70, 73, .5);
    box-shadow: 0 0 0 .25rem rgba(66, 70, 73, .5)
}

.btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #212529;
    border-color: #212529
}

.btn-outline-primary {
    color: #0d6efd;
    border-color: #0d6efd
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd
}

.btn-check:focus+.btn-outline-primary, .btn-outline-primary:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .5);
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .5)
}

.btn-check:active+.btn-outline-primary, .btn-check:checked+.btn-outline-primary, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show, .btn-outline-primary:active {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd
}

.btn-check:active+.btn-outline-primary:focus, .btn-check:checked+.btn-outline-primary:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus, .btn-outline-primary:active:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .5);
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .5)
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #0d6efd;
    background-color: transparent
}

.btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-check:focus+.btn-outline-secondary, .btn-outline-secondary:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(108, 117, 125, .5);
    box-shadow: 0 0 0 .25rem rgba(108, 117, 125, .5)
}

.btn-check:active+.btn-outline-secondary, .btn-check:checked+.btn-outline-secondary, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show, .btn-outline-secondary:active {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-check:active+.btn-outline-secondary:focus, .btn-check:checked+.btn-outline-secondary:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus, .btn-outline-secondary:active:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(108, 117, 125, .5);
    box-shadow: 0 0 0 .25rem rgba(108, 117, 125, .5)
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent
}

.btn-outline-success {
    color: #198754;
    border-color: #198754
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #198754;
    border-color: #198754
}

.btn-check:focus+.btn-outline-success, .btn-outline-success:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .5);
    box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .5)
}

.btn-check:active+.btn-outline-success, .btn-check:checked+.btn-outline-success, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show, .btn-outline-success:active {
    color: #fff;
    background-color: #198754;
    border-color: #198754
}

.btn-check:active+.btn-outline-success:focus, .btn-check:checked+.btn-outline-success:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus, .btn-outline-success:active:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .5);
    box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .5)
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #198754;
    background-color: transparent
}

.btn-outline-info {
    color: #0dcaf0;
    border-color: #0dcaf0
}

.btn-outline-info:hover {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0
}

.btn-check:focus+.btn-outline-info, .btn-outline-info:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(13, 202, 240, .5);
    box-shadow: 0 0 0 .25rem rgba(13, 202, 240, .5)
}

.btn-check:active+.btn-outline-info, .btn-check:checked+.btn-outline-info, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show, .btn-outline-info:active {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0
}

.btn-check:active+.btn-outline-info:focus, .btn-check:checked+.btn-outline-info:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus, .btn-outline-info:active:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(13, 202, 240, .5);
    box-shadow: 0 0 0 .25rem rgba(13, 202, 240, .5)
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #0dcaf0;
    background-color: transparent
}

.btn-outline-warning {
    color: #ffc107;
    border-color: #ffc107
}

.btn-outline-warning:hover {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-check:focus+.btn-outline-warning, .btn-outline-warning:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(255, 193, 7, .5);
    box-shadow: 0 0 0 .25rem rgba(255, 193, 7, .5)
}

.btn-check:active+.btn-outline-warning, .btn-check:checked+.btn-outline-warning, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show, .btn-outline-warning:active {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-check:active+.btn-outline-warning:focus, .btn-check:checked+.btn-outline-warning:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus, .btn-outline-warning:active:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(255, 193, 7, .5);
    box-shadow: 0 0 0 .25rem rgba(255, 193, 7, .5)
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent
}

.btn-outline-danger {
    color: #dc3545;
    border-color: #dc3545
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-check:focus+.btn-outline-danger, .btn-outline-danger:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .5);
    box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .5)
}

.btn-check:active+.btn-outline-danger, .btn-check:checked+.btn-outline-danger, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show, .btn-outline-danger:active {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-check:active+.btn-outline-danger:focus, .btn-check:checked+.btn-outline-danger:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus, .btn-outline-danger:active:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .5);
    box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .5)
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent
}

.btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-outline-light:hover {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-check:focus+.btn-outline-light, .btn-outline-light:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(248, 249, 250, .5);
    box-shadow: 0 0 0 .25rem rgba(248, 249, 250, .5)
}

.btn-check:active+.btn-outline-light, .btn-check:checked+.btn-outline-light, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show, .btn-outline-light:active {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-check:active+.btn-outline-light:focus, .btn-check:checked+.btn-outline-light:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus, .btn-outline-light:active:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(248, 249, 250, .5);
    box-shadow: 0 0 0 .25rem rgba(248, 249, 250, .5)
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent
}

.btn-outline-dark {
    color: #212529;
    border-color: #212529
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #212529;
    border-color: #212529
}

.btn-check:focus+.btn-outline-dark, .btn-outline-dark:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(33, 37, 41, .5);
    box-shadow: 0 0 0 .25rem rgba(33, 37, 41, .5)
}

.btn-check:active+.btn-outline-dark, .btn-check:checked+.btn-outline-dark, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show, .btn-outline-dark:active {
    color: #fff;
    background-color: #212529;
    border-color: #212529
}

.btn-check:active+.btn-outline-dark:focus, .btn-check:checked+.btn-outline-dark:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus, .btn-outline-dark:active:focus {
    -webkit-box-shadow: 0 0 0 .25rem rgba(33, 37, 41, .5);
    box-shadow: 0 0 0 .25rem rgba(33, 37, 41, .5)
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #212529;
    background-color: transparent
}

.btn-link {
    font-weight: 400;
    color: #0d6efd;
    text-decoration: underline
}

.btn-link:hover {
    color: var(--bs-primary)
}

.btn-link.disabled, .btn-link:disabled {
    color: #6c757d
}

.btn-group-lg>.btn, .btn-lg {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: .3rem
}

.btn-group-sm>.btn, .btn-sm {
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .2rem
}

.fade {
    -webkit-transition: opacity .15s linear;
    transition: opacity .15s linear
}

@media(prefers-reduced-motion:reduce) {
    .fade {
        -webkit-transition: none;
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    height: 0;
    overflow: hidden;
    -webkit-transition: height .35s ease;
    transition: height .35s ease
}

@media(prefers-reduced-motion:reduce) {
    .collapsing {
        -webkit-transition: none;
        transition: none
    }
}

.collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    -webkit-transition: width .35s ease;
    transition: width .35s ease
}

@media(prefers-reduced-motion:reduce) {
    .collapsing.collapse-horizontal {
        -webkit-transition: none;
        transition: none
    }
}

.dropdown, .dropend, .dropstart, .dropup {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-toggle:after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent
}

.dropdown-toggle:empty:after {
    margin-left: 0
}

.dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: .5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem
}

.dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: .125rem
}

.dropdown-menu-start {
    --bs-position: start
}

.dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0
}

.dropdown-menu-end {
    --bs-position: end
}

.dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto
}

@media(min-width:576px) {
    .dropdown-menu-sm-start {
        --bs-position: start
    }

    .dropdown-menu-sm-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-sm-end {
        --bs-position: end
    }

    .dropdown-menu-sm-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media(min-width:768px) {
    .dropdown-menu-md-start {
        --bs-position: start
    }

    .dropdown-menu-md-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-md-end {
        --bs-position: end
    }

    .dropdown-menu-md-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media(min-width:992px) {
    .dropdown-menu-lg-start {
        --bs-position: start
    }

    .dropdown-menu-lg-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-lg-end {
        --bs-position: end
    }

    .dropdown-menu-lg-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media(min-width:1200px) {
    .dropdown-menu-xl-start {
        --bs-position: start
    }

    .dropdown-menu-xl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xl-end {
        --bs-position: end
    }

    .dropdown-menu-xl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media(min-width:1400px) {
    .dropdown-menu-xxl-start {
        --bs-position: start
    }

    .dropdown-menu-xxl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xxl-end {
        --bs-position: end
    }

    .dropdown-menu-xxl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

.dropup .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: .125rem
}

.dropup .dropdown-toggle:after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty:after {
    margin-left: 0
}

.dropend .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: .125rem
}

.dropend .dropdown-toggle:after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid
}

.dropend .dropdown-toggle:empty:after {
    margin-left: 0
}

.dropend .dropdown-toggle:after {
    vertical-align: 0
}

.dropstart .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: .125rem
}

.dropstart .dropdown-toggle:after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    display: none
}

.dropstart .dropdown-toggle:before {
    display: inline-block;
    margin-right: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent
}

.dropstart .dropdown-toggle:empty:after {
    margin-left: 0
}

.dropstart .dropdown-toggle:before {
    vertical-align: 0
}

.dropdown-divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid rgba(0, 0, 0, .15)
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}

.dropdown-item:focus, .dropdown-item:hover {
    color: #1e2125;
    background-color: #e9ecef
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0d6efd
}

.dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    display: block;
    padding: .5rem 1rem;
    margin-bottom: 0;
    font-size: .875rem;
    color: #6c757d;
    white-space: nowrap
}

.dropdown-item-text {
    display: block;
    padding: .25rem 1rem;
    color: #212529
}

.dropdown-menu-dark {
    color: #dee2e6;
    background-color: #343a40;
    border-color: rgba(0, 0, 0, .15)
}

.dropdown-menu-dark .dropdown-item {
    color: #dee2e6
}

.dropdown-menu-dark .dropdown-item:focus, .dropdown-menu-dark .dropdown-item:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, .15)
}

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
    color: #fff;
    background-color: #0d6efd
}

.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
    color: #adb5bd
}

.dropdown-menu-dark .dropdown-divider {
    border-color: rgba(0, 0, 0, .15)
}

.dropdown-menu-dark .dropdown-item-text {
    color: #dee2e6
}

.dropdown-menu-dark .dropdown-header {
    color: #adb5bd
}

.btn-group, .btn-group-vertical {
    position: relative;
    display: -webkit-inline-flex;
    display: inline-flex;
    vertical-align: middle
}

.btn-group-vertical>.btn, .btn-group>.btn {
    position: relative;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto
}

.btn-group-vertical>.btn-check:checked+.btn, .btn-group-vertical>.btn-check:focus+.btn, .btn-group-vertical>.btn.active, .btn-group-vertical>.btn:active, .btn-group-vertical>.btn:focus, .btn-group-vertical>.btn:hover, .btn-group>.btn-check:checked+.btn, .btn-group>.btn-check:focus+.btn, .btn-group>.btn.active, .btn-group>.btn:active, .btn-group>.btn:focus, .btn-group>.btn:hover {
    z-index: 1
}

.btn-toolbar {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group>.btn-group:not(:first-child), .btn-group>.btn:not(:first-child) {
    margin-left: -1px
}

.btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn, .btn-group>.btn:nth-child(n+3), .btn-group>:not(.btn-check)+.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.dropdown-toggle-split {
    padding-right: .5625rem;
    padding-left: .5625rem
}

.dropdown-toggle-split:after, .dropend .dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after {
    margin-left: 0
}

.dropstart .dropdown-toggle-split:before {
    margin-right: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split, .btn-sm+.dropdown-toggle-split {
    padding-right: .375rem;
    padding-left: .375rem
}

.btn-group-lg>.btn+.dropdown-toggle-split, .btn-lg+.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
}

.btn-group-vertical {
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: center;
    justify-content: center
}

.btn-group-vertical>.btn, .btn-group-vertical>.btn-group {
    width: 100%
}

.btn-group-vertical>.btn-group:not(:first-child), .btn-group-vertical>.btn:not(:first-child) {
    margin-top: -1px
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn, .btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn, .btn-group-vertical>.btn~.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: .5rem 1rem;
    color: #0d6efd;
    text-decoration: none;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .nav-link {
        -webkit-transition: none;
        transition: none
    }
}

.nav-link:focus, .nav-link:hover {
    color: var(--bs-primary)
}

.nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6
}

.nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: #e9ecef #e9ecef #dee2e6;
    isolation: isolate
}

.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: .25rem
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0d6efd
}

.nav-fill .nav-item, .nav-fill>.nav-link {
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item, .nav-justified>.nav-link {
    -webkit-flex-basis: 0;
    flex-basis: 0;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    text-align: center
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
    width: 100%
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: inherit;
    flex-wrap: inherit;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between
}

.navbar-brand {
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap
}

.navbar-nav {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-nav .dropdown-menu {
    position: static
}

.navbar-text {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-collapse {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-align-items: center;
    align-items: center
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
    -webkit-transition: -webkit-box-shadow .15s ease-in-out;
    transition: -webkit-box-shadow .15s ease-in-out;
    transition: box-shadow .15s ease-in-out;
    transition: box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .navbar-toggler {
        -webkit-transition: none;
        transition: none
    }
}

.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    -webkit-box-shadow: 0 0 0 .25rem;
    box-shadow: 0 0 0 .25rem
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100%
}

.navbar-nav-scroll {
    max-height: var(--bs-scroll-height, 75vh);
    overflow-y: auto
}

@media(min-width:576px) {
    .navbar-expand-sm {
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        justify-content: flex-start
    }

    .navbar-expand-sm .navbar-nav {
        -webkit-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-sm .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-sm .navbar-collapse {
        display: -webkit-flex !important;
        display: flex !important;
        -webkit-flex-basis: auto;
        flex-basis: auto
    }

    .navbar-expand-sm .navbar-toggler, .navbar-expand-sm .offcanvas-header {
        display: none
    }

    .navbar-expand-sm .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        -webkit-flex-grow: 1;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        -webkit-transition: none;
        transition: none;
        -webkit-transform: none;
        transform: none
    }

    .navbar-expand-sm .offcanvas-bottom, .navbar-expand-sm .offcanvas-top {
        height: auto;
        border-top: 0;
        border-bottom: 0
    }

    .navbar-expand-sm .offcanvas-body {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media(min-width:768px) {
    .navbar-expand-md {
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        -webkit-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-md .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-md .navbar-collapse {
        display: -webkit-flex !important;
        display: flex !important;
        -webkit-flex-basis: auto;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler, .navbar-expand-md .offcanvas-header {
        display: none
    }

    .navbar-expand-md .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        -webkit-flex-grow: 1;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        -webkit-transition: none;
        transition: none;
        -webkit-transform: none;
        transform: none
    }

    .navbar-expand-md .offcanvas-bottom, .navbar-expand-md .offcanvas-top {
        height: auto;
        border-top: 0;
        border-bottom: 0
    }

    .navbar-expand-md .offcanvas-body {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media(min-width:992px) {
    .navbar-expand-lg {
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        -webkit-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-lg .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-lg .navbar-collapse {
        display: -webkit-flex !important;
        display: flex !important;
        -webkit-flex-basis: auto;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler, .navbar-expand-lg .offcanvas-header {
        display: none
    }

    .navbar-expand-lg .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        -webkit-flex-grow: 1;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        -webkit-transition: none;
        transition: none;
        -webkit-transform: none;
        transform: none
    }

    .navbar-expand-lg .offcanvas-bottom, .navbar-expand-lg .offcanvas-top {
        height: auto;
        border-top: 0;
        border-bottom: 0
    }

    .navbar-expand-lg .offcanvas-body {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media(min-width:1200px) {
    .navbar-expand-xl {
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        justify-content: flex-start
    }

    .navbar-expand-xl .navbar-nav {
        -webkit-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-xl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xl .navbar-collapse {
        display: -webkit-flex !important;
        display: flex !important;
        -webkit-flex-basis: auto;
        flex-basis: auto
    }

    .navbar-expand-xl .navbar-toggler, .navbar-expand-xl .offcanvas-header {
        display: none
    }

    .navbar-expand-xl .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        -webkit-flex-grow: 1;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        -webkit-transition: none;
        transition: none;
        -webkit-transform: none;
        transform: none
    }

    .navbar-expand-xl .offcanvas-bottom, .navbar-expand-xl .offcanvas-top {
        height: auto;
        border-top: 0;
        border-bottom: 0
    }

    .navbar-expand-xl .offcanvas-body {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media(min-width:1400px) {
    .navbar-expand-xxl {
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        justify-content: flex-start
    }

    .navbar-expand-xxl .navbar-nav {
        -webkit-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-xxl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xxl .navbar-collapse {
        display: -webkit-flex !important;
        display: flex !important;
        -webkit-flex-basis: auto;
        flex-basis: auto
    }

    .navbar-expand-xxl .navbar-toggler, .navbar-expand-xxl .offcanvas-header {
        display: none
    }

    .navbar-expand-xxl .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        -webkit-flex-grow: 1;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        -webkit-transition: none;
        transition: none;
        -webkit-transform: none;
        transform: none
    }

    .navbar-expand-xxl .offcanvas-bottom, .navbar-expand-xxl .offcanvas-top {
        height: auto;
        border-top: 0;
        border-bottom: 0
    }

    .navbar-expand-xxl .offcanvas-body {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

.navbar-expand {
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    justify-content: flex-start
}

.navbar-expand .navbar-nav {
    -webkit-flex-direction: row;
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
}

.navbar-expand .navbar-nav-scroll {
    overflow: visible
}

.navbar-expand .navbar-collapse {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-basis: auto;
    flex-basis: auto
}

.navbar-expand .navbar-toggler, .navbar-expand .offcanvas-header {
    display: none
}

.navbar-expand .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    -webkit-transition: none;
    transition: none;
    -webkit-transform: none;
    transform: none
}

.navbar-expand .offcanvas-bottom, .navbar-expand .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0
}

.navbar-expand .offcanvas-body {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 0;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible
}

.navbar-light .navbar-brand, .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, .55)
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, .7)
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, .3)
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, .55);
    border-color: rgba(0, 0, 0, .1)
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, .55)
}

.navbar-light .navbar-text a, .navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-dark .navbar-brand, .navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, .55)
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, .75)
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, .25)
}

.navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .show>.nav-link {
    color: #fff
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, .55);
    border-color: rgba(255, 255, 255, .1)
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, .55)
}

.navbar-dark .navbar-text a, .navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
    color: #fff
}

.card {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem
}

.card>hr {
    margin-right: 0;
    margin-left: 0
}

.card>.list-group {
    border-top: inherit;
    border-bottom: inherit
}

.card>.list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: -webkit-calc(.25rem - 1px);
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: -webkit-calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.card>.list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: -webkit-calc(.25rem - 1px);
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: -webkit-calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px)
}

.card>.card-header+.list-group, .card>.list-group+.card-footer {
    border-top: 0
}

.card-body {
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem
}

.card-title {
    margin-bottom: .5rem
}

.card-subtitle {
    margin-top: -.25rem
}

.card-subtitle, .card-text:last-child {
    margin-bottom: 0
}

.card-link+.card-link {
    margin-left: 1rem
}

.card-header {
    padding: .5rem 1rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125)
}

.card-header:first-child {
    border-radius: -webkit-calc(.25rem - 1px) -webkit-calc(.25rem - 1px) 0 0;
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0
}

.card-footer {
    padding: .5rem 1rem;
    background-color: rgba(0, 0, 0, .03);
    border-top: 1px solid rgba(0, 0, 0, .125)
}

.card-footer:last-child {
    border-radius: 0 0 -webkit-calc(.25rem - 1px) -webkit-calc(.25rem - 1px);
    border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px)
}

.card-header-tabs {
    margin-bottom: -.5rem;
    border-bottom: 0
}

.card-header-pills, .card-header-tabs {
    margin-right: -.5rem;
    margin-left: -.5rem
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    border-radius: -webkit-calc(.25rem - 1px);
    border-radius: calc(.25rem - 1px)
}

.card-img, .card-img-bottom, .card-img-top {
    width: 100%
}

.card-img, .card-img-top {
    border-top-left-radius: -webkit-calc(.25rem - 1px);
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: -webkit-calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.card-img, .card-img-bottom {
    border-bottom-right-radius: -webkit-calc(.25rem - 1px);
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: -webkit-calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px)
}

.card-group>.card {
    margin-bottom: .75rem
}

@media(min-width:576px) {
    .card-group {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .card-group>.card {
        -webkit-flex: 1 0;
        flex: 1 0;
        margin-bottom: 0
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0
    }

    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-header, .card-group>.card:not(:last-child) .card-img-top {
        border-top-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-footer, .card-group>.card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-header, .card-group>.card:not(:first-child) .card-img-top {
        border-top-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-footer, .card-group>.card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0
    }
}

.accordion-button {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, border-radius .15s ease, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, border-radius .15s ease, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease, -webkit-box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .accordion-button {
        -webkit-transition: none;
        transition: none
    }
}

.accordion-button:not(.collapsed) {
    color: #0c63e4;
    background-color: #e7f1ff;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125)
}

.accordion-button:not(.collapsed):after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg)
}

.accordion-button:after {
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .accordion-button:after {
        -webkit-transition: none;
        transition: none
    }
}

.accordion-button:hover {
    z-index: 2
}

.accordion-button:focus {
    z-index: 3;
    border-color: #86b7fe;
    outline: 0;
    -webkit-box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.accordion-header {
    margin-bottom: 0
}

.accordion-item {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125)
}

.accordion-item:first-of-type {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: -webkit-calc(.25rem - 1px);
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: -webkit-calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.accordion-item:not(:first-of-type) {
    border-top: 0
}

.accordion-item:last-of-type {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: -webkit-calc(.25rem - 1px);
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: -webkit-calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px)
}

.accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.accordion-body {
    padding: 1rem 1.25rem
}

.accordion-flush .accordion-collapse {
    border-width: 0
}

.accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0
}

.accordion-flush .accordion-item:first-child {
    border-top: 0
}

.accordion-flush .accordion-item:last-child {
    border-bottom: 0
}

.accordion-flush .accordion-item .accordion-button {
    border-radius: 0
}

.breadcrumb {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 1rem;
    list-style: none
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: .5rem
}

.breadcrumb-item+.breadcrumb-item:before {
    float: left;
    padding-right: .5rem;
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, "../../../index.html")
}

.breadcrumb-item.active {
    color: #6c757d
}

.pagination {
    display: -webkit-flex;
    display: flex;
    padding-left: 0;
    list-style: none
}

.page-link {
    position: relative;
    display: block;
    color: #0d6efd;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #dee2e6;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .page-link {
        -webkit-transition: none;
        transition: none
    }
}

.page-link:hover {
    z-index: 2;
    border-color: #dee2e6
}

.page-link:focus, .page-link:hover {
    color: var(--bs-primary);
    background-color: #e9ecef
}

.page-link:focus {
    z-index: 3;
    outline: 0;
    -webkit-box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.page-item:not(:first-child) .page-link {
    margin-left: -1px
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff;
    border-color: #dee2e6
}

.page-link {
    padding: .375rem .75rem
}

.page-item:first-child .page-link {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem
}

.pagination-lg .page-link {
    padding: .75rem 1.5rem;
    font-size: 1.25rem
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: .3rem;
    border-bottom-left-radius: .3rem
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem
}

.pagination-sm .page-link {
    padding: .25rem .5rem;
    font-size: .875rem
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
}

.badge {
    display: inline-block;
    padding: .35em .65em;
    font-size: .75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.alert {
    position: relative;
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 700
}

.alert-dismissible {
    padding-right: 3rem
}

.alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem
}

.alert-primary {
    color: #084298;
    background-color: #cfe2ff;
    border-color: #b6d4fe
}

.alert-primary .alert-link {
    color: #06357a
}

.alert-secondary {
    color: #41464b;
    background-color: #e2e3e5;
    border-color: #d3d6d8
}

.alert-secondary .alert-link {
    color: #34383c
}

.alert-success {
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc
}

.alert-success .alert-link {
    color: #0c4128
}

.alert-info {
    color: #055160;
    background-color: #cff4fc;
    border-color: #b6effb
}

.alert-info .alert-link {
    color: #04414d
}

.alert-warning {
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5
}

.alert-warning .alert-link {
    color: #523e02
}

.alert-danger {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7
}

.alert-danger .alert-link {
    color: #6a1a21
}

.alert-light {
    color: #636464;
    background-color: #fefefe;
    border-color: #fdfdfe
}

.alert-light .alert-link {
    color: #4f5050
}

.alert-dark {
    color: #141619;
    background-color: #d3d3d4;
    border-color: #bcbebf
}

.alert-dark .alert-link {
    color: #101214
}

@-webkit-keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem
    }
}

@keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem
    }
}

.progress {
    height: 1rem;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: .25rem
}

.progress, .progress-bar {
    display: -webkit-flex;
    display: flex;
    overflow: hidden
}

.progress-bar {
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #0d6efd;
    -webkit-transition: width .6s ease;
    transition: width .6s ease
}

@media(prefers-reduced-motion:reduce) {
    .progress-bar {
        -webkit-transition: none;
        transition: none
    }
}

.progress-bar-striped {
    background-image: -webkit-linear-gradient(45deg, hsla(0, 0%, 100%, .15) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .15) 0, hsla(0, 0%, 100%, .15) 75%, transparent 0, transparent);
    background-image: linear-gradient(45deg, hsla(0, 0%, 100%, .15) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .15) 0, hsla(0, 0%, 100%, .15) 75%, transparent 0, transparent);
    background-size: 1rem 1rem
}

.progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite
}

@media(prefers-reduced-motion:reduce) {
    .progress-bar-animated {
        -webkit-animation: none;
        animation: none
    }
}

.list-group {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: .25rem
}

.list-group-numbered {
    list-style-type: none;
    counter-reset: section
}

.list-group-numbered>li:before {
    content: counters(section, ".") ". ";
    counter-increment: section
}

.list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit
}

.list-group-item-action:focus, .list-group-item-action:hover {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa
}

.list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef
}

.list-group-item {
    position: relative;
    display: block;
    padding: .5rem 1rem;
    color: #212529;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125)
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
}

.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit
}

.list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd
}

.list-group-item+.list-group-item {
    border-top-width: 0
}

.list-group-item+.list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px
}

.list-group-horizontal {
    -webkit-flex-direction: row;
    flex-direction: row
}

.list-group-horizontal>.list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
}

.list-group-horizontal>.list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0
}

.list-group-horizontal>.list-group-item.active {
    margin-top: 0
}

.list-group-horizontal>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
}

@media(min-width:576px) {
    .list-group-horizontal-sm {
        -webkit-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-sm>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media(min-width:768px) {
    .list-group-horizontal-md {
        -webkit-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-md>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-md>.list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-md>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media(min-width:992px) {
    .list-group-horizontal-lg {
        -webkit-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-lg>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media(min-width:1200px) {
    .list-group-horizontal-xl {
        -webkit-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-xl>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media(min-width:1400px) {
    .list-group-horizontal-xxl {
        -webkit-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-xxl>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

.list-group-flush {
    border-radius: 0
}

.list-group-flush>.list-group-item {
    border-width: 0 0 1px
}

.list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0
}

.list-group-item-primary {
    color: #084298;
    background-color: #cfe2ff
}

.list-group-item-primary.list-group-item-action:focus, .list-group-item-primary.list-group-item-action:hover {
    color: #084298;
    background-color: #bacbe6
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #084298;
    border-color: #084298
}

.list-group-item-secondary {
    color: #41464b;
    background-color: #e2e3e5
}

.list-group-item-secondary.list-group-item-action:focus, .list-group-item-secondary.list-group-item-action:hover {
    color: #41464b;
    background-color: #cbccce
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #41464b;
    border-color: #41464b
}

.list-group-item-success {
    color: #0f5132;
    background-color: #d1e7dd
}

.list-group-item-success.list-group-item-action:focus, .list-group-item-success.list-group-item-action:hover {
    color: #0f5132;
    background-color: #bcd0c7
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #0f5132;
    border-color: #0f5132
}

.list-group-item-info {
    color: #055160;
    background-color: #cff4fc
}

.list-group-item-info.list-group-item-action:focus, .list-group-item-info.list-group-item-action:hover {
    color: #055160;
    background-color: #badce3
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #055160;
    border-color: #055160
}

.list-group-item-warning {
    color: #664d03;
    background-color: #fff3cd
}

.list-group-item-warning.list-group-item-action:focus, .list-group-item-warning.list-group-item-action:hover {
    color: #664d03;
    background-color: #e6dbb9
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #664d03;
    border-color: #664d03
}

.list-group-item-danger {
    color: #842029;
    background-color: #f8d7da
}

.list-group-item-danger.list-group-item-action:focus, .list-group-item-danger.list-group-item-action:hover {
    color: #842029;
    background-color: #dfc2c4
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #842029;
    border-color: #842029
}

.list-group-item-light {
    color: #636464;
    background-color: #fefefe
}

.list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:hover {
    color: #636464;
    background-color: #e5e5e5
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #636464;
    border-color: #636464
}

.list-group-item-dark {
    color: #141619;
    background-color: #d3d3d4
}

.list-group-item-dark.list-group-item-action:focus, .list-group-item-dark.list-group-item-action:hover {
    color: #141619;
    background-color: #bebebf
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #141619;
    border-color: #141619
}

.btn-close {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em;
    color: #000;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") 50%/1em auto no-repeat;
    border: 0;
    border-radius: .25rem;
    opacity: .5
}

.btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75
}

.btn-close:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    opacity: 1
}

.btn-close.disabled, .btn-close:disabled {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: .25
}

.btn-close-white {
    -webkit-filter: invert(1) grayscale(100%) brightness(200%);
    filter: invert(1) grayscale(100%) brightness(200%)
}

.toast {
    width: 350px;
    max-width: 100%;
    font-size: .875rem;
    pointer-events: auto;
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
    border-radius: .25rem
}

.toast.showing {
    opacity: 0
}

.toast:not(.show) {
    display: none
}

.toast-container {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 100%;
    pointer-events: none
}

.toast-container>:not(:last-child) {
    margin-bottom: .75rem
}

.toast-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding: .5rem .75rem;
    color: #6c757d;
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    border-top-left-radius: -webkit-calc(.25rem - 1px);
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: -webkit-calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.toast-header .btn-close {
    margin-right: -.375rem;
    margin-left: .75rem
}

.toast-body {
    padding: .75rem;
    word-wrap: break-word
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none
}

.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform .3s ease-out;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px)
}

@media(prefers-reduced-motion:reduce) {
    .modal.fade .modal-dialog {
        -webkit-transition: none;
        transition: none
    }
}

.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none
}

.modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
    transform: scale(1.02)
}

.modal-dialog-scrollable {
    height: -webkit-calc(100% - 1rem);
    height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}

.modal-dialog-centered {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    min-height: -webkit-calc(100% - 1rem);
    min-height: calc(100% - 1rem)
}

.modal-content {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    outline: 0
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: #000
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: .5
}

.modal-header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: -webkit-calc(.3rem - 1px);
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: -webkit-calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px)
}

.modal-header .btn-close {
    padding: .5rem;
    margin: -.5rem -.5rem -.5rem auto
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5
}

.modal-body {
    position: relative;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem
}

.modal-footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    padding: .75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: -webkit-calc(.3rem - 1px);
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: -webkit-calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px)
}

.modal-footer>* {
    margin: .25rem
}

@media(min-width:576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto
    }

    .modal-dialog-scrollable {
        height: -webkit-calc(100% - 3.5rem);
        height: calc(100% - 3.5rem)
    }

    .modal-dialog-centered {
        min-height: -webkit-calc(100% - 3.5rem);
        min-height: calc(100% - 3.5rem)
    }

    .modal-sm {
        max-width: 300px
    }
}

@media(min-width:992px) {
    .modal-lg, .modal-xl {
        max-width: 800px
    }
}

@media(min-width:1200px) {
    .modal-xl {
        max-width: 1140px
    }
}

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
}

.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0
}

.modal-fullscreen .modal-header {
    border-radius: 0
}

.modal-fullscreen .modal-body {
    overflow-y: auto
}

.modal-fullscreen .modal-footer {
    border-radius: 0
}

@media(max-width:575.98px) {
    .modal-fullscreen-sm-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-sm-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-sm-down .modal-footer {
        border-radius: 0
    }
}

@media(max-width:767.98px) {
    .modal-fullscreen-md-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-md-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-md-down .modal-footer {
        border-radius: 0
    }
}

@media(max-width:991.98px) {
    .modal-fullscreen-lg-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-lg-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-lg-down .modal-footer {
        border-radius: 0
    }
}

@media(max-width:1199.98px) {
    .modal-fullscreen-xl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-xl-down .modal-footer {
        border-radius: 0
    }
}

@media(max-width:1399.98px) {
    .modal-fullscreen-xxl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xxl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-xxl-down .modal-footer {
        border-radius: 0
    }
}

.tooltip {
    position: absolute;
    z-index: 1080;
    display: block;
    margin: 0;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;
    word-wrap: break-word;
    opacity: 0
}

.tooltip.show {
    opacity: .9
}

.tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem
}

.tooltip .tooltip-arrow:before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-tooltip-auto[data-popper-placement^=top], .bs-tooltip-top {
    padding: .4rem 0
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow, .bs-tooltip-top .tooltip-arrow {
    bottom: 0
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow:before, .bs-tooltip-top .tooltip-arrow:before {
    top: -1px;
    border-width: .4rem .4rem 0;
    border-top-color: #000
}

.bs-tooltip-auto[data-popper-placement^=right], .bs-tooltip-end {
    padding: 0 .4rem
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow, .bs-tooltip-end .tooltip-arrow {
    left: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow:before, .bs-tooltip-end .tooltip-arrow:before {
    right: -1px;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #000
}

.bs-tooltip-auto[data-popper-placement^=bottom], .bs-tooltip-bottom {
    padding: .4rem 0
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow, .bs-tooltip-bottom .tooltip-arrow {
    top: 0
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow:before, .bs-tooltip-bottom .tooltip-arrow:before {
    bottom: -1px;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #000
}

.bs-tooltip-auto[data-popper-placement^=left], .bs-tooltip-start {
    padding: 0 .4rem
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow, .bs-tooltip-start .tooltip-arrow {
    right: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow:before, .bs-tooltip-start .tooltip-arrow:before {
    left: -1px;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #000
}

.tooltip-inner {
    max-width: 200px;
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: .25rem
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1070;
    display: block;
    max-width: 276px;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem
}

.popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: .5rem
}

.popover .popover-arrow:after, .popover .popover-arrow:before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow, .bs-popover-top>.popover-arrow {
    bottom: -webkit-calc(-.5rem - 1px);
    bottom: calc(-.5rem - 1px)
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow:before, .bs-popover-top>.popover-arrow:before {
    bottom: 0;
    border-width: .5rem .5rem 0;
    border-top-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow:after, .bs-popover-top>.popover-arrow:after {
    bottom: 1px;
    border-width: .5rem .5rem 0;
    border-top-color: #fff
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow, .bs-popover-end>.popover-arrow {
    left: -webkit-calc(-.5rem - 1px);
    left: calc(-.5rem - 1px);
    width: .5rem;
    height: 1rem
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow:before, .bs-popover-end>.popover-arrow:before {
    left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow:after, .bs-popover-end>.popover-arrow:after {
    left: 1px;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #fff
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow, .bs-popover-bottom>.popover-arrow {
    top: -webkit-calc(-.5rem - 1px);
    top: calc(-.5rem - 1px)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow:before, .bs-popover-bottom>.popover-arrow:before {
    top: 0;
    border-width: 0 .5rem .5rem;
    border-bottom-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow:after, .bs-popover-bottom>.popover-arrow:after {
    top: 1px;
    border-width: 0 .5rem .5rem;
    border-bottom-color: #fff
}

.bs-popover-auto[data-popper-placement^=bottom] .popover-header:before, .bs-popover-bottom .popover-header:before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -.5rem;
    content: "";
    border-bottom: 1px solid #f0f0f0
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow, .bs-popover-start>.popover-arrow {
    right: -webkit-calc(-.5rem - 1px);
    right: calc(-.5rem - 1px);
    width: .5rem;
    height: 1rem
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow:before, .bs-popover-start>.popover-arrow:before {
    right: 0;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow:after, .bs-popover-start>.popover-arrow:after {
    right: 1px;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #fff
}

.popover-header {
    padding: .5rem 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f0f0f0;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    border-top-left-radius: -webkit-calc(.3rem - 1px);
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: -webkit-calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px)
}

.popover-header:empty {
    display: none
}

.popover-body {
    padding: 1rem;
    color: #212529
}

.carousel {
    position: relative
}

.carousel.pointer-event {
    touch-action: pan-y
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-inner:after {
    display: block;
    clear: both;
    content: ""
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform .6s ease-in-out;
    transition: -webkit-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .carousel-item {
        -webkit-transition: none;
        transition: none
    }
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
    display: block
}

.active.carousel-item-end, .carousel-item-next:not(.carousel-item-start) {
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
}

.active.carousel-item-start, .carousel-item-prev:not(.carousel-item-end) {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transform: none;
    transform: none
}

.carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end, .carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1
}

.carousel-fade .active.carousel-item-end, .carousel-fade .active.carousel-item-start {
    z-index: 0;
    opacity: 0;
    -webkit-transition: opacity 0s .6s;
    transition: opacity 0s .6s
}

@media(prefers-reduced-motion:reduce) {
    .carousel-fade .active.carousel-item-end, .carousel-fade .active.carousel-item-start {
        -webkit-transition: none;
        transition: none
    }
}

.carousel-control-next, .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: none;
    border: 0;
    opacity: .5;
    -webkit-transition: opacity .15s ease;
    transition: opacity .15s ease
}

@media(prefers-reduced-motion:reduce) {
    .carousel-control-next, .carousel-control-prev {
        -webkit-transition: none;
        transition: none
    }
}

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-next-icon, .carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators [data-bs-target] {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    -webkit-transition: opacity .6s ease;
    transition: opacity .6s ease
}

@media(prefers-reduced-motion:reduce) {
    .carousel-indicators [data-bs-target] {
        -webkit-transition: none;
        transition: none
    }
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center
}

.carousel-dark .carousel-control-next-icon, .carousel-dark .carousel-control-prev-icon {
    -webkit-filter: invert(1) grayscale(100);
    filter: invert(1) grayscale(100)
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #000
}

.carousel-dark .carousel-caption {
    color: #000
}

@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -.125em;
    border: .25em solid;
    border-right: .25em solid transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: .2em
}

@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -.125em;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow .75s linear infinite;
    animation: spinner-grow .75s linear infinite
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem
}

@media(prefers-reduced-motion:reduce) {
    .spinner-border, .spinner-grow {
        -webkit-animation-duration: 1.5s;
        animation-duration: 1.5s
    }
}

.offcanvas {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    -webkit-transition: -webkit-transform .3s ease-in-out;
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .offcanvas {
        -webkit-transition: none;
        transition: none
    }
}

.offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000
}

.offcanvas-backdrop.fade {
    opacity: 0
}

.offcanvas-backdrop.show {
    opacity: .5
}

.offcanvas-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 1rem
}

.offcanvas-header .btn-close {
    padding: .5rem;
    margin-top: -.5rem;
    margin-right: -.5rem;
    margin-bottom: -.5rem
}

.offcanvas-title {
    margin-bottom: 0;
    line-height: 1.5
}

.offcanvas-body {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    padding: 1rem;
    overflow-y: auto
}

.offcanvas-start {
    top: 0;
    left: 0;
    width: 400px;
    border-right: 1px solid rgba(0, 0, 0, .2);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
}

.offcanvas-end {
    top: 0;
    right: 0;
    width: 400px;
    border-left: 1px solid rgba(0, 0, 0, .2);
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
}

.offcanvas-top {
    top: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
}

.offcanvas-bottom, .offcanvas-top {
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%
}

.offcanvas-bottom {
    border-top: 1px solid rgba(0, 0, 0, .2);
    -webkit-transform: translateY(100%);
    transform: translateY(100%)
}

.offcanvas.show {
    -webkit-transform: none;
    transform: none
}

.placeholder {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    cursor: wait;
    background-color: currentColor;
    opacity: .5
}

.placeholder.btn:before {
    display: inline-block;
    content: ""
}

.placeholder-xs {
    min-height: .6em
}

.placeholder-sm {
    min-height: .8em
}

.placeholder-lg {
    min-height: 1.2em
}

.placeholder-glow .placeholder {
    -webkit-animation: placeholder-glow 2s ease-in-out infinite;
    animation: placeholder-glow 2s ease-in-out infinite
}

@-webkit-keyframes placeholder-glow {
    50% {
        opacity: .2
    }
}

@keyframes placeholder-glow {
    50% {
        opacity: .2
    }
}

.placeholder-wave {
    -webkit-mask-image: -webkit-linear-gradient(320deg, #000 55%, rgba(0, 0, 0, .8) 75%, #000 95%);
    mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, .8) 75%, #000 95%);
    -webkit-mask-size: 200% 100%;
    mask-size: 200% 100%;
    -webkit-animation: placeholder-wave 2s linear infinite;
    animation: placeholder-wave 2s linear infinite
}

@-webkit-keyframes placeholder-wave {
    to {
        -webkit-mask-position: -200% 0;
        mask-position: -200% 0
    }
}

@keyframes placeholder-wave {
    to {
        -webkit-mask-position: -200% 0;
        mask-position: -200% 0
    }
}

.clearfix:after {
    display: block;
    clear: both;
    content: ""
}

.link-primary {
    color: #0d6efd
}

.link-primary:focus, .link-primary:hover {
    color: var(--bs-primary)
}

.link-secondary {
    color: #6c757d
}

.link-secondary:focus, .link-secondary:hover {
    color: #565e64
}

.link-success {
    color: #198754
}

.link-success:focus, .link-success:hover {
    color: #146c43
}

.link-info {
    color: #0dcaf0
}

.link-info:focus, .link-info:hover {
    color: #3dd5f3
}

.link-warning {
    color: #ffc107
}

.link-warning:focus, .link-warning:hover {
    color: #ffcd39
}

.link-danger {
    color: #dc3545
}

.link-danger:focus, .link-danger:hover {
    color: #b02a37
}

.link-light {
    color: #f8f9fa
}

.link-light:focus, .link-light:hover {
    color: #f9fafb
}

.link-dark {
    color: #212529
}

.link-dark:focus, .link-dark:hover {
    color: #1a1e21
}

.ratio {
    position: relative;
    width: 100%
}

.ratio:before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""
}

.ratio>* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.ratio-1x1 {
    --bs-aspect-ratio: 100%
}

.ratio-4x3 {
    --bs-aspect-ratio: 75%
}

.ratio-16x9 {
    --bs-aspect-ratio: 56.25%
}

.ratio-21x9 {
    --bs-aspect-ratio: 42.8571428571%
}

.fixed-top {
    top: 0
}

.fixed-bottom, .fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    bottom: 0
}

.sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020
}

@media(min-width:576px) {
    .sticky-sm-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media(min-width:768px) {
    .sticky-md-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media(min-width:992px) {
    .sticky-lg-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media(min-width:1200px) {
    .sticky-xl-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media(min-width:1400px) {
    .sticky-xxl-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

.hstack {
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center
}

.hstack, .vstack {
    display: -webkit-flex;
    display: flex;
    -webkit-align-self: stretch;
    align-self: stretch
}

.vstack {
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex-direction: column;
    flex-direction: column
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important
}

.stretched-link:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: ""
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.vr {
    display: inline-block;
    -webkit-align-self: stretch;
    align-self: stretch;
    width: 1px;
    min-height: 1em;
    background-color: currentColor;
    opacity: .25
}

.align-baseline {
    vertical-align: baseline !important
}

.align-top {
    vertical-align: top !important
}

.align-middle {
    vertical-align: middle !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

.float-start {
    float: left !important
}

.float-end {
    float: right !important
}

.float-none {
    float: none !important
}

.opacity-0 {
    opacity: 0 !important
}

.opacity-25 {
    opacity: .25 !important
}

.opacity-50 {
    opacity: .5 !important
}

.opacity-75 {
    opacity: .75 !important
}

.opacity-100 {
    opacity: 1 !important
}

.overflow-auto {
    overflow: auto !important
}

.overflow-hidden {
    overflow: hidden !important
}

.overflow-visible {
    overflow: visible !important
}

.overflow-scroll {
    overflow: scroll !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-grid {
    display: grid !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: -webkit-flex !important;
    display: flex !important
}

.d-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important
}

.d-none {
    display: none !important
}

.shadow {
    -webkit-box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important
}

.shadow-sm {
    -webkit-box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important
}

.shadow-lg {
    -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important
}

.shadow-none {
    -webkit-box-shadow: none !important;
    box-shadow: none !important
}

.position-static {
    position: static !important
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.position-sticky {
    position: sticky !important
}

.top-0 {
    top: 0 !important
}

.top-50 {
    top: 50% !important
}

.top-100 {
    top: 100% !important
}

.bottom-0 {
    bottom: 0 !important
}

.bottom-50 {
    bottom: 50% !important
}

.bottom-100 {
    bottom: 100% !important
}

.start-0 {
    left: 0 !important
}

.start-50 {
    left: 50% !important
}

.start-100 {
    left: 100% !important
}

.end-0 {
    right: 0 !important
}

.end-50 {
    right: 50% !important
}

.end-100 {
    right: 100% !important
}

.translate-middle {
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important
}

.translate-middle-x {
    -webkit-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important
}

.translate-middle-y {
    -webkit-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important
}

.border {
    border: 1px solid #dee2e6 !important
}

.border-0 {
    border: 0 !important
}

.border-top {
    border-top: 1px solid #dee2e6 !important
}

.border-top-0 {
    border-top: 0 !important
}

.border-end {
    border-right: 1px solid #dee2e6 !important
}

.border-end-0 {
    border-right: 0 !important
}

.border-bottom {
    border-bottom: 1px solid #dee2e6 !important
}

.border-bottom-0 {
    border-bottom: 0 !important
}

.border-start {
    border-left: 1px solid #dee2e6 !important
}

.border-start-0 {
    border-left: 0 !important
}

.border-primary {
    border-color: #0d6efd !important
}

.border-secondary {
    border-color: #6c757d !important
}

.border-success {
    border-color: #198754 !important
}

.border-info {
    border-color: #0dcaf0 !important
}

.border-warning {
    border-color: #ffc107 !important
}

.border-danger {
    border-color: #dc3545 !important
}

.border-light {
    border-color: #f8f9fa !important
}

.border-dark {
    border-color: #212529 !important
}

.border-white {
    border-color: #fff !important
}

.border-1 {
    border-width: 1px !important
}

.border-2 {
    border-width: 2px !important
}

.border-3 {
    border-width: 3px !important
}

.border-4 {
    border-width: 4px !important
}

.border-5 {
    border-width: 5px !important
}

.w-25 {
    width: 25% !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.w-auto {
    width: auto !important
}

.mw-100 {
    max-width: 100% !important
}

.vw-100 {
    width: 100vw !important
}

.min-vw-100 {
    min-width: 100vw !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

.h-auto {
    height: auto !important
}

.mh-100 {
    max-height: 100% !important
}

.vh-100 {
    height: 100vh !important
}

.min-vh-100 {
    min-height: 100vh !important
}

.flex-fill {
    -webkit-flex: 1 1 auto !important;
    flex: 1 1 auto !important
}

.flex-row {
    -webkit-flex-direction: row !important;
    flex-direction: row !important
}

.flex-column {
    -webkit-flex-direction: column !important;
    flex-direction: column !important
}

.flex-row-reverse {
    -webkit-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    -webkit-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important
}

.flex-grow-0 {
    -webkit-flex-grow: 0 !important;
    flex-grow: 0 !important
}

.flex-grow-1 {
    -webkit-flex-grow: 1 !important;
    flex-grow: 1 !important
}

.flex-shrink-0 {
    -webkit-flex-shrink: 0 !important;
    flex-shrink: 0 !important
}

.flex-shrink-1 {
    -webkit-flex-shrink: 1 !important;
    flex-shrink: 1 !important
}

.flex-wrap {
    -webkit-flex-wrap: wrap !important;
    flex-wrap: wrap !important
}

.flex-nowrap {
    -webkit-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important
}

.gap-0 {
    grid-gap: 0 !important;
    gap: 0 !important
}

.gap-1 {
    grid-gap: .25rem !important;
    gap: .25rem !important
}

.gap-2 {
    grid-gap: .5rem !important;
    gap: .5rem !important
}

.gap-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important
}

.gap-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important
}

.gap-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important
}

.justify-content-start {
    -webkit-justify-content: flex-start !important;
    justify-content: flex-start !important
}

.justify-content-end {
    -webkit-justify-content: flex-end !important;
    justify-content: flex-end !important
}

.justify-content-center {
    -webkit-justify-content: center !important;
    justify-content: center !important
}

.justify-content-between {
    -webkit-justify-content: space-between !important;
    justify-content: space-between !important
}

.justify-content-around {
    -webkit-justify-content: space-around !important;
    justify-content: space-around !important
}

.justify-content-evenly {
    -webkit-justify-content: space-evenly !important;
    justify-content: space-evenly !important
}

.align-items-start {
    -webkit-align-items: flex-start !important;
    align-items: flex-start !important
}

.align-items-end {
    -webkit-align-items: flex-end !important;
    align-items: flex-end !important
}

.align-items-center {
    -webkit-align-items: center !important;
    align-items: center !important
}

.align-items-baseline {
    -webkit-align-items: baseline !important;
    align-items: baseline !important
}

.align-items-stretch {
    -webkit-align-items: stretch !important;
    align-items: stretch !important
}

.align-content-start {
    -webkit-align-content: flex-start !important;
    align-content: flex-start !important
}

.align-content-end {
    -webkit-align-content: flex-end !important;
    align-content: flex-end !important
}

.align-content-center {
    -webkit-align-content: center !important;
    align-content: center !important
}

.align-content-between {
    -webkit-align-content: space-between !important;
    align-content: space-between !important
}

.align-content-around {
    -webkit-align-content: space-around !important;
    align-content: space-around !important
}

.align-content-stretch {
    -webkit-align-content: stretch !important;
    align-content: stretch !important
}

.align-self-auto {
    -webkit-align-self: auto !important;
    align-self: auto !important
}

.align-self-start {
    -webkit-align-self: flex-start !important;
    align-self: flex-start !important
}

.align-self-end {
    -webkit-align-self: flex-end !important;
    align-self: flex-end !important
}

.align-self-center {
    -webkit-align-self: center !important;
    align-self: center !important
}

.align-self-baseline {
    -webkit-align-self: baseline !important;
    align-self: baseline !important
}

.align-self-stretch {
    -webkit-align-self: stretch !important;
    align-self: stretch !important
}

.order-first {
    -webkit-order: -1 !important;
    order: -1 !important
}

.order-0 {
    -webkit-order: 0 !important;
    order: 0 !important
}

.order-1 {
    -webkit-order: 1 !important;
    order: 1 !important
}

.order-2 {
    -webkit-order: 2 !important;
    order: 2 !important
}

.order-3 {
    -webkit-order: 3 !important;
    order: 3 !important
}

.order-4 {
    -webkit-order: 4 !important;
    order: 4 !important
}

.order-5 {
    -webkit-order: 5 !important;
    order: 5 !important
}

.order-last {
    -webkit-order: 6 !important;
    order: 6 !important
}

.m-0 {
    margin: 0 !important
}

.m-1 {
    margin: .25rem !important
}

.m-2 {
    margin: .5rem !important
}

.m-3 {
    margin: 1rem !important
}

.m-4 {
    margin: 1.5rem !important
}

.m-5 {
    margin: 3rem !important
}

.m-auto {
    margin: auto !important
}

.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
}

.mx-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important
}

.mx-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important
}

.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important
}

.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
}

.mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
}

.my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
}

.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
}

.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
}

.mt-0 {
    margin-top: 0 !important
}

.mt-1 {
    margin-top: .25rem !important
}

.mt-2 {
    margin-top: .5rem !important
}

.mt-3 {
    margin-top: 1rem !important
}

.mt-4 {
    margin-top: 1.5rem !important
}

.mt-5 {
    margin-top: 3rem !important
}

.mt-auto {
    margin-top: auto !important
}

.me-0 {
    margin-right: 0 !important
}

.me-1 {
    margin-right: .25rem !important
}

.me-2 {
    margin-right: .5rem !important
}

.me-3 {
    margin-right: 1rem !important
}

.me-4 {
    margin-right: 1.5rem !important
}

.me-5 {
    margin-right: 3rem !important
}

.me-auto {
    margin-right: auto !important
}

.mb-0 {
    margin-bottom: 0 !important
}

.mb-1 {
    margin-bottom: .25rem !important
}

.mb-2 {
    margin-bottom: .5rem !important
}

.mb-3 {
    margin-bottom: 1rem !important
}

.mb-4 {
    margin-bottom: 1.5rem !important
}

.mb-5 {
    margin-bottom: 3rem !important
}

.mb-auto {
    margin-bottom: auto !important
}

.ms-0 {
    margin-left: 0 !important
}

.ms-1 {
    margin-left: .25rem !important
}

.ms-2 {
    margin-left: .5rem !important
}

.ms-3 {
    margin-left: 1rem !important
}

.ms-4 {
    margin-left: 1.5rem !important
}

.ms-5 {
    margin-left: 3rem !important
}

.ms-auto {
    margin-left: auto !important
}

.p-0 {
    padding: 0 !important
}

.p-1 {
    padding: .25rem !important
}

.p-2 {
    padding: .5rem !important
}

.p-3 {
    padding: 1rem !important
}

.p-4 {
    padding: 1.5rem !important
}

.p-5 {
    padding: 3rem !important
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
}

.px-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
}

.px-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
}

.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important
}

.py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
}

.pt-0 {
    padding-top: 0 !important
}

.pt-1 {
    padding-top: .25rem !important
}

.pt-2 {
    padding-top: .5rem !important
}

.pt-3 {
    padding-top: 1rem !important
}

.pt-4 {
    padding-top: 1.5rem !important
}

.pt-5 {
    padding-top: 3rem !important
}

.pe-0 {
    padding-right: 0 !important
}

.pe-1 {
    padding-right: .25rem !important
}

.pe-2 {
    padding-right: .5rem !important
}

.pe-3 {
    padding-right: 1rem !important
}

.pe-4 {
    padding-right: 1.5rem !important
}

.pe-5 {
    padding-right: 3rem !important
}

.pb-0 {
    padding-bottom: 0 !important
}

.pb-1 {
    padding-bottom: .25rem !important
}

.pb-2 {
    padding-bottom: .5rem !important
}

.pb-3 {
    padding-bottom: 1rem !important
}

.pb-4 {
    padding-bottom: 1.5rem !important
}

.pb-5 {
    padding-bottom: 3rem !important
}

.ps-0 {
    padding-left: 0 !important
}

.ps-1 {
    padding-left: .25rem !important
}

.ps-2 {
    padding-left: .5rem !important
}

.ps-3 {
    padding-left: 1rem !important
}

.ps-4 {
    padding-left: 1.5rem !important
}

.ps-5 {
    padding-left: 3rem !important
}

.font-monospace {
    font-family: var(--bs-font-monospace) !important
}

.fs-1 {
    font-size: -webkit-calc(1.375rem + 1.5vw) !important;
    font-size: calc(1.375rem + 1.5vw) !important
}

.fs-2 {
    font-size: -webkit-calc(1.325rem + .9vw) !important;
    font-size: calc(1.325rem + .9vw) !important
}

.fs-3 {
    font-size: -webkit-calc(1.3rem + .6vw) !important;
    font-size: calc(1.3rem + .6vw) !important
}

.fs-4 {
    font-size: -webkit-calc(1.275rem + .3vw) !important;
    font-size: calc(1.275rem + .3vw) !important
}

.fs-5 {
    font-size: 1.25rem !important
}

.fs-6 {
    font-size: 1rem !important
}

.fst-italic {
    font-style: italic !important
}

.fst-normal {
    font-style: normal !important
}

.fw-light {
    font-weight: 300 !important
}

.fw-lighter {
    font-weight: lighter !important
}

.fw-normal {
    font-weight: 400 !important
}

.fw-bold {
    font-weight: 700 !important
}

.fw-bolder {
    font-weight: bolder !important
}

.lh-1 {
    line-height: 1 !important
}

.lh-sm {
    line-height: 1.25 !important
}

.lh-base {
    line-height: 1.5 !important
}

.lh-lg {
    line-height: 2 !important
}

.text-start {
    text-align: left !important
}

.text-end {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

.text-decoration-none {
    text-decoration: none !important
}

.text-decoration-underline {
    text-decoration: underline !important
}

.text-decoration-line-through {
    text-decoration: line-through !important
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-break {
    word-wrap: break-word !important;
    word-break: break-word !important
}

.text-primary {
    
    color: var(--bs-primary) !important
}

.text-secondary {
    color: var(--bs-secondary) !important
}

.text-success {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important
}

.text-info {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important
}

.text-warning {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important
}

.text-danger {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important
}

.text-light {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important
}

.text-dark {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important
}

.text-black {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important
}

.text-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important
}

.text-body {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important
}

.text-muted {
    --bs-text-opacity: 1;
    color: #6c757d !important
}

.text-black-50 {
    --bs-text-opacity: 1;
    color: rgba(0, 0, 0, .5) !important
}

.text-white-50 {
    --bs-text-opacity: 1;
    color: rgba(255, 255, 255, .5) !important
}

.text-reset {
    --bs-text-opacity: 1;
    color: inherit !important
}

.text-opacity-25 {
    --bs-text-opacity: 0.25
}

.text-opacity-50 {
    --bs-text-opacity: 0.5
}

.text-opacity-75 {
    --bs-text-opacity: 0.75
}

.text-opacity-100 {
    --bs-text-opacity: 1
}

.bg-primary {
    
    background-color: var(--bs-primary) !important
}

.bg-secondary {
   
    background-color: var(--bs-secondary) !important
}

.bg-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important
}

.bg-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important
}

.bg-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important
}

.bg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important
}

.bg-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important
}

.bg-black {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important
}

.bg-white {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important
}

.bg-body {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important
}

.bg-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important
}

.bg-opacity-10 {
    --bs-bg-opacity: 0.1
}

.bg-opacity-25 {
    --bs-bg-opacity: 0.25
}

.bg-opacity-50 {
    --bs-bg-opacity: 0.5
}

.bg-opacity-75 {
    --bs-bg-opacity: 0.75
}

.bg-opacity-100 {
    --bs-bg-opacity: 1
}

.bg-gradient {
    background-image: var(--bs-gradient) !important
}

.user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    -ms-user-select: all !important;
    user-select: all !important
}

.user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important
}

.user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important
}

.pe-none {
    pointer-events: none !important
}

.pe-auto {
    pointer-events: auto !important
}

.rounded {
    border-radius: .25rem !important
}

.rounded-0 {
    border-radius: 0 !important
}

.rounded-1 {
    border-radius: .2rem !important
}

.rounded-2 {
    border-radius: .25rem !important
}

.rounded-3 {
    border-radius: .3rem !important
}

.rounded-circle {
    border-radius: 50% !important
}

.rounded-pill {
    border-radius: 50rem !important
}

.rounded-top {
    border-top-left-radius: .25rem !important
}

.rounded-end, .rounded-top {
    border-top-right-radius: .25rem !important
}

.rounded-bottom, .rounded-end {
    border-bottom-right-radius: .25rem !important
}

.rounded-bottom, .rounded-start {
    border-bottom-left-radius: .25rem !important
}

.rounded-start {
    border-top-left-radius: .25rem !important
}

.visible {
    visibility: visible !important
}

.invisible {
    visibility: hidden !important
}

@media(min-width:576px) {
    .float-sm-start {
        float: left !important
    }

    .float-sm-end {
        float: right !important
    }

    .float-sm-none {
        float: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-grid {
        display: grid !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-row {
        display: table-row !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: -webkit-flex !important;
        display: flex !important
    }

    .d-sm-inline-flex {
        display: -webkit-inline-flex !important;
        display: inline-flex !important
    }

    .d-sm-none {
        display: none !important
    }

    .flex-sm-fill {
        -webkit-flex: 1 1 auto !important;
        flex: 1 1 auto !important
    }

    .flex-sm-row {
        -webkit-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-sm-column {
        -webkit-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-sm-row-reverse {
        -webkit-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-sm-column-reverse {
        -webkit-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-sm-grow-0 {
        -webkit-flex-grow: 0 !important;
        flex-grow: 0 !important
    }

    .flex-sm-grow-1 {
        -webkit-flex-grow: 1 !important;
        flex-grow: 1 !important
    }

    .flex-sm-shrink-0 {
        -webkit-flex-shrink: 0 !important;
        flex-shrink: 0 !important
    }

    .flex-sm-shrink-1 {
        -webkit-flex-shrink: 1 !important;
        flex-shrink: 1 !important
    }

    .flex-sm-wrap {
        -webkit-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-sm-nowrap {
        -webkit-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-sm-wrap-reverse {
        -webkit-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .gap-sm-0 {
        grid-gap: 0 !important;
        gap: 0 !important
    }

    .gap-sm-1 {
        grid-gap: .25rem !important;
        gap: .25rem !important
    }

    .gap-sm-2 {
        grid-gap: .5rem !important;
        gap: .5rem !important
    }

    .gap-sm-3 {
        grid-gap: 1rem !important;
        gap: 1rem !important
    }

    .gap-sm-4 {
        grid-gap: 1.5rem !important;
        gap: 1.5rem !important
    }

    .gap-sm-5 {
        grid-gap: 3rem !important;
        gap: 3rem !important
    }

    .justify-content-sm-start {
        -webkit-justify-content: flex-start !important;
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        -webkit-justify-content: flex-end !important;
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        -webkit-justify-content: center !important;
        justify-content: center !important
    }

    .justify-content-sm-between {
        -webkit-justify-content: space-between !important;
        justify-content: space-between !important
    }

    .justify-content-sm-around {
        -webkit-justify-content: space-around !important;
        justify-content: space-around !important
    }

    .justify-content-sm-evenly {
        -webkit-justify-content: space-evenly !important;
        justify-content: space-evenly !important
    }

    .align-items-sm-start {
        -webkit-align-items: flex-start !important;
        align-items: flex-start !important
    }

    .align-items-sm-end {
        -webkit-align-items: flex-end !important;
        align-items: flex-end !important
    }

    .align-items-sm-center {
        -webkit-align-items: center !important;
        align-items: center !important
    }

    .align-items-sm-baseline {
        -webkit-align-items: baseline !important;
        align-items: baseline !important
    }

    .align-items-sm-stretch {
        -webkit-align-items: stretch !important;
        align-items: stretch !important
    }

    .align-content-sm-start {
        -webkit-align-content: flex-start !important;
        align-content: flex-start !important
    }

    .align-content-sm-end {
        -webkit-align-content: flex-end !important;
        align-content: flex-end !important
    }

    .align-content-sm-center {
        -webkit-align-content: center !important;
        align-content: center !important
    }

    .align-content-sm-between {
        -webkit-align-content: space-between !important;
        align-content: space-between !important
    }

    .align-content-sm-around {
        -webkit-align-content: space-around !important;
        align-content: space-around !important
    }

    .align-content-sm-stretch {
        -webkit-align-content: stretch !important;
        align-content: stretch !important
    }

    .align-self-sm-auto {
        -webkit-align-self: auto !important;
        align-self: auto !important
    }

    .align-self-sm-start {
        -webkit-align-self: flex-start !important;
        align-self: flex-start !important
    }

    .align-self-sm-end {
        -webkit-align-self: flex-end !important;
        align-self: flex-end !important
    }

    .align-self-sm-center {
        -webkit-align-self: center !important;
        align-self: center !important
    }

    .align-self-sm-baseline {
        -webkit-align-self: baseline !important;
        align-self: baseline !important
    }

    .align-self-sm-stretch {
        -webkit-align-self: stretch !important;
        align-self: stretch !important
    }

    .order-sm-first {
        -webkit-order: -1 !important;
        order: -1 !important
    }

    .order-sm-0 {
        -webkit-order: 0 !important;
        order: 0 !important
    }

    .order-sm-1 {
        -webkit-order: 1 !important;
        order: 1 !important
    }

    .order-sm-2 {
        -webkit-order: 2 !important;
        order: 2 !important
    }

    .order-sm-3 {
        -webkit-order: 3 !important;
        order: 3 !important
    }

    .order-sm-4 {
        -webkit-order: 4 !important;
        order: 4 !important
    }

    .order-sm-5 {
        -webkit-order: 5 !important;
        order: 5 !important
    }

    .order-sm-last {
        -webkit-order: 6 !important;
        order: 6 !important
    }

    .m-sm-0 {
        margin: 0 !important
    }

    .m-sm-1 {
        margin: .25rem !important
    }

    .m-sm-2 {
        margin: .5rem !important
    }

    .m-sm-3 {
        margin: 1rem !important
    }

    .m-sm-4 {
        margin: 1.5rem !important
    }

    .m-sm-5 {
        margin: 3rem !important
    }

    .m-sm-auto {
        margin: auto !important
    }

    .mx-sm-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-sm-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-sm-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-sm-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-sm-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-sm-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-sm-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-sm-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-sm-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-sm-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-sm-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-sm-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-sm-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-sm-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-sm-0 {
        margin-top: 0 !important
    }

    .mt-sm-1 {
        margin-top: .25rem !important
    }

    .mt-sm-2 {
        margin-top: .5rem !important
    }

    .mt-sm-3 {
        margin-top: 1rem !important
    }

    .mt-sm-4 {
        margin-top: 1.5rem !important
    }

    .mt-sm-5 {
        margin-top: 3rem !important
    }

    .mt-sm-auto {
        margin-top: auto !important
    }

    .me-sm-0 {
        margin-right: 0 !important
    }

    .me-sm-1 {
        margin-right: .25rem !important
    }

    .me-sm-2 {
        margin-right: .5rem !important
    }

    .me-sm-3 {
        margin-right: 1rem !important
    }

    .me-sm-4 {
        margin-right: 1.5rem !important
    }

    .me-sm-5 {
        margin-right: 3rem !important
    }

    .me-sm-auto {
        margin-right: auto !important
    }

    .mb-sm-0 {
        margin-bottom: 0 !important
    }

    .mb-sm-1 {
        margin-bottom: .25rem !important
    }

    .mb-sm-2 {
        margin-bottom: .5rem !important
    }

    .mb-sm-3 {
        margin-bottom: 1rem !important
    }

    .mb-sm-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-sm-5 {
        margin-bottom: 3rem !important
    }

    .mb-sm-auto {
        margin-bottom: auto !important
    }

    .ms-sm-0 {
        margin-left: 0 !important
    }

    .ms-sm-1 {
        margin-left: .25rem !important
    }

    .ms-sm-2 {
        margin-left: .5rem !important
    }

    .ms-sm-3 {
        margin-left: 1rem !important
    }

    .ms-sm-4 {
        margin-left: 1.5rem !important
    }

    .ms-sm-5 {
        margin-left: 3rem !important
    }

    .ms-sm-auto {
        margin-left: auto !important
    }

    .p-sm-0 {
        padding: 0 !important
    }

    .p-sm-1 {
        padding: .25rem !important
    }

    .p-sm-2 {
        padding: .5rem !important
    }

    .p-sm-3 {
        padding: 1rem !important
    }

    .p-sm-4 {
        padding: 1.5rem !important
    }

    .p-sm-5 {
        padding: 3rem !important
    }

    .px-sm-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-sm-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-sm-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-sm-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-sm-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-sm-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-sm-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-sm-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-sm-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-sm-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-sm-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-sm-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-sm-0 {
        padding-top: 0 !important
    }

    .pt-sm-1 {
        padding-top: .25rem !important
    }

    .pt-sm-2 {
        padding-top: .5rem !important
    }

    .pt-sm-3 {
        padding-top: 1rem !important
    }

    .pt-sm-4 {
        padding-top: 1.5rem !important
    }

    .pt-sm-5 {
        padding-top: 3rem !important
    }

    .pe-sm-0 {
        padding-right: 0 !important
    }

    .pe-sm-1 {
        padding-right: .25rem !important
    }

    .pe-sm-2 {
        padding-right: .5rem !important
    }

    .pe-sm-3 {
        padding-right: 1rem !important
    }

    .pe-sm-4 {
        padding-right: 1.5rem !important
    }

    .pe-sm-5 {
        padding-right: 3rem !important
    }

    .pb-sm-0 {
        padding-bottom: 0 !important
    }

    .pb-sm-1 {
        padding-bottom: .25rem !important
    }

    .pb-sm-2 {
        padding-bottom: .5rem !important
    }

    .pb-sm-3 {
        padding-bottom: 1rem !important
    }

    .pb-sm-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-sm-5 {
        padding-bottom: 3rem !important
    }

    .ps-sm-0 {
        padding-left: 0 !important
    }

    .ps-sm-1 {
        padding-left: .25rem !important
    }

    .ps-sm-2 {
        padding-left: .5rem !important
    }

    .ps-sm-3 {
        padding-left: 1rem !important
    }

    .ps-sm-4 {
        padding-left: 1.5rem !important
    }

    .ps-sm-5 {
        padding-left: 3rem !important
    }

    .text-sm-start {
        text-align: left !important
    }

    .text-sm-end {
        text-align: right !important
    }

    .text-sm-center {
        text-align: center !important
    }
}

@media(min-width:768px) {
    .float-md-start {
        float: left !important
    }

    .float-md-end {
        float: right !important
    }

    .float-md-none {
        float: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-grid {
        display: grid !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: -webkit-flex !important;
        display: flex !important
    }

    .d-md-inline-flex {
        display: -webkit-inline-flex !important;
        display: inline-flex !important
    }

    .d-md-none {
        display: none !important
    }

    .flex-md-fill {
        -webkit-flex: 1 1 auto !important;
        flex: 1 1 auto !important
    }

    .flex-md-row {
        -webkit-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-md-column {
        -webkit-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-md-row-reverse {
        -webkit-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-md-column-reverse {
        -webkit-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-md-grow-0 {
        -webkit-flex-grow: 0 !important;
        flex-grow: 0 !important
    }

    .flex-md-grow-1 {
        -webkit-flex-grow: 1 !important;
        flex-grow: 1 !important
    }

    .flex-md-shrink-0 {
        -webkit-flex-shrink: 0 !important;
        flex-shrink: 0 !important
    }

    .flex-md-shrink-1 {
        -webkit-flex-shrink: 1 !important;
        flex-shrink: 1 !important
    }

    .flex-md-wrap {
        -webkit-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-md-nowrap {
        -webkit-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-md-wrap-reverse {
        -webkit-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .gap-md-0 {
        grid-gap: 0 !important;
        gap: 0 !important
    }

    .gap-md-1 {
        grid-gap: .25rem !important;
        gap: .25rem !important
    }

    .gap-md-2 {
        grid-gap: .5rem !important;
        gap: .5rem !important
    }

    .gap-md-3 {
        grid-gap: 1rem !important;
        gap: 1rem !important
    }

    .gap-md-4 {
        grid-gap: 1.5rem !important;
        gap: 1.5rem !important
    }

    .gap-md-5 {
        grid-gap: 3rem !important;
        gap: 3rem !important
    }

    .justify-content-md-start {
        -webkit-justify-content: flex-start !important;
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        -webkit-justify-content: flex-end !important;
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        -webkit-justify-content: center !important;
        justify-content: center !important
    }

    .justify-content-md-between {
        -webkit-justify-content: space-between !important;
        justify-content: space-between !important
    }

    .justify-content-md-around {
        -webkit-justify-content: space-around !important;
        justify-content: space-around !important
    }

    .justify-content-md-evenly {
        -webkit-justify-content: space-evenly !important;
        justify-content: space-evenly !important
    }

    .align-items-md-start {
        -webkit-align-items: flex-start !important;
        align-items: flex-start !important
    }

    .align-items-md-end {
        -webkit-align-items: flex-end !important;
        align-items: flex-end !important
    }

    .align-items-md-center {
        -webkit-align-items: center !important;
        align-items: center !important
    }

    .align-items-md-baseline {
        -webkit-align-items: baseline !important;
        align-items: baseline !important
    }

    .align-items-md-stretch {
        -webkit-align-items: stretch !important;
        align-items: stretch !important
    }

    .align-content-md-start {
        -webkit-align-content: flex-start !important;
        align-content: flex-start !important
    }

    .align-content-md-end {
        -webkit-align-content: flex-end !important;
        align-content: flex-end !important
    }

    .align-content-md-center {
        -webkit-align-content: center !important;
        align-content: center !important
    }

    .align-content-md-between {
        -webkit-align-content: space-between !important;
        align-content: space-between !important
    }

    .align-content-md-around {
        -webkit-align-content: space-around !important;
        align-content: space-around !important
    }

    .align-content-md-stretch {
        -webkit-align-content: stretch !important;
        align-content: stretch !important
    }

    .align-self-md-auto {
        -webkit-align-self: auto !important;
        align-self: auto !important
    }

    .align-self-md-start {
        -webkit-align-self: flex-start !important;
        align-self: flex-start !important
    }

    .align-self-md-end {
        -webkit-align-self: flex-end !important;
        align-self: flex-end !important
    }

    .align-self-md-center {
        -webkit-align-self: center !important;
        align-self: center !important
    }

    .align-self-md-baseline {
        -webkit-align-self: baseline !important;
        align-self: baseline !important
    }

    .align-self-md-stretch {
        -webkit-align-self: stretch !important;
        align-self: stretch !important
    }

    .order-md-first {
        -webkit-order: -1 !important;
        order: -1 !important
    }

    .order-md-0 {
        -webkit-order: 0 !important;
        order: 0 !important
    }

    .order-md-1 {
        -webkit-order: 1 !important;
        order: 1 !important
    }

    .order-md-2 {
        -webkit-order: 2 !important;
        order: 2 !important
    }

    .order-md-3 {
        -webkit-order: 3 !important;
        order: 3 !important
    }

    .order-md-4 {
        -webkit-order: 4 !important;
        order: 4 !important
    }

    .order-md-5 {
        -webkit-order: 5 !important;
        order: 5 !important
    }

    .order-md-last {
        -webkit-order: 6 !important;
        order: 6 !important
    }

    .m-md-0 {
        margin: 0 !important
    }

    .m-md-1 {
        margin: .25rem !important
    }

    .m-md-2 {
        margin: .5rem !important
    }

    .m-md-3 {
        margin: 1rem !important
    }

    .m-md-4 {
        margin: 1.5rem !important
    }

    .m-md-5 {
        margin: 3rem !important
    }

    .m-md-auto {
        margin: auto !important
    }

    .mx-md-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-md-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-md-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-md-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-md-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-md-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-md-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-md-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-md-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-md-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-md-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-md-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-md-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-md-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-md-0 {
        margin-top: 0 !important
    }

    .mt-md-1 {
        margin-top: .25rem !important
    }

    .mt-md-2 {
        margin-top: .5rem !important
    }

    .mt-md-3 {
        margin-top: 1rem !important
    }

    .mt-md-4 {
        margin-top: 1.5rem !important
    }

    .mt-md-5 {
        margin-top: 3rem !important
    }

    .mt-md-auto {
        margin-top: auto !important
    }

    .me-md-0 {
        margin-right: 0 !important
    }

    .me-md-1 {
        margin-right: .25rem !important
    }

    .me-md-2 {
        margin-right: .5rem !important
    }

    .me-md-3 {
        margin-right: 1rem !important
    }

    .me-md-4 {
        margin-right: 1.5rem !important
    }

    .me-md-5 {
        margin-right: 3rem !important
    }

    .me-md-auto {
        margin-right: auto !important
    }

    .mb-md-0 {
        margin-bottom: 0 !important
    }

    .mb-md-1 {
        margin-bottom: .25rem !important
    }

    .mb-md-2 {
        margin-bottom: .5rem !important
    }

    .mb-md-3 {
        margin-bottom: 1rem !important
    }

    .mb-md-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-md-5 {
        margin-bottom: 3rem !important
    }

    .mb-md-auto {
        margin-bottom: auto !important
    }

    .ms-md-0 {
        margin-left: 0 !important
    }

    .ms-md-1 {
        margin-left: .25rem !important
    }

    .ms-md-2 {
        margin-left: .5rem !important
    }

    .ms-md-3 {
        margin-left: 1rem !important
    }

    .ms-md-4 {
        margin-left: 1.5rem !important
    }

    .ms-md-5 {
        margin-left: 3rem !important
    }

    .ms-md-auto {
        margin-left: auto !important
    }

    .p-md-0 {
        padding: 0 !important
    }

    .p-md-1 {
        padding: .25rem !important
    }

    .p-md-2 {
        padding: .5rem !important
    }

    .p-md-3 {
        padding: 1rem !important
    }

    .p-md-4 {
        padding: 1.5rem !important
    }

    .p-md-5 {
        padding: 3rem !important
    }

    .px-md-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-md-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-md-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-md-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-md-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-md-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-md-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-md-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-md-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-md-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-md-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-md-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-md-0 {
        padding-top: 0 !important
    }

    .pt-md-1 {
        padding-top: .25rem !important
    }

    .pt-md-2 {
        padding-top: .5rem !important
    }

    .pt-md-3 {
        padding-top: 1rem !important
    }

    .pt-md-4 {
        padding-top: 1.5rem !important
    }

    .pt-md-5 {
        padding-top: 3rem !important
    }

    .pe-md-0 {
        padding-right: 0 !important
    }

    .pe-md-1 {
        padding-right: .25rem !important
    }

    .pe-md-2 {
        padding-right: .5rem !important
    }

    .pe-md-3 {
        padding-right: 1rem !important
    }

    .pe-md-4 {
        padding-right: 1.5rem !important
    }

    .pe-md-5 {
        padding-right: 3rem !important
    }

    .pb-md-0 {
        padding-bottom: 0 !important
    }

    .pb-md-1 {
        padding-bottom: .25rem !important
    }

    .pb-md-2 {
        padding-bottom: .5rem !important
    }

    .pb-md-3 {
        padding-bottom: 1rem !important
    }

    .pb-md-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-md-5 {
        padding-bottom: 3rem !important
    }

    .ps-md-0 {
        padding-left: 0 !important
    }

    .ps-md-1 {
        padding-left: .25rem !important
    }

    .ps-md-2 {
        padding-left: .5rem !important
    }

    .ps-md-3 {
        padding-left: 1rem !important
    }

    .ps-md-4 {
        padding-left: 1.5rem !important
    }

    .ps-md-5 {
        padding-left: 3rem !important
    }

    .text-md-start {
        text-align: left !important
    }

    .text-md-end {
        text-align: right !important
    }

    .text-md-center {
        text-align: center !important
    }
}

@media(min-width:992px) {
    .float-lg-start {
        float: left !important
    }

    .float-lg-end {
        float: right !important
    }

    .float-lg-none {
        float: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-grid {
        display: grid !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-row {
        display: table-row !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: -webkit-flex !important;
        display: flex !important
    }

    .d-lg-inline-flex {
        display: -webkit-inline-flex !important;
        display: inline-flex !important
    }

    .d-lg-none {
        display: none !important
    }

    .flex-lg-fill {
        -webkit-flex: 1 1 auto !important;
        flex: 1 1 auto !important
    }

    .flex-lg-row {
        -webkit-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-lg-column {
        -webkit-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-lg-row-reverse {
        -webkit-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-lg-column-reverse {
        -webkit-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-lg-grow-0 {
        -webkit-flex-grow: 0 !important;
        flex-grow: 0 !important
    }

    .flex-lg-grow-1 {
        -webkit-flex-grow: 1 !important;
        flex-grow: 1 !important
    }

    .flex-lg-shrink-0 {
        -webkit-flex-shrink: 0 !important;
        flex-shrink: 0 !important
    }

    .flex-lg-shrink-1 {
        -webkit-flex-shrink: 1 !important;
        flex-shrink: 1 !important
    }

    .flex-lg-wrap {
        -webkit-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-lg-nowrap {
        -webkit-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-lg-wrap-reverse {
        -webkit-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .gap-lg-0 {
        grid-gap: 0 !important;
        gap: 0 !important
    }

    .gap-lg-1 {
        grid-gap: .25rem !important;
        gap: .25rem !important
    }

    .gap-lg-2 {
        grid-gap: .5rem !important;
        gap: .5rem !important
    }

    .gap-lg-3 {
        grid-gap: 1rem !important;
        gap: 1rem !important
    }

    .gap-lg-4 {
        grid-gap: 1.5rem !important;
        gap: 1.5rem !important
    }

    .gap-lg-5 {
        grid-gap: 3rem !important;
        gap: 3rem !important
    }

    .justify-content-lg-start {
        -webkit-justify-content: flex-start !important;
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        -webkit-justify-content: flex-end !important;
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        -webkit-justify-content: center !important;
        justify-content: center !important
    }

    .justify-content-lg-between {
        -webkit-justify-content: space-between !important;
        justify-content: space-between !important
    }

    .justify-content-lg-around {
        -webkit-justify-content: space-around !important;
        justify-content: space-around !important
    }

    .justify-content-lg-evenly {
        -webkit-justify-content: space-evenly !important;
        justify-content: space-evenly !important
    }

    .align-items-lg-start {
        -webkit-align-items: flex-start !important;
        align-items: flex-start !important
    }

    .align-items-lg-end {
        -webkit-align-items: flex-end !important;
        align-items: flex-end !important
    }

    .align-items-lg-center {
        -webkit-align-items: center !important;
        align-items: center !important
    }

    .align-items-lg-baseline {
        -webkit-align-items: baseline !important;
        align-items: baseline !important
    }

    .align-items-lg-stretch {
        -webkit-align-items: stretch !important;
        align-items: stretch !important
    }

    .align-content-lg-start {
        -webkit-align-content: flex-start !important;
        align-content: flex-start !important
    }

    .align-content-lg-end {
        -webkit-align-content: flex-end !important;
        align-content: flex-end !important
    }

    .align-content-lg-center {
        -webkit-align-content: center !important;
        align-content: center !important
    }

    .align-content-lg-between {
        -webkit-align-content: space-between !important;
        align-content: space-between !important
    }

    .align-content-lg-around {
        -webkit-align-content: space-around !important;
        align-content: space-around !important
    }

    .align-content-lg-stretch {
        -webkit-align-content: stretch !important;
        align-content: stretch !important
    }

    .align-self-lg-auto {
        -webkit-align-self: auto !important;
        align-self: auto !important
    }

    .align-self-lg-start {
        -webkit-align-self: flex-start !important;
        align-self: flex-start !important
    }

    .align-self-lg-end {
        -webkit-align-self: flex-end !important;
        align-self: flex-end !important
    }

    .align-self-lg-center {
        -webkit-align-self: center !important;
        align-self: center !important
    }

    .align-self-lg-baseline {
        -webkit-align-self: baseline !important;
        align-self: baseline !important
    }

    .align-self-lg-stretch {
        -webkit-align-self: stretch !important;
        align-self: stretch !important
    }

    .order-lg-first {
        -webkit-order: -1 !important;
        order: -1 !important
    }

    .order-lg-0 {
        -webkit-order: 0 !important;
        order: 0 !important
    }

    .order-lg-1 {
        -webkit-order: 1 !important;
        order: 1 !important
    }

    .order-lg-2 {
        -webkit-order: 2 !important;
        order: 2 !important
    }

    .order-lg-3 {
        -webkit-order: 3 !important;
        order: 3 !important
    }

    .order-lg-4 {
        -webkit-order: 4 !important;
        order: 4 !important
    }

    .order-lg-5 {
        -webkit-order: 5 !important;
        order: 5 !important
    }

    .order-lg-last {
        -webkit-order: 6 !important;
        order: 6 !important
    }

    .m-lg-0 {
        margin: 0 !important
    }

    .m-lg-1 {
        margin: .25rem !important
    }

    .m-lg-2 {
        margin: .5rem !important
    }

    .m-lg-3 {
        margin: 1rem !important
    }

    .m-lg-4 {
        margin: 1.5rem !important
    }

    .m-lg-5 {
        margin: 3rem !important
    }

    .m-lg-auto {
        margin: auto !important
    }

    .mx-lg-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-lg-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-lg-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-lg-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-lg-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-lg-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-lg-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-lg-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-lg-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-lg-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-lg-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-lg-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-lg-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-lg-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-lg-0 {
        margin-top: 0 !important
    }

    .mt-lg-1 {
        margin-top: .25rem !important
    }

    .mt-lg-2 {
        margin-top: .5rem !important
    }

    .mt-lg-3 {
        margin-top: 1rem !important
    }

    .mt-lg-4 {
        margin-top: 1.5rem !important
    }

    .mt-lg-5 {
        margin-top: 3rem !important
    }

    .mt-lg-auto {
        margin-top: auto !important
    }

    .me-lg-0 {
        margin-right: 0 !important
    }

    .me-lg-1 {
        margin-right: .25rem !important
    }

    .me-lg-2 {
        margin-right: .5rem !important
    }

    .me-lg-3 {
        margin-right: 1rem !important
    }

    .me-lg-4 {
        margin-right: 1.5rem !important
    }

    .me-lg-5 {
        margin-right: 3rem !important
    }

    .me-lg-auto {
        margin-right: auto !important
    }

    .mb-lg-0 {
        margin-bottom: 0 !important
    }

    .mb-lg-1 {
        margin-bottom: .25rem !important
    }

    .mb-lg-2 {
        margin-bottom: .5rem !important
    }

    .mb-lg-3 {
        margin-bottom: 1rem !important
    }

    .mb-lg-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-lg-5 {
        margin-bottom: 3rem !important
    }

    .mb-lg-auto {
        margin-bottom: auto !important
    }

    .ms-lg-0 {
        margin-left: 0 !important
    }

    .ms-lg-1 {
        margin-left: .25rem !important
    }

    .ms-lg-2 {
        margin-left: .5rem !important
    }

    .ms-lg-3 {
        margin-left: 1rem !important
    }

    .ms-lg-4 {
        margin-left: 1.5rem !important
    }

    .ms-lg-5 {
        margin-left: 3rem !important
    }

    .ms-lg-auto {
        margin-left: auto !important
    }

    .p-lg-0 {
        padding: 0 !important
    }

    .p-lg-1 {
        padding: .25rem !important
    }

    .p-lg-2 {
        padding: .5rem !important
    }

    .p-lg-3 {
        padding: 1rem !important
    }

    .p-lg-4 {
        padding: 1.5rem !important
    }

    .p-lg-5 {
        padding: 3rem !important
    }

    .px-lg-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-lg-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-lg-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-lg-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-lg-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-lg-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-lg-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-lg-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-lg-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-lg-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-lg-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-lg-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-lg-0 {
        padding-top: 0 !important
    }

    .pt-lg-1 {
        padding-top: .25rem !important
    }

    .pt-lg-2 {
        padding-top: .5rem !important
    }

    .pt-lg-3 {
        padding-top: 1rem !important
    }

    .pt-lg-4 {
        padding-top: 1.5rem !important
    }

    .pt-lg-5 {
        padding-top: 3rem !important
    }

    .pe-lg-0 {
        padding-right: 0 !important
    }

    .pe-lg-1 {
        padding-right: .25rem !important
    }

    .pe-lg-2 {
        padding-right: .5rem !important
    }

    .pe-lg-3 {
        padding-right: 1rem !important
    }

    .pe-lg-4 {
        padding-right: 1.5rem !important
    }

    .pe-lg-5 {
        padding-right: 3rem !important
    }

    .pb-lg-0 {
        padding-bottom: 0 !important
    }

    .pb-lg-1 {
        padding-bottom: .25rem !important
    }

    .pb-lg-2 {
        padding-bottom: .5rem !important
    }

    .pb-lg-3 {
        padding-bottom: 1rem !important
    }

    .pb-lg-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-lg-5 {
        padding-bottom: 3rem !important
    }

    .ps-lg-0 {
        padding-left: 0 !important
    }

    .ps-lg-1 {
        padding-left: .25rem !important
    }

    .ps-lg-2 {
        padding-left: .5rem !important
    }

    .ps-lg-3 {
        padding-left: 1rem !important
    }

    .ps-lg-4 {
        padding-left: 1.5rem !important
    }

    .ps-lg-5 {
        padding-left: 3rem !important
    }

    .text-lg-start {
        text-align: left !important
    }

    .text-lg-end {
        text-align: right !important
    }

    .text-lg-center {
        text-align: center !important
    }
}

@media(min-width:1200px) {
    .float-xl-start {
        float: left !important
    }

    .float-xl-end {
        float: right !important
    }

    .float-xl-none {
        float: none !important
    }

    .d-xl-inline {
        display: inline !important
    }

    .d-xl-inline-block {
        display: inline-block !important
    }

    .d-xl-block {
        display: block !important
    }

    .d-xl-grid {
        display: grid !important
    }

    .d-xl-table {
        display: table !important
    }

    .d-xl-table-row {
        display: table-row !important
    }

    .d-xl-table-cell {
        display: table-cell !important
    }

    .d-xl-flex {
        display: -webkit-flex !important;
        display: flex !important
    }

    .d-xl-inline-flex {
        display: -webkit-inline-flex !important;
        display: inline-flex !important
    }

    .d-xl-none {
        display: none !important
    }

    .flex-xl-fill {
        -webkit-flex: 1 1 auto !important;
        flex: 1 1 auto !important
    }

    .flex-xl-row {
        -webkit-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-xl-column {
        -webkit-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        -webkit-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        -webkit-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-xl-grow-0 {
        -webkit-flex-grow: 0 !important;
        flex-grow: 0 !important
    }

    .flex-xl-grow-1 {
        -webkit-flex-grow: 1 !important;
        flex-grow: 1 !important
    }

    .flex-xl-shrink-0 {
        -webkit-flex-shrink: 0 !important;
        flex-shrink: 0 !important
    }

    .flex-xl-shrink-1 {
        -webkit-flex-shrink: 1 !important;
        flex-shrink: 1 !important
    }

    .flex-xl-wrap {
        -webkit-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        -webkit-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        -webkit-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .gap-xl-0 {
        grid-gap: 0 !important;
        gap: 0 !important
    }

    .gap-xl-1 {
        grid-gap: .25rem !important;
        gap: .25rem !important
    }

    .gap-xl-2 {
        grid-gap: .5rem !important;
        gap: .5rem !important
    }

    .gap-xl-3 {
        grid-gap: 1rem !important;
        gap: 1rem !important
    }

    .gap-xl-4 {
        grid-gap: 1.5rem !important;
        gap: 1.5rem !important
    }

    .gap-xl-5 {
        grid-gap: 3rem !important;
        gap: 3rem !important
    }

    .justify-content-xl-start {
        -webkit-justify-content: flex-start !important;
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        -webkit-justify-content: flex-end !important;
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        -webkit-justify-content: center !important;
        justify-content: center !important
    }

    .justify-content-xl-between {
        -webkit-justify-content: space-between !important;
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        -webkit-justify-content: space-around !important;
        justify-content: space-around !important
    }

    .justify-content-xl-evenly {
        -webkit-justify-content: space-evenly !important;
        justify-content: space-evenly !important
    }

    .align-items-xl-start {
        -webkit-align-items: flex-start !important;
        align-items: flex-start !important
    }

    .align-items-xl-end {
        -webkit-align-items: flex-end !important;
        align-items: flex-end !important
    }

    .align-items-xl-center {
        -webkit-align-items: center !important;
        align-items: center !important
    }

    .align-items-xl-baseline {
        -webkit-align-items: baseline !important;
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        -webkit-align-items: stretch !important;
        align-items: stretch !important
    }

    .align-content-xl-start {
        -webkit-align-content: flex-start !important;
        align-content: flex-start !important
    }

    .align-content-xl-end {
        -webkit-align-content: flex-end !important;
        align-content: flex-end !important
    }

    .align-content-xl-center {
        -webkit-align-content: center !important;
        align-content: center !important
    }

    .align-content-xl-between {
        -webkit-align-content: space-between !important;
        align-content: space-between !important
    }

    .align-content-xl-around {
        -webkit-align-content: space-around !important;
        align-content: space-around !important
    }

    .align-content-xl-stretch {
        -webkit-align-content: stretch !important;
        align-content: stretch !important
    }

    .align-self-xl-auto {
        -webkit-align-self: auto !important;
        align-self: auto !important
    }

    .align-self-xl-start {
        -webkit-align-self: flex-start !important;
        align-self: flex-start !important
    }

    .align-self-xl-end {
        -webkit-align-self: flex-end !important;
        align-self: flex-end !important
    }

    .align-self-xl-center {
        -webkit-align-self: center !important;
        align-self: center !important
    }

    .align-self-xl-baseline {
        -webkit-align-self: baseline !important;
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        -webkit-align-self: stretch !important;
        align-self: stretch !important
    }

    .order-xl-first {
        -webkit-order: -1 !important;
        order: -1 !important
    }

    .order-xl-0 {
        -webkit-order: 0 !important;
        order: 0 !important
    }

    .order-xl-1 {
        -webkit-order: 1 !important;
        order: 1 !important
    }

    .order-xl-2 {
        -webkit-order: 2 !important;
        order: 2 !important
    }

    .order-xl-3 {
        -webkit-order: 3 !important;
        order: 3 !important
    }

    .order-xl-4 {
        -webkit-order: 4 !important;
        order: 4 !important
    }

    .order-xl-5 {
        -webkit-order: 5 !important;
        order: 5 !important
    }

    .order-xl-last {
        -webkit-order: 6 !important;
        order: 6 !important
    }

    .m-xl-0 {
        margin: 0 !important
    }

    .m-xl-1 {
        margin: .25rem !important
    }

    .m-xl-2 {
        margin: .5rem !important
    }

    .m-xl-3 {
        margin: 1rem !important
    }

    .m-xl-4 {
        margin: 1.5rem !important
    }

    .m-xl-5 {
        margin: 3rem !important
    }

    .m-xl-auto {
        margin: auto !important
    }

    .mx-xl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-xl-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-xl-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-xl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-xl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-xl-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-xl-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-xl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-xl-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-xl-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-xl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-xl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-xl-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-xl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-xl-0 {
        margin-top: 0 !important
    }

    .mt-xl-1 {
        margin-top: .25rem !important
    }

    .mt-xl-2 {
        margin-top: .5rem !important
    }

    .mt-xl-3 {
        margin-top: 1rem !important
    }

    .mt-xl-4 {
        margin-top: 1.5rem !important
    }

    .mt-xl-5 {
        margin-top: 3rem !important
    }

    .mt-xl-auto {
        margin-top: auto !important
    }

    .me-xl-0 {
        margin-right: 0 !important
    }

    .me-xl-1 {
        margin-right: .25rem !important
    }

    .me-xl-2 {
        margin-right: .5rem !important
    }

    .me-xl-3 {
        margin-right: 1rem !important
    }

    .me-xl-4 {
        margin-right: 1.5rem !important
    }

    .me-xl-5 {
        margin-right: 3rem !important
    }

    .me-xl-auto {
        margin-right: auto !important
    }

    .mb-xl-0 {
        margin-bottom: 0 !important
    }

    .mb-xl-1 {
        margin-bottom: .25rem !important
    }

    .mb-xl-2 {
        margin-bottom: .5rem !important
    }

    .mb-xl-3 {
        margin-bottom: 1rem !important
    }

    .mb-xl-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-xl-5 {
        margin-bottom: 3rem !important
    }

    .mb-xl-auto {
        margin-bottom: auto !important
    }

    .ms-xl-0 {
        margin-left: 0 !important
    }

    .ms-xl-1 {
        margin-left: .25rem !important
    }

    .ms-xl-2 {
        margin-left: .5rem !important
    }

    .ms-xl-3 {
        margin-left: 1rem !important
    }

    .ms-xl-4 {
        margin-left: 1.5rem !important
    }

    .ms-xl-5 {
        margin-left: 3rem !important
    }

    .ms-xl-auto {
        margin-left: auto !important
    }

    .p-xl-0 {
        padding: 0 !important
    }

    .p-xl-1 {
        padding: .25rem !important
    }

    .p-xl-2 {
        padding: .5rem !important
    }

    .p-xl-3 {
        padding: 1rem !important
    }

    .p-xl-4 {
        padding: 1.5rem !important
    }

    .p-xl-5 {
        padding: 3rem !important
    }

    .px-xl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-xl-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-xl-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-xl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-xl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-xl-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-xl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-xl-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-xl-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-xl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-xl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-xl-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-xl-0 {
        padding-top: 0 !important
    }

    .pt-xl-1 {
        padding-top: .25rem !important
    }

    .pt-xl-2 {
        padding-top: .5rem !important
    }

    .pt-xl-3 {
        padding-top: 1rem !important
    }

    .pt-xl-4 {
        padding-top: 1.5rem !important
    }

    .pt-xl-5 {
        padding-top: 3rem !important
    }

    .pe-xl-0 {
        padding-right: 0 !important
    }

    .pe-xl-1 {
        padding-right: .25rem !important
    }

    .pe-xl-2 {
        padding-right: .5rem !important
    }

    .pe-xl-3 {
        padding-right: 1rem !important
    }

    .pe-xl-4 {
        padding-right: 1.5rem !important
    }

    .pe-xl-5 {
        padding-right: 3rem !important
    }

    .pb-xl-0 {
        padding-bottom: 0 !important
    }

    .pb-xl-1 {
        padding-bottom: .25rem !important
    }

    .pb-xl-2 {
        padding-bottom: .5rem !important
    }

    .pb-xl-3 {
        padding-bottom: 1rem !important
    }

    .pb-xl-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-xl-5 {
        padding-bottom: 3rem !important
    }

    .ps-xl-0 {
        padding-left: 0 !important
    }

    .ps-xl-1 {
        padding-left: .25rem !important
    }

    .ps-xl-2 {
        padding-left: .5rem !important
    }

    .ps-xl-3 {
        padding-left: 1rem !important
    }

    .ps-xl-4 {
        padding-left: 1.5rem !important
    }

    .ps-xl-5 {
        padding-left: 3rem !important
    }

    .text-xl-start {
        text-align: left !important
    }

    .text-xl-end {
        text-align: right !important
    }

    .text-xl-center {
        text-align: center !important
    }
}

@media(min-width:1400px) {
    .float-xxl-start {
        float: left !important
    }

    .float-xxl-end {
        float: right !important
    }

    .float-xxl-none {
        float: none !important
    }

    .d-xxl-inline {
        display: inline !important
    }

    .d-xxl-inline-block {
        display: inline-block !important
    }

    .d-xxl-block {
        display: block !important
    }

    .d-xxl-grid {
        display: grid !important
    }

    .d-xxl-table {
        display: table !important
    }

    .d-xxl-table-row {
        display: table-row !important
    }

    .d-xxl-table-cell {
        display: table-cell !important
    }

    .d-xxl-flex {
        display: -webkit-flex !important;
        display: flex !important
    }

    .d-xxl-inline-flex {
        display: -webkit-inline-flex !important;
        display: inline-flex !important
    }

    .d-xxl-none {
        display: none !important
    }

    .flex-xxl-fill {
        -webkit-flex: 1 1 auto !important;
        flex: 1 1 auto !important
    }

    .flex-xxl-row {
        -webkit-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-xxl-column {
        -webkit-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-xxl-row-reverse {
        -webkit-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-xxl-column-reverse {
        -webkit-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-xxl-grow-0 {
        -webkit-flex-grow: 0 !important;
        flex-grow: 0 !important
    }

    .flex-xxl-grow-1 {
        -webkit-flex-grow: 1 !important;
        flex-grow: 1 !important
    }

    .flex-xxl-shrink-0 {
        -webkit-flex-shrink: 0 !important;
        flex-shrink: 0 !important
    }

    .flex-xxl-shrink-1 {
        -webkit-flex-shrink: 1 !important;
        flex-shrink: 1 !important
    }

    .flex-xxl-wrap {
        -webkit-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-xxl-nowrap {
        -webkit-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-xxl-wrap-reverse {
        -webkit-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .gap-xxl-0 {
        grid-gap: 0 !important;
        gap: 0 !important
    }

    .gap-xxl-1 {
        grid-gap: .25rem !important;
        gap: .25rem !important
    }

    .gap-xxl-2 {
        grid-gap: .5rem !important;
        gap: .5rem !important
    }

    .gap-xxl-3 {
        grid-gap: 1rem !important;
        gap: 1rem !important
    }

    .gap-xxl-4 {
        grid-gap: 1.5rem !important;
        gap: 1.5rem !important
    }

    .gap-xxl-5 {
        grid-gap: 3rem !important;
        gap: 3rem !important
    }

    .justify-content-xxl-start {
        -webkit-justify-content: flex-start !important;
        justify-content: flex-start !important
    }

    .justify-content-xxl-end {
        -webkit-justify-content: flex-end !important;
        justify-content: flex-end !important
    }

    .justify-content-xxl-center {
        -webkit-justify-content: center !important;
        justify-content: center !important
    }

    .justify-content-xxl-between {
        -webkit-justify-content: space-between !important;
        justify-content: space-between !important
    }

    .justify-content-xxl-around {
        -webkit-justify-content: space-around !important;
        justify-content: space-around !important
    }

    .justify-content-xxl-evenly {
        -webkit-justify-content: space-evenly !important;
        justify-content: space-evenly !important
    }

    .align-items-xxl-start {
        -webkit-align-items: flex-start !important;
        align-items: flex-start !important
    }

    .align-items-xxl-end {
        -webkit-align-items: flex-end !important;
        align-items: flex-end !important
    }

    .align-items-xxl-center {
        -webkit-align-items: center !important;
        align-items: center !important
    }

    .align-items-xxl-baseline {
        -webkit-align-items: baseline !important;
        align-items: baseline !important
    }

    .align-items-xxl-stretch {
        -webkit-align-items: stretch !important;
        align-items: stretch !important
    }

    .align-content-xxl-start {
        -webkit-align-content: flex-start !important;
        align-content: flex-start !important
    }

    .align-content-xxl-end {
        -webkit-align-content: flex-end !important;
        align-content: flex-end !important
    }

    .align-content-xxl-center {
        -webkit-align-content: center !important;
        align-content: center !important
    }

    .align-content-xxl-between {
        -webkit-align-content: space-between !important;
        align-content: space-between !important
    }

    .align-content-xxl-around {
        -webkit-align-content: space-around !important;
        align-content: space-around !important
    }

    .align-content-xxl-stretch {
        -webkit-align-content: stretch !important;
        align-content: stretch !important
    }

    .align-self-xxl-auto {
        -webkit-align-self: auto !important;
        align-self: auto !important
    }

    .align-self-xxl-start {
        -webkit-align-self: flex-start !important;
        align-self: flex-start !important
    }

    .align-self-xxl-end {
        -webkit-align-self: flex-end !important;
        align-self: flex-end !important
    }

    .align-self-xxl-center {
        -webkit-align-self: center !important;
        align-self: center !important
    }

    .align-self-xxl-baseline {
        -webkit-align-self: baseline !important;
        align-self: baseline !important
    }

    .align-self-xxl-stretch {
        -webkit-align-self: stretch !important;
        align-self: stretch !important
    }

    .order-xxl-first {
        -webkit-order: -1 !important;
        order: -1 !important
    }

    .order-xxl-0 {
        -webkit-order: 0 !important;
        order: 0 !important
    }

    .order-xxl-1 {
        -webkit-order: 1 !important;
        order: 1 !important
    }

    .order-xxl-2 {
        -webkit-order: 2 !important;
        order: 2 !important
    }

    .order-xxl-3 {
        -webkit-order: 3 !important;
        order: 3 !important
    }

    .order-xxl-4 {
        -webkit-order: 4 !important;
        order: 4 !important
    }

    .order-xxl-5 {
        -webkit-order: 5 !important;
        order: 5 !important
    }

    .order-xxl-last {
        -webkit-order: 6 !important;
        order: 6 !important
    }

    .m-xxl-0 {
        margin: 0 !important
    }

    .m-xxl-1 {
        margin: .25rem !important
    }

    .m-xxl-2 {
        margin: .5rem !important
    }

    .m-xxl-3 {
        margin: 1rem !important
    }

    .m-xxl-4 {
        margin: 1.5rem !important
    }

    .m-xxl-5 {
        margin: 3rem !important
    }

    .m-xxl-auto {
        margin: auto !important
    }

    .mx-xxl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-xxl-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-xxl-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-xxl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-xxl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-xxl-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-xxl-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-xxl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-xxl-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-xxl-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-xxl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-xxl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-xxl-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-xxl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-xxl-0 {
        margin-top: 0 !important
    }

    .mt-xxl-1 {
        margin-top: .25rem !important
    }

    .mt-xxl-2 {
        margin-top: .5rem !important
    }

    .mt-xxl-3 {
        margin-top: 1rem !important
    }

    .mt-xxl-4 {
        margin-top: 1.5rem !important
    }

    .mt-xxl-5 {
        margin-top: 3rem !important
    }

    .mt-xxl-auto {
        margin-top: auto !important
    }

    .me-xxl-0 {
        margin-right: 0 !important
    }

    .me-xxl-1 {
        margin-right: .25rem !important
    }

    .me-xxl-2 {
        margin-right: .5rem !important
    }

    .me-xxl-3 {
        margin-right: 1rem !important
    }

    .me-xxl-4 {
        margin-right: 1.5rem !important
    }

    .me-xxl-5 {
        margin-right: 3rem !important
    }

    .me-xxl-auto {
        margin-right: auto !important
    }

    .mb-xxl-0 {
        margin-bottom: 0 !important
    }

    .mb-xxl-1 {
        margin-bottom: .25rem !important
    }

    .mb-xxl-2 {
        margin-bottom: .5rem !important
    }

    .mb-xxl-3 {
        margin-bottom: 1rem !important
    }

    .mb-xxl-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-xxl-5 {
        margin-bottom: 3rem !important
    }

    .mb-xxl-auto {
        margin-bottom: auto !important
    }

    .ms-xxl-0 {
        margin-left: 0 !important
    }

    .ms-xxl-1 {
        margin-left: .25rem !important
    }

    .ms-xxl-2 {
        margin-left: .5rem !important
    }

    .ms-xxl-3 {
        margin-left: 1rem !important
    }

    .ms-xxl-4 {
        margin-left: 1.5rem !important
    }

    .ms-xxl-5 {
        margin-left: 3rem !important
    }

    .ms-xxl-auto {
        margin-left: auto !important
    }

    .p-xxl-0 {
        padding: 0 !important
    }

    .p-xxl-1 {
        padding: .25rem !important
    }

    .p-xxl-2 {
        padding: .5rem !important
    }

    .p-xxl-3 {
        padding: 1rem !important
    }

    .p-xxl-4 {
        padding: 1.5rem !important
    }

    .p-xxl-5 {
        padding: 3rem !important
    }

    .px-xxl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-xxl-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-xxl-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-xxl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-xxl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-xxl-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-xxl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-xxl-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-xxl-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-xxl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-xxl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-xxl-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-xxl-0 {
        padding-top: 0 !important
    }

    .pt-xxl-1 {
        padding-top: .25rem !important
    }

    .pt-xxl-2 {
        padding-top: .5rem !important
    }

    .pt-xxl-3 {
        padding-top: 1rem !important
    }

    .pt-xxl-4 {
        padding-top: 1.5rem !important
    }

    .pt-xxl-5 {
        padding-top: 3rem !important
    }

    .pe-xxl-0 {
        padding-right: 0 !important
    }

    .pe-xxl-1 {
        padding-right: .25rem !important
    }

    .pe-xxl-2 {
        padding-right: .5rem !important
    }

    .pe-xxl-3 {
        padding-right: 1rem !important
    }

    .pe-xxl-4 {
        padding-right: 1.5rem !important
    }

    .pe-xxl-5 {
        padding-right: 3rem !important
    }

    .pb-xxl-0 {
        padding-bottom: 0 !important
    }

    .pb-xxl-1 {
        padding-bottom: .25rem !important
    }

    .pb-xxl-2 {
        padding-bottom: .5rem !important
    }

    .pb-xxl-3 {
        padding-bottom: 1rem !important
    }

    .pb-xxl-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-xxl-5 {
        padding-bottom: 3rem !important
    }

    .ps-xxl-0 {
        padding-left: 0 !important
    }

    .ps-xxl-1 {
        padding-left: .25rem !important
    }

    .ps-xxl-2 {
        padding-left: .5rem !important
    }

    .ps-xxl-3 {
        padding-left: 1rem !important
    }

    .ps-xxl-4 {
        padding-left: 1.5rem !important
    }

    .ps-xxl-5 {
        padding-left: 3rem !important
    }

    .text-xxl-start {
        text-align: left !important
    }

    .text-xxl-end {
        text-align: right !important
    }

    .text-xxl-center {
        text-align: center !important
    }
}

@media(min-width:1200px) {
    .fs-1 {
        font-size: 2.5rem !important
    }

    .fs-2 {
        font-size: 2rem !important
    }

    .fs-3 {
        font-size: 1.75rem !important
    }

    .fs-4 {
        font-size: 1.5rem !important
    }
}

@media print {
    .d-print-inline {
        display: inline !important
    }

    .d-print-inline-block {
        display: inline-block !important
    }

    .d-print-block {
        display: block !important
    }

    .d-print-grid {
        display: grid !important
    }

    .d-print-table {
        display: table !important
    }

    .d-print-table-row {
        display: table-row !important
    }

    .d-print-table-cell {
        display: table-cell !important
    }

    .d-print-flex {
        display: -webkit-flex !important;
        display: flex !important
    }

    .d-print-inline-flex {
        display: -webkit-inline-flex !important;
        display: inline-flex !important
    }

    .d-print-none {
        display: none !important
    }
}

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

body {
    font-family: Hind, sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #6d6e75;
    line-height: 26px
}

@media only screen and (min-width:1400px) {
    .container {
        max-width: 1200px
    }
}

a {
    text-decoration: none
}

.w-img img {
    width: 100%
}

.m-img img {
    max-width: 100%
}

.btn, .h1, .h2, .h3, .h4, .h5, .h6, .transition-3, :after, :before, a, button, h1, h2, h3, h4, h5, h6, i, img, input, li, p, select, span, svg path, textarea {
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.button:focus, a:focus {
    text-decoration: none;
    outline: none
}

a:focus, a:hover {
    color: inherit;
    text-decoration: none
}

a, button {
    color: inherit;
    outline: none;
    border: none
}

button:hover {
    cursor: pointer
}

button:focus {
    outline: 0;
    border: 0
}

.uppercase {
    text-transform: uppercase
}

.capitalize {
    text-transform: capitalize
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: Hind, sans-serif;
    color: #0e1133;
    margin-top: 0;
    font-weight: 700;
    line-height: 1.2;
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.h1, h1 {
    font-size: 40px
}

.h2, h2 {
    font-size: 36px
}

.h3, h3 {
    font-size: 24px
}

.h4, h4 {
    font-size: 20px
}

.h5, h5 {
    font-size: 16px
}

.h6, h6 {
    font-size: 14px
}

ul {
    margin: 0;
    padding: 0
}

li {
    list-style: none
}

p {
    font-family: Hind, sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #6d6e75;
    margin-bottom: 15px;
    line-height: 26px
}

::-moz-selection {
    background: var(--bs-primary);
    color: #fff;
    text-shadow: none
}

::selection {
    background: var(--bs-primary);
    color: #fff;
    text-shadow: none
}

::-moz-placeholder {
    color: #0e1133;
    font-size: 14px;
    opacity: 1
}

::-webkit-input-placeholder {
    color: #0e1133;
    font-size: 14px;
    opacity: 1
}

:-ms-input-placeholder {
    color: #0e1133;
    font-size: 14px;
    opacity: 1
}

::placeholder {
    color: #0e1133;
    font-size: 14px;
    opacity: 1
}

.fix {
    overflow: hidden
}

.clear {
    clear: both
}

.f-left {
    float: left
}

.f-right {
    float: right
}

.z-index-1 {
    z-index: 1
}

.overflow-y-visible {
    overflow-x: hidden;
    overflow-y: visible
}

.p-relative {
    position: relative
}

.p-absolute {
    position: absolute
}

.grey-bg {
    background: #f3f4f8
}

.grey-bg-2 {
    background: #edeef3
}

.grey-bg-5 {
    background: #f5f6fa
}

.blue-bg {
    background: var(--bs-primary)
}

.blue-bg-3 {
    background: #b8dde3
}

.blue-bg-4 {
    background: #2d69f0
}

.pink-bg {
    background: #dd246e
}

.purple-bg {
    background: #8007e6
}

.green-bg {
    background: #0cae74
}

.white-bg {
    background: #fff
}

.black-bg, .footer-bg {
    background: #0e1133
}

.text-white .h1, .text-white .h2, .text-white .h3, .text-white .h4, .text-white .h5, .text-white .h6, .text-white a, .text-white h1, .text-white h2, .text-white h3, .text-white h4, .text-white h5, .text-white h6, .text-white li, .text-white p, .text-white span {
    color: #fff !important
}

.white-color {
    color: #fff
}

.theme-color {
    color: var(--bs-primary) !important
}

.black-color {
    color: #0e1133
}

.pl-245 {
    padding-left: 245px
}

.pr-245 {
    padding-right: 245px
}

.mb-22 {
    margin-bottom: 22px
}

.ml-270 {
    margin-left: 270px
}

.mb--120 {
    margin-bottom: -120px
}

.mb--100 {
    margin-bottom: -100px
}

.mt--210 {
    margin-top: -210px
}

.mt--80 {
    margin-top: -80px
}

.mb-130 {
    margin-bottom: -130px
}

.section-padding {
    padding-left: 50px;
    padding-right: 50px
}

@media only screen and (min-width:1200px)and (max-width:1399px) {
    .section-padding {
        padding-left: 30px;
        padding-right: 30px
    }
}

@media(max-width:575px) {
    .section-padding {
        padding-left: 0;
        padding-right: 0
    }
}

.e-btn2 {
    display: inline-block;
    height: 50px;
    line-height: 52px;
    text-align: center;
    padding: 0 25px;
    color: #fff;
    background: var(--bs-gray-500);
    border-radius: 4px;
    font-weight: 500;
}

.e-btn {
    display: inline-block;
    height: 50px;
    line-height: 52px;
    text-align: center;
    padding: 0 25px;
    color: #fff;
    background: var(--bs-primary);
    border-radius: 4px;
    font-weight: 500;
    text-transform: lowercase
}

.e-btn:first-letter {
    text-transform: uppercase
}

.e-btn:hover {
    color: #fff;
    -webkit-box-shadow: 0 10px 24px 0 rgba(4, 23, 118, .3);
    box-shadow: 0 10px 24px 0 rgba(4, 23, 118, .3)
}

.e-btn-2 {
    height: 34px;
    line-height: 36px;
    padding: 0 20px;
    font-size: 14px;
    text-transform: capitalize
}

.e-btn-3 {
    padding: 0 28px
}

.e-btn-4 {
    height: 46px;
    line-height: 44px;
    border: 2px solid var(--bs-primary);
    background: var(--bs-primary);
    color: #fff
}

.e-btn-5 {
    padding-left: 22px;
    padding-right: 22px
}

.e-btn-6 {
    padding-left: 60px;
    padding-right: 60px
}

.e-btn-7 {
    height: 44px;
    line-height: 46px
}

.e-btn-border {
    height: 46px;
    line-height: 44px;
    border: 2px solid #d7d9e3;
    color: #0e1133;
    background: transparent
}

.e-btn-border:hover {
    background: var(--bs-primary);
    border-color: var(--bs-primary);
    color: #fff
}

.e-btn-border-2 {
    height: 46px;
    line-height: 44px;
    border: 2px solid rgba(255, 255, 255, .3);
    background: transparent;
    padding: 0 20px
}

.e-btn-border-2:hover {
    background: #fff;
    border-color: #fff;
    color: #0e1133
}

.e-btn-white {
    height: 54px;
    line-height: 58px;
    background: #fff;
    padding: 0 45px
}

.e-btn-white, .e-btn-white:hover {
    color: #0e1133
}

.link-btn {
    position: relative;
    font-size: 16px;
    color: #6d6e75;
    font-weight: 500;
    padding-right: 21px;
    display: inline-block
}

.link-btn i {
    font-size: 14px;
    position: absolute;
    top: 12px;
    -webkit-transform: translateY(-38%);
    transform: translateY(-38%);
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease
}

.link-btn i:first-child {
    right: 10%;
    visibility: hidden;
    opacity: 0
}

.link-btn i:last-child {
    right: 0
}

.link-btn:hover {
    color: var(--bs-primary)
}

.link-btn:hover i:first-child {
    right: 0;
    visibility: visible;
    opacity: 1
}

.link-btn:hover i:last-child {
    right: -10%;
    visibility: hidden;
    opacity: 0
}

.link-btn.link-prev {
    padding-right: 0;
    padding-left: 21px
}

.link-btn.link-prev i:first-child {
    left: 10%;
    right: auto
}

.link-btn.link-prev:hover i:first-child, .link-btn.link-prev i:last-child {
    left: 0;
    right: auto
}

.link-btn.link-prev:hover i:last-child {
    left: -10%;
    right: auto
}

.link-btn-2 {
    position: relative;
    font-size: 12px;
    font-weight: 500;
    color: #0e1133;
    text-transform: uppercase;
    padding-right: 25px;
    width: 40px;
    height: 40px;
    display: inline-block;
    line-height: 36px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, .2);
    overflow: hidden
}

.link-btn-2 i {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease
}

.link-btn-2 i:first-child {
    left: -100%;
    visibility: hidden;
    opacity: 0
}

.link-btn-2 i:last-child {
    right: 35%
}

.link-btn-2:hover {
    color: #fff
}

.link-btn-2:hover i:first-child {
    left: 35%;
    visibility: visible;
    opacity: 1
}

.link-btn-2:hover i:last-child {
    right: -100%;
    visibility: hidden;
    opacity: 0
}

.table>:not(:first-child) {
    border-top: 0 solid
}

.play-btn {
    display: inline-block;
    width: 44px;
    height: 44px;
    text-align: center;
    line-height: 47px;
    border-radius: 50%;
    background: #fff;
    color: var(--bs-primary);
    -webkit-animation: pulse 2s infinite;
    animation: pulse 2s infinite;
    font-size: 12px
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, .4)
    }

    70% {
        -webkit-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0)
    }

    to {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0)
    }
}

@keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, .4)
    }

    70% {
        -webkit-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 45px rgba(255, 255, 255, 0)
    }

    to {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0)
    }
}

.section__sub-title {
    font-size: 18px;
    font-weight: 500;
    color: var(--bs-primary);
    display: inline-block;
    margin-bottom: 5px
}

.section__title {
    font-size: 40px;
    color: #0e1133;
    text-transform: capitalize;
    z-index: 1;
    margin-bottom: 3px
}

@media(max-width:575px) {
    .section__title {
        font-size: 33px
    }
}

.section__title span {
    position: relative
}

.section__title span img {
    position: absolute;
    left: -14px;
    bottom: 12px;
    z-index: -1;
    -webkit-animation: section-animation 3s infinite;
    animation: section-animation 3s infinite
}

.section__title span.yellow-bg-big img {
    bottom: 15px
}

.section__title span.yellow-bg-big img, .section__title span.yellow-bg-sm img {
    width: 120%;
    -webkit-animation: section-animation-2 3s infinite;
    animation: section-animation-2 3s infinite
}

.section__title span.yellow-bg-sm img {
    bottom: 10px
}

.section__title-wrapper {
    position: relative;
    z-index: 1
}

.section__title-wrapper p {
    font-size: 16px;
    color: #53545b
}

@-webkit-keyframes section-animation {
    0% {
        width: 0
    }

    15% {
        width: 100%
    }

    85% {
        opacity: 1
    }

    90% {
        width: 100%;
        opacity: 0
    }

    to {
        width: 0;
        opacity: 0
    }
}

@keyframes section-animation {
    0% {
        width: 0
    }

    15% {
        width: 100%
    }

    85% {
        opacity: 1
    }

    90% {
        width: 100%;
        opacity: 0
    }

    to {
        width: 0;
        opacity: 0
    }
}

@-webkit-keyframes section-animation-2 {
    0% {
        width: 0
    }

    15% {
        width: 125%
    }

    85% {
        opacity: 1
    }

    90% {
        width: 125%;
        opacity: 0
    }

    to {
        width: 0;
        opacity: 0
    }
}

@keyframes section-animation-2 {
    0% {
        width: 0
    }

    15% {
        width: 125%
    }

    85% {
        opacity: 1
    }

    90% {
        width: 125%;
        opacity: 0
    }

    to {
        width: 0;
        opacity: 0
    }
}

.basic-pagination ul li {
    display: inline-block;
    margin-right: 20px
}

@media(max-width:575px) {
    .basic-pagination ul li {
        margin-right: 5px
    }
}

.basic-pagination ul li.next a, .basic-pagination ul li.prev a {
    width: inherit;
    height: inherit;
    line-height: inherit;
    border-radius: 0;
    border: none
}

.basic-pagination ul li.next a:hover, .basic-pagination ul li.prev a:hover {
    color: var(--bs-primary);
    background: inherit
}

.basic-pagination ul li.active a {
    color: #fff;
    background: var(--bs-primary);
    border-color: var(--bs-primary)
}

.basic-pagination ul li a {
    position: relative;
    overflow: hidden;
    background: transparent;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 38px;
    text-align: center;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    border: 2px solid #eef0f6;
    color: #0e1133
}

.basic-pagination ul li a:hover {
    background: var(--bs-primary);
    color: #fff;
    border-color: var(--bs-primary)
}

.body-overlay {
    z-index: 99;
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.body-overlay:hover {
    cursor: pointer
}

.body-overlay.opened {
    opacity: 1;
    visibility: visible
}

.mt-5 {
    margin-top: 5px !important
}

.mt-10 {
    margin-top: 10px
}

.mt-15 {
    margin-top: 15px
}

.mt-20 {
    margin-top: 20px
}

.mt-25 {
    margin-top: 25px
}

.mt-30 {
    margin-top: 30px
}

.mt-35 {
    margin-top: 35px
}

.mt-40 {
    margin-top: 40px
}

.mt-45 {
    margin-top: 45px
}

.mt-50 {
    margin-top: 50px
}

.mt-55 {
    margin-top: 55px
}

.mt-60 {
    margin-top: 60px
}

.mt-65 {
    margin-top: 65px
}

.mt-70 {
    margin-top: 70px
}

.mt-75 {
    margin-top: 75px
}

.mt-80 {
    margin-top: 80px
}

.mt-85 {
    margin-top: 85px
}

.mt-90 {
    margin-top: 90px
}

.mt-95 {
    margin-top: 95px
}

.mt-100 {
    margin-top: 100px
}

.mt-105 {
    margin-top: 105px
}

.mt-110 {
    margin-top: 110px
}

.mt-115 {
    margin-top: 115px
}

.mt-120 {
    margin-top: 120px
}

.mt-125 {
    margin-top: 125px
}

.mt-130 {
    margin-top: 130px
}

.mt-135 {
    margin-top: 135px
}

.mt-140 {
    margin-top: 140px
}

.mt-145 {
    margin-top: 145px
}

.mt-150 {
    margin-top: 150px
}

.mt-155 {
    margin-top: 155px
}

.mt-160 {
    margin-top: 160px
}

.mt-165 {
    margin-top: 165px
}

.mt-170 {
    margin-top: 170px
}

.mt-175 {
    margin-top: 175px
}

.mt-180 {
    margin-top: 180px
}

.mt-185 {
    margin-top: 185px
}

.mt-190 {
    margin-top: 190px
}

.mt-195 {
    margin-top: 195px
}

.mt-200 {
    margin-top: 200px
}

.mb-5 {
    margin-bottom: 5px !important
}

.mb-10 {
    margin-bottom: 10px
}

.mb-15 {
    margin-bottom: 15px
}

.mb-20 {
    margin-bottom: 20px
}

.mb-25 {
    margin-bottom: 25px
}

.mb-30 {
    margin-bottom: 30px
}

.mb-35 {
    margin-bottom: 35px
}

.mb-40 {
    margin-bottom: 40px
}

.mb-45 {
    margin-bottom: 45px
}

.mb-50 {
    margin-bottom: 50px
}

.mb-55 {
    margin-bottom: 55px
}

.mb-60 {
    margin-bottom: 60px
}

.mb-65 {
    margin-bottom: 65px
}

.mb-70 {
    margin-bottom: 70px
}

.mb-75 {
    margin-bottom: 75px
}

.mb-80 {
    margin-bottom: 80px
}

.mb-85 {
    margin-bottom: 85px
}

.mb-90 {
    margin-bottom: 90px
}

.mb-95 {
    margin-bottom: 95px
}

.mb-100 {
    margin-bottom: 100px
}

.mb-105 {
    margin-bottom: 105px
}

.mb-110 {
    margin-bottom: 110px
}

.mb-115 {
    margin-bottom: 115px
}

.mb-120 {
    margin-bottom: 120px
}

.mb-125 {
    margin-bottom: 125px
}

.mb-130 {
    margin-bottom: 130px
}

.mb-135 {
    margin-bottom: 135px
}

.mb-140 {
    margin-bottom: 140px
}

.mb-145 {
    margin-bottom: 145px
}

.mb-150 {
    margin-bottom: 150px
}

.mb-155 {
    margin-bottom: 155px
}

.mb-160 {
    margin-bottom: 160px
}

.mb-165 {
    margin-bottom: 165px
}

.mb-170 {
    margin-bottom: 170px
}

.mb-175 {
    margin-bottom: 175px
}

.mb-180 {
    margin-bottom: 180px
}

.mb-185 {
    margin-bottom: 185px
}

.mb-190 {
    margin-bottom: 190px
}

.mb-195 {
    margin-bottom: 195px
}

.mb-200 {
    margin-bottom: 200px
}

.ml-5 {
    margin-left: 5px
}

.ml-10 {
    margin-left: 10px
}

.ml-15 {
    margin-left: 15px
}

.ml-20 {
    margin-left: 20px
}

.ml-25 {
    margin-left: 25px
}

.ml-30 {
    margin-left: 30px
}

.ml-35 {
    margin-left: 35px
}

.ml-40 {
    margin-left: 40px
}

.ml-45 {
    margin-left: 45px
}

.ml-50 {
    margin-left: 50px
}

.ml-55 {
    margin-left: 55px
}

.ml-60 {
    margin-left: 60px
}

.ml-65 {
    margin-left: 65px
}

.ml-70 {
    margin-left: 70px
}

.ml-75 {
    margin-left: 75px
}

.ml-80 {
    margin-left: 80px
}

.ml-85 {
    margin-left: 85px
}

.ml-90 {
    margin-left: 90px
}

.ml-95 {
    margin-left: 95px
}

.ml-100 {
    margin-left: 100px
}

.ml-105 {
    margin-left: 105px
}

.ml-110 {
    margin-left: 110px
}

.ml-115 {
    margin-left: 115px
}

.ml-120 {
    margin-left: 120px
}

.ml-125 {
    margin-left: 125px
}

.ml-130 {
    margin-left: 130px
}

.ml-135 {
    margin-left: 135px
}

.ml-140 {
    margin-left: 140px
}

.ml-145 {
    margin-left: 145px
}

.ml-150 {
    margin-left: 150px
}

.ml-155 {
    margin-left: 155px
}

.ml-160 {
    margin-left: 160px
}

.ml-165 {
    margin-left: 165px
}

.ml-170 {
    margin-left: 170px
}

.ml-175 {
    margin-left: 175px
}

.ml-180 {
    margin-left: 180px
}

.ml-185 {
    margin-left: 185px
}

.ml-190 {
    margin-left: 190px
}

.ml-195 {
    margin-left: 195px
}

.ml-200 {
    margin-left: 200px
}

.mr-5 {
    margin-right: 5px
}

.mr-10 {
    margin-right: 10px
}

.mr-15 {
    margin-right: 15px
}

.mr-20 {
    margin-right: 20px
}

.mr-25 {
    margin-right: 25px
}

.mr-30 {
    margin-right: 30px
}

.mr-35 {
    margin-right: 35px
}

.mr-40 {
    margin-right: 40px
}

.mr-45 {
    margin-right: 45px
}

.mr-50 {
    margin-right: 50px
}

.mr-55 {
    margin-right: 55px
}

.mr-60 {
    margin-right: 60px
}

.mr-65 {
    margin-right: 65px
}

.mr-70 {
    margin-right: 70px
}

.mr-75 {
    margin-right: 75px
}

.mr-80 {
    margin-right: 80px
}

.mr-85 {
    margin-right: 85px
}

.mr-90 {
    margin-right: 90px
}

.mr-95 {
    margin-right: 95px
}

.mr-100 {
    margin-right: 100px
}

.mr-105 {
    margin-right: 105px
}

.mr-110 {
    margin-right: 110px
}

.mr-115 {
    margin-right: 115px
}

.mr-120 {
    margin-right: 120px
}

.mr-125 {
    margin-right: 125px
}

.mr-130 {
    margin-right: 130px
}

.mr-135 {
    margin-right: 135px
}

.mr-140 {
    margin-right: 140px
}

.mr-145 {
    margin-right: 145px
}

.mr-150 {
    margin-right: 150px
}

.mr-155 {
    margin-right: 155px
}

.mr-160 {
    margin-right: 160px
}

.mr-165 {
    margin-right: 165px
}

.mr-170 {
    margin-right: 170px
}

.mr-175 {
    margin-right: 175px
}

.mr-180 {
    margin-right: 180px
}

.mr-185 {
    margin-right: 185px
}

.mr-190 {
    margin-right: 190px
}

.mr-195 {
    margin-right: 195px
}

.mr-200 {
    margin-right: 200px
}

.pt-5 {
    padding-top: 5px !important
}

.pt-10 {
    padding-top: 10px
}

.pt-15 {
    padding-top: 15px
}

.pt-20 {
    padding-top: 20px
}

.pt-25 {
    padding-top: 25px
}

.pt-30 {
    padding-top: 30px
}

.pt-35 {
    padding-top: 35px
}

.pt-40 {
    padding-top: 40px
}

.pt-45 {
    padding-top: 45px
}

.pt-50 {
    padding-top: 50px
}

.pt-55 {
    padding-top: 55px
}

.pt-60 {
    padding-top: 60px
}

.pt-65 {
    padding-top: 65px
}

.pt-70 {
    padding-top: 70px
}

.pt-75 {
    padding-top: 75px
}

.pt-80 {
    padding-top: 80px
}

.pt-85 {
    padding-top: 85px
}

.pt-90 {
    padding-top: 90px
}

.pt-95 {
    padding-top: 95px
}

.pt-100 {
    padding-top: 100px
}

.pt-105 {
    padding-top: 105px
}

.pt-110 {
    padding-top: 110px
}

.pt-115 {
    padding-top: 115px
}

.pt-120 {
    padding-top: 120px
}

.pt-125 {
    padding-top: 125px
}

.pt-130 {
    padding-top: 130px
}

.pt-135 {
    padding-top: 135px
}

.pt-140 {
    padding-top: 140px
}

.pt-145 {
    padding-top: 145px
}

.pt-150 {
    padding-top: 150px
}

.pt-155 {
    padding-top: 155px
}

.pt-160 {
    padding-top: 160px
}

.pt-165 {
    padding-top: 165px
}

.pt-170 {
    padding-top: 170px
}

.pt-175 {
    padding-top: 175px
}

.pt-180 {
    padding-top: 180px
}

.pt-185 {
    padding-top: 185px
}

.pt-190 {
    padding-top: 190px
}

.pt-195 {
    padding-top: 195px
}

.pt-200 {
    padding-top: 200px
}

.pb-5 {
    padding-bottom: 5px !important
}

.pb-10 {
    padding-bottom: 10px
}

.pb-15 {
    padding-bottom: 15px
}

.pb-20 {
    padding-bottom: 20px
}

.pb-25 {
    padding-bottom: 25px
}

.pb-30 {
    padding-bottom: 30px
}

.pb-35 {
    padding-bottom: 35px
}

.pb-40 {
    padding-bottom: 40px
}

.pb-45 {
    padding-bottom: 45px
}

.pb-50 {
    padding-bottom: 50px
}

.pb-55 {
    padding-bottom: 55px
}

.pb-60 {
    padding-bottom: 60px
}

.pb-65 {
    padding-bottom: 65px
}

.pb-70 {
    padding-bottom: 70px
}

.pb-75 {
    padding-bottom: 75px
}

.pb-80 {
    padding-bottom: 80px
}

.pb-85 {
    padding-bottom: 85px
}

.pb-90 {
    padding-bottom: 90px
}

.pb-95 {
    padding-bottom: 95px
}

.pb-100 {
    padding-bottom: 100px
}

.pb-105 {
    padding-bottom: 105px
}

.pb-110 {
    padding-bottom: 110px
}

.pb-115 {
    padding-bottom: 115px
}

.pb-120 {
    padding-bottom: 120px
}

.pb-125 {
    padding-bottom: 125px
}

.pb-130 {
    padding-bottom: 130px
}

.pb-135 {
    padding-bottom: 135px
}

.pb-140 {
    padding-bottom: 140px
}

.pb-145 {
    padding-bottom: 145px
}

.pb-150 {
    padding-bottom: 150px
}

.pb-155 {
    padding-bottom: 155px
}

.pb-160 {
    padding-bottom: 160px
}

.pb-165 {
    padding-bottom: 165px
}

.pb-170 {
    padding-bottom: 170px
}

.pb-175 {
    padding-bottom: 175px
}

.pb-180 {
    padding-bottom: 180px
}

.pb-185 {
    padding-bottom: 185px
}

.pb-190 {
    padding-bottom: 190px
}

.pb-195 {
    padding-bottom: 195px
}

.pb-200 {
    padding-bottom: 200px
}

.pl-5 {
    padding-left: 5px
}

.pl-10 {
    padding-left: 10px
}

.pl-15 {
    padding-left: 15px
}

.pl-20 {
    padding-left: 20px
}

.pl-25 {
    padding-left: 25px
}

.pl-30 {
    padding-left: 30px
}

.pl-35 {
    padding-left: 35px
}

.pl-40 {
    padding-left: 40px
}

.pl-45 {
    padding-left: 45px
}

.pl-50 {
    padding-left: 50px
}

.pl-55 {
    padding-left: 55px
}

.pl-60 {
    padding-left: 60px
}

.pl-65 {
    padding-left: 65px
}

.pl-70 {
    padding-left: 70px
}

.pl-75 {
    padding-left: 75px
}

.pl-80 {
    padding-left: 80px
}

.pl-85 {
    padding-left: 85px
}

.pl-90 {
    padding-left: 90px
}

.pl-95 {
    padding-left: 95px
}

.pl-100 {
    padding-left: 100px
}

.pl-105 {
    padding-left: 105px
}

.pl-110 {
    padding-left: 110px
}

.pl-115 {
    padding-left: 115px
}

.pl-120 {
    padding-left: 120px
}

.pl-125 {
    padding-left: 125px
}

.pl-130 {
    padding-left: 130px
}

.pl-135 {
    padding-left: 135px
}

.pl-140 {
    padding-left: 140px
}

.pl-145 {
    padding-left: 145px
}

.pl-150 {
    padding-left: 150px
}

.pl-155 {
    padding-left: 155px
}

.pl-160 {
    padding-left: 160px
}

.pl-165 {
    padding-left: 165px
}

.pl-170 {
    padding-left: 170px
}

.pl-175 {
    padding-left: 175px
}

.pl-180 {
    padding-left: 180px
}

.pl-185 {
    padding-left: 185px
}

.pl-190 {
    padding-left: 190px
}

.pl-195 {
    padding-left: 195px
}

.pl-200 {
    padding-left: 200px
}

.pr-5 {
    padding-right: 5px
}

.pr-10 {
    padding-right: 10px
}

.pr-15 {
    padding-right: 15px
}

.pr-20 {
    padding-right: 20px
}

.pr-25 {
    padding-right: 25px
}

.pr-30 {
    padding-right: 30px
}

.pr-35 {
    padding-right: 35px
}

.pr-40 {
    padding-right: 40px
}

.pr-45 {
    padding-right: 45px
}

.pr-50 {
    padding-right: 50px
}

.pr-55 {
    padding-right: 55px
}

.pr-60 {
    padding-right: 60px
}

.pr-65 {
    padding-right: 65px
}

.pr-70 {
    padding-right: 70px
}

.pr-75 {
    padding-right: 75px
}

.pr-80 {
    padding-right: 80px
}

.pr-85 {
    padding-right: 85px
}

.pr-90 {
    padding-right: 90px
}

.pr-95 {
    padding-right: 95px
}

.pr-100 {
    padding-right: 100px
}

.pr-105 {
    padding-right: 105px
}

.pr-110 {
    padding-right: 110px
}

.pr-115 {
    padding-right: 115px
}

.pr-120 {
    padding-right: 120px
}

.pr-125 {
    padding-right: 125px
}

.pr-130 {
    padding-right: 130px
}

.pr-135 {
    padding-right: 135px
}

.pr-140 {
    padding-right: 140px
}

.pr-145 {
    padding-right: 145px
}

.pr-150 {
    padding-right: 150px
}

.pr-155 {
    padding-right: 155px
}

.pr-160 {
    padding-right: 160px
}

.pr-165 {
    padding-right: 165px
}

.pr-170 {
    padding-right: 170px
}

.pr-175 {
    padding-right: 175px
}

.pr-180 {
    padding-right: 180px
}

.pr-185 {
    padding-right: 185px
}

.pr-190 {
    padding-right: 190px
}

.pr-195 {
    padding-right: 195px
}

.pr-200 {
    padding-right: 200px
}

.header__padding {
    padding-left: 245px;
    padding-right: 245px
}

@media only screen and (min-width:1600px)and (max-width:1900px) {
    .header__padding {
        padding-left: 150px;
        padding-right: 150px
    }
}

@media only screen and (min-width:1400px)and (max-width:1600px) {
    .header__padding {
        padding-left: 70px;
        padding-right: 70px
    }
}

@media only screen and (min-width:1200px)and (max-width:1399px) {
    .header__padding {
        padding-left: 20px;
        padding-right: 20px
    }
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .header__padding {
        padding: 20px
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .header__padding {
        padding: 20px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .header__padding {
        padding: 20px
    }
}

@media(max-width:575px) {
    .header__padding {
        padding: 20px
    }
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .header__padding-2 {
        padding: 20px 0
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .header__padding-2 {
        padding: 20px 0
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .header__padding-2 {
        padding: 20px 0
    }
}

@media(max-width:575px) {
    .header__padding-2 {
        padding: 20px 0
    }
}

.header__shadow {
    -webkit-box-shadow: 0 30px 40px 0 rgba(1, 11, 60, .06);
    box-shadow: 0 30px 40px 0 rgba(1, 11, 60, .06)
}

.header__area.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 99;
    animation: fadeInDown .5s ease-in-out 0s 1 normal none running;
    -webkit-animation: fadeInDown .3s ease-in-out 0s 1 normal none running;
    display: block;
    -webkit-box-shadow: 0 10px 20px 0 rgba(8, 0, 42, .08);
    box-shadow: 0 10px 20px 0 rgba(8, 0, 42, .08)
}

.header__area.sticky .header__search input {
    background: #edeef3
}

.header__area.sticky .logo .logo-white {
    display: none
}

.header__area.sticky .logo .logo-black {
    display: block
}

.header__area.sticky .main-menu-3 ul li a {
    color: #0e1133
}

.header__area.sticky .main-menu-3 ul li a:after {
    border-color: #0e1133
}

.header__area.sticky .main-menu-3 ul li a:before {
    background: #0e1133
}

.header__area.sticky .main-menu-3 ul li .submenu li a:before {
    background: var(--bs-primary)
}

.header__area.sticky .header__search-2 svg .st0 {
    fill: #0e1133
}

.header__area.sticky .header__search-2 svg .st1 {
    fill: #121317
}

.header__area.sticky .cat-menu {
    color: #0e1133
}

.header__area.sticky .cat-menu:hover {
    color: var(--bs-primary)
}

.header__area.sticky .cat-menu:hover svg .cat-dot {
    fill: var(--bs-primary)
}

.header__area.sticky .cat-menu svg .cat-dot {
    fill: #0e1133
}

.header__area.sticky .header__category:after {
    background: rgba(14, 17, 51, .2)
}

.header__area.sticky .sidebar-toggle-btn .line {
    background-color: var(--bs-primary)
}

.header__transparent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 11;
    background: linear-gradient(180deg, #ffffff8a, transparent);
    height: 5rem;
}
.header__transparent__home {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 11;
    background: linear-gradient(180deg, #2b2b2b2e, transparent);
    height: 5rem;
}

.header__white .cat-menu {
    color: #fff
}

.header__white .cat-menu svg .cat-dot {
    fill: #fff
}

.header__white .cat-menu:hover {
    color: #fff
}

.header__white .cat-menu:hover svg .cat-dot {
    fill: #fff
}

.header__white .header__category:after {
    background: rgba(255, 255, 255, .2)
}

.header__white .main-menu ul li a {
    color: #fff
}

.header__white .sidebar-toggle-btn .line {
    background-color: #fff
}

.header__category {
    position: relative;
    padding-left: 30px;
    margin-left: 30px;
    padding-top: 3px
}

.header__category:after {
    position: absolute;
    content: "";
    left: 0;
    top: 60%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 40px;
    background: #c9ccd4
}

.header__category ul li {
    position: relative
}

.header__category ul li .cat-submenu {
    position: absolute;
    top: -webkit-calc(100% + 30px);
    top: calc(100% + 30px);
    left: 0;
    width: 200px;
    padding: 17px 0 15px;
    background: #fff;
    -webkit-box-shadow: 0 10px 20px 0 rgba(8, 0, 42, .08);
    box-shadow: 0 10px 20px 0 rgba(8, 0, 42, .08);
    border-radius: 4px;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.header__category ul li .cat-submenu li {
    display: block;
    margin-right: 0
}

.header__category ul li .cat-submenu li>a {
    width: 100%;
    display: block;
    padding: 3px 30px;
    text-transform: capitalize;
    color: #6d6e75;
    font-weight: 500;
    position: relative
}

.header__category ul li .cat-submenu li>a:after {
    right: 30px;
    -webkit-transform: translateY(-50%) rotate(-90deg);
    transform: translateY(-50%) rotate(-90deg)
}

.header__category ul li .cat-submenu li:hover>a {
    color: var(--bs-primary)
}

.header__category ul li:hover .cat-submenu {
    visibility: visible;
    opacity: 1;
    top: -webkit-calc(100% + 10px);
    top: calc(100% + 10px)
}

.header__search input {
    width: 280px;
    height: 50px;
    line-height: 20px;
    border-radius: 4px;
    background: #fff;
    outline: none;
    border: 1px solid #fff;
    font-size: 16px;
    padding: 25px 65px 20px 55px
}

.header__search input::-webkit-input-placeholder {
    color: #8c8faa
}

.header__search input::-moz-placeholder {
    color: #8c8faa
}

.header__search input:-ms-input-placeholder {
    color: #8c8faa
}

.header__search input::placeholder {
    color: #8c8faa
}

.header__search input:focus {
    border-color: var(--bs-primary)
}

.header__search button {
    background: transparent;
    position: absolute;
    top: 15px;
    left: 24px
}

.header__search button i:after {
    color: #0e1133;
    opacity: 1
}

.header__search button i:before {
    color: #8c8faa
}

.header__search-2 svg {
    width: 18px;
    height: 18px
}

.header__search-2 svg .st0 {
    fill: #8c8faa
}

.header__search-2 svg .st1 {
    fill: #fff
}

.header__search-2 svg:hover {
    cursor: pointer
}

.header__cart {
    position: absolute;
    top: 15px;
    right: 20px;
    padding-left: 18px
}

.header__cart:after {
    position: absolute;
    content: "";
    top: -5px;
    left: 0;
    width: 2px;
    height: 30px;
    background: #dbdfe9
}

.header__cart-icon {
    display: inline-block
}

.header__cart-icon svg {
    width: 20px;
    height: 19px
}

.header__cart-icon svg .st0 {
    fill: none;
    stroke: #0e1133;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round
}

.header__cart .cart-toggle-btn {
    position: relative;
    font-size: 17px;
    color: #0e1133;
    cursor: pointer
}

.header__cart .cart-toggle-btn .cart-item {
    position: absolute;
    top: -10px;
    right: -10px;
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 22px;
    text-align: center;
    font-size: 14px;
    color: #fff;
    background: var(--bs-primary);
    border-radius: 50%;
    font-weight: 500;
    -webkit-box-shadow: 0 10px 20px 0 rgba(4, 23, 118, .3);
    box-shadow: 0 10px 20px 0 rgba(4, 23, 118, .3)
}

.header__cart .cart-toggle-btn:hover .cart-item {
    background: #0e1133
}

.header__btn-2 a {
    height: 44px;
    line-height: 44px;
    padding: 0 38px
}

.header__search-3 {
    position: fixed;
    top: -100px;
    left: 0;
    width: 100%;
    min-height: 330px;
    padding: 40px 0;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale(.5);
    transform: scale(.5);
    z-index: 9999;
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
    background: #fff
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .header__search-3 {
        min-height: 300px
    }
}

@media(max-width:575px) {
    .header__search-3 {
        padding-top: 30px;
        min-height: 300px
    }
}

.header__search-3.search-opened {
    top: 0;
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
}

.header__search-3-btn {
    margin-bottom: 35px
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .header__search-3-btn {
        margin-bottom: 20px
    }
}

@media(max-width:575px) {
    .header__search-3-btn {
        margin-bottom: 20px
    }
}

.header__search-3-btn-close {
    font-size: 18px;
    color: var(--bs-primary);
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
}

.header__search-3-header {
    margin-bottom: 35px
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .header__search-3-header {
        margin-bottom: 20px
    }
}

@media(max-width:575px) {
    .header__search-3-header {
        margin-bottom: 20px
    }
}

.header__search-3-header .h3, .header__search-3-header h3 {
    color: #0e1133;
    font-size: 30px
}

.header__search-3-categories {
    margin-bottom: 35px
}

.header__search-3-categories ul li {
    display: inline-block
}

.header__search-3-categories ul li a {
    font-size: 18px;
    color: #121317;
    margin: 0 30px
}

.header__search-3-categories ul li a:hover {
    color: var(--bs-primary)
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .header__search-3-categories ul li a {
        margin: 0 15px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .header__search-3-categories ul li a {
        font-size: 16px;
        margin: 0 5px
    }
}

@media(max-width:575px) {
    .header__search-3-categories ul li a {
        margin: 0 5px;
        font-size: 14px
    }
}

.header__search-3-input input {
    height: 50px;
    width: 100%;
    padding: 0 30px 0 15px;
    border: none;
    border-bottom: 1px solid #eef0f6;
    border-radius: 0;
    outline: none
}

.header__search-3-input input::-webkit-input-placeholder {
    color: #53545b
}

.header__search-3-input input::-moz-placeholder {
    color: #53545b
}

.header__search-3-input input:-ms-input-placeholder {
    color: #53545b
}

.header__search-3-input input::placeholder {
    color: #53545b
}

.header__search-3-input input:focus {
    border-color: var(--bs-primary)
}

.header__search-3-input button {
    position: absolute;
    top: 0;
    right: 0;
    height: 50px;
    width: 50px;
    text-align: center;
    font-size: 14px;
    background: transparent;
    color: #0e1133
}

.logo-black {
    display: none
}

.cat-menu {
    font-size: 16px;
    font-weight: 500;
    color: #0e1133;
    position: relative
}

.cat-menu:hover {
    color: var(--bs-primary)
}

.cat-menu:hover svg .cat-dot {
    fill: var(--bs-primary)
}

.cat-menu svg {
    width: 16px;
    height: 16px
}

.cat-menu svg .cat-dot {
    fill: #0e1133
}

.cat-menu span {
    padding-left: 14px;
    line-height: 1;
    display: inline-block;
    padding-top: 3px
}

.main-menu ul li {
    display: inline-block;
    position: relative
}

.main-menu ul li:not(:first-child) {
    margin-left: 40px
}

@media only screen and (min-width:1400px)and (max-width:1600px) {
    .main-menu ul li:not(:first-child) {
        margin-left: 30px
    }
}

@media only screen and (min-width:1200px)and (max-width:1399px) {
    .main-menu ul li:not(:first-child) {
        margin-left: 14px
    }
}

.main-menu ul li a {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    padding: 15px 0;
    text-transform: capitalize
}

.main-menu ul li.has-dropdown>a {
    position: relative
}

.main-menu ul li.has-dropdown>a:after {
    content: " ";
    display: inline-block;
    border-bottom: 1px solid #0e1133;
    border-right: 1px solid #0e1133;
    height: 7px;
    width: 7px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-left: 5px;
    margin-bottom: 4px
}

.main-menu ul li:hover>a, .main-menu ul li:hover>a:after {
    color: var(--bs-primary)
}

.main-menu ul li:hover .submenu {
    top: 100%;
    visibility: visible;
    opacity: 1
}

.main-menu ul li .submenu {
    position: absolute;
    top: 120%;
    right: 0;
    width: 200px;
    background: #fff;
    z-index: 99;
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
    visibility: hidden;
    opacity: 0;
    -webkit-box-shadow: 0 30px 70px 0 rgba(11, 6, 70, .08);
    box-shadow: 0 30px 70px 0 rgba(11, 6, 70, .08)
}

.main-menu ul li .submenu li {
    display: block;
    width: 100%;
    margin: 0
}

.main-menu ul li .submenu li:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, .1)
}

.main-menu ul li .submenu li.has-dropdown>a:after {
    position: absolute;
    top: 50%;
    right: 25px;
    -webkit-transform: translateY(-50%) rotate(-90deg);
    transform: translateY(-50%) rotate(-90deg)
}

.main-menu ul li .submenu li a {
    padding: 10px 25px;
    font-size: 13px;
    position: relative;
    z-index: 1;
    color: #0e1133;
    width: 100%
}

.main-menu ul li .submenu li a:before {
    position: absolute;
    content: "";
    top: 0;
    left: auto;
    right: 0;
    width: 0;
    height: 100%;
    /* background: var(--bs-primary); */
    z-index: -1
}

.main-menu ul li .submenu li .submenu {
    left: 120%;
    top: 0;
    visibility: hidden;
    opacity: 0
}

/* .main-menu ul li .submenu li:hover>a, .main-menu ul li .submenu li:hover>a:after {
    color: #fff
} */

.main-menu ul li .submenu li:hover>a:before {
    left: 0;
    right: auto;
    width: 100%
}

.main-menu ul li .submenu li:hover .submenu {
    left: 100%;
    visibility: visible;
    opacity: 1
}

.main-menu-2 ul li:not(:first-child) {
    margin-left: 35px
}

@media only screen and (min-width:1200px)and (max-width:1399px) {
    .main-menu-2 ul li:not(:first-child) {
        margin-left: 20px
    }
}

.main-menu-2 ul li .menu-tag {
    position: absolute;
    top: 13px;
    right: -4px;
    display: inline-block;
    height: 20px;
    line-height: 20px;
    padding: 0 7px;
    background: var(--bs-primary);
    color: #fff;
    border-radius: 4px;
    font-size: 12px;
    text-transform: capitalize;
    -webkit-box-shadow: 0 8px 20px 0 rgba(1, 23, 133, .3);
    box-shadow: 0 8px 20px 0 rgba(1, 23, 133, .3)
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .main-menu-2 ul li .menu-tag {
        display: none
    }
}

.main-menu-2 ul li .menu-tag:after {
    position: absolute;
    content: "";
    bottom: -5px;
    left: 10px;
    width: 0;
    height: 0;
    border-left: 5px solid var(--bs-primary);
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent
}

.main-menu-3 ul li a {
    color: #fff;
    position: relative
}

.main-menu-3 ul li a:before {
    position: absolute;
    content: "";
    left: auto;
    right: 0;
    bottom: 38px;
    width: 0;
    height: 1px;
    background: #fff
}

.main-menu-3 ul li.has-dropdown a:after {
    color: #fff;
    border-color: #fff
}

.main-menu-3 ul li .submenu li a:after {
    color: #0e1133
}

.main-menu-3 ul li:hover a {
    color: #fff
}

.main-menu-3 ul li:hover a:before {
    width: 100%;
    left: 0;
    right: auto
}

.sidebar-toggle-btn .line {
    width: 30px;
    height: 3px;
    background-color: var(--bs-primary);
    display: block;
    margin: 6px auto;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.sidebar-toggle-btn-white .line {
    background: #fff
}

.sidebar-toggle-btn:hover {
    cursor: pointer
}

.sidebar-toggle-btn:hover .line {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg)
}

.sidebar-toggle-btn:hover .line:first-child {
    width: 10px
}

.sidebar-toggle-btn:hover .line:nth-child(2) {
    width: 20px
}

.sidebar__area {
    right: -340px;
    width: 320px;
    background: #fff none repeat scroll 0 0;
    overflow-y: scroll;
    -webkit-transition: all .3s cubic-bezier(.785, .135, .15, .86);
    transition: all .3s cubic-bezier(.785, .135, .15, .86);
    z-index: 9999
}

.sidebar__area.sidebar-opened {
    right: 0
}

.sidebar__wrapper {
    position: relative;
    padding: 30px
}

.sidebar__close {
    position: absolute;
    top: 25px;
    right: 80px
}

.sidebar__close-btn {
    -webkit-transition: all .45s cubic-bezier(.4, .25, .3, 1.3);
    transition: all .45s cubic-bezier(.4, .25, .3, 1.3);
    width: 40px;
    height: 40px;
    position: absolute;
    text-align: center;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    color: #0e1133;
    border: 1px solid #2a3a57;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    background: transparent;
    z-index: 99
}

.sidebar__close-btn:focus {
    border: 1px solid #0e1133
}

.sidebar__close-btn span {
    -webkit-transition: all .4s cubic-bezier(.4, .25, .3, 1.3) .1s;
    transition: all .4s cubic-bezier(.4, .25, .3, 1.3) .1s;
    position: absolute;
    top: 54%;
    left: 50%;
    font-size: 14px;
    font-weight: 500;
    line-height: 40px;
    vertical-align: middle
}

.sidebar__close-btn span:first-of-type {
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%)
}

.sidebar__close-btn span:last-of-type {
    text-transform: uppercase;
    transform: translate(-50%, 50%);
    -webkit-transform: translate(-50%, 50%);
    -moz-transform: translate(-50%, 50%);
    -ms-transform: translate(-50%, 50%);
    -o-transform: translate(-50%, 50%)
}

.sidebar__close-btn:hover {
    width: 100px;
    border-radius: 0
}

.sidebar__close-btn:hover span:first-of-type {
    transform: translate(-50%, -150%);
    -webkit-transform: translate(-50%, -150%);
    -moz-transform: translate(-50%, -150%);
    -ms-transform: translate(-50%, -150%);
    -o-transform: translate(-50%, -150%)
}

.sidebar__close-btn:hover span:last-of-type {
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%)
}

.sidebar__search input {
    height: 50px;
    line-height: 20px;
    background: #edeef3;
    outline: none;
    border: 1px solid #fff;
    font-size: 16px;
    padding: 25px 65px 20px 55px
}

.sidebar__search input::-webkit-input-placeholder {
    color: #8c8faa
}

.sidebar__search input::-moz-placeholder {
    color: #8c8faa
}

.sidebar__search input:-ms-input-placeholder {
    color: #8c8faa
}

.sidebar__search input::placeholder {
    color: #8c8faa
}

.sidebar__search input:focus {
    border-color: var(--bs-primary)
}

.sidebar__search button {
    background: transparent;
    top: 15px;
    left: 24px
}

.sidebar__search button i:after {
    color: #0e1133;
    opacity: 1
}

.sidebar__search button i:before {
    color: #8c8faa
}

.sidebar__cart-icon {
    display: inline-block
}

.sidebar__cart-icon svg {
    width: 20px;
    height: 19px
}

.sidebar__cart-icon svg .st0 {
    fill: none;
    stroke: #0e1133;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round
}

.sidebar__cart a {
    position: relative;
    font-size: 17px;
    color: #0e1133
}

.sidebar__cart a .cart-item {
    position: absolute;
    top: -10px;
    right: -10px;
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 22px;
    text-align: center;
    font-size: 14px;
    color: #fff;
    background: var(--bs-primary);
    border-radius: 50%;
    font-weight: 500;
    -webkit-box-shadow: 0 10px 20px 0 rgba(4, 23, 118, .3);
    box-shadow: 0 10px 20px 0 rgba(4, 23, 118, .3)
}

.sidebar__cart a:hover .cart-item {
    background: #0e1133
}

.sidebar__area {
    position: fixed;
    overflow-y: auto;
    top: 0;
    right: -350px;
    width: 300px;
    padding: 25px 0;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    background-color: #fff;
    z-index: 1020;
    -webkit-transition: all .6s cubic-bezier(.785, .135, .15, .86);
    transition: all .6s cubic-bezier(.785, .135, .15, .86);
    -webkit-box-shadow: -5px 0 20px -5px rgba(0, 0, 0, .5);
    box-shadow: -5px 0 20px -5px rgba(0, 0, 0, .5)
}

.sidebar__area.open {
    right: 0;
    visibility: visible;
    opacity: 1
}

.body-overlay {
    background-color: rgba(0, 0, 0, .5);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: -1;
    left: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .3s linear 0s;
    transition: all .3s linear 0s
}

.body-overlay.show {
    z-index: 999;
    opacity: 1;
    visibility: visible
}

.menu-bar .bars {
    display: inline-block;
    width: 50px;
    background: #212121;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border: 0;
    font-size: 20px;
    color: #fff
}

.close-mobile-menu {
    color: #212121;
    position: relative;
    z-index: 2;
    font-size: 16px;
    top: -10px;
    left: 0
}

.close-mobile-menu:hover {
    color: var(--bs-primary)
}

.mm-menu ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.mm-menu ul li {
    display: block
}

.mm-menu ul li:hover>a {
    padding-left: 3px;
    color: var(--bs-primary)
}

.mm-menu ul li a {
    padding: 12px 0;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    font-size: 14px;
    color: #101a23;
    font-weight: 600;
    text-transform: uppercase;
    position: relative
}

.mm-menu ul li a.active {
    padding-left: 3px;
    color: var(--bs-primary)
}

.mm-menu ul li.has-droupdown>a:after {
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    border-style: solid;
    border-width: 2px 0 0 2px;
    border-color: initial;
    right: 16px;
    top: 50%;
    -webkit-transform: rotate(-45deg) translateY(-50%);
    transform: rotate(-45deg) translateY(-50%);
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out
}

.mm-menu ul li.has-droupdown ul.sub-menu {
    padding-left: 0;
    list-style: none
}

.mm-menu ul li.has-droupdown ul.sub-menu li {
    padding-left: 15px
}

.mm-menu ul li.has-droupdown ul.sub-menu li:hover>a {
    padding: 0 0 0 18px;
    color: var(--bs-primary)
}

.mm-menu ul li.has-droupdown ul.sub-menu li:hover>a:before {
    background: var(--bs-primary);
    border-color: var(--bs-primary)
}

.mm-menu ul li.has-droupdown ul.sub-menu li a {
    position: relative;
    padding: 0 0 0 15px;
    text-transform: capitalize;
    font-size: 13px;
    height: 0;
    line-height: 46px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all .3s;
    transition: all .3s
}

.mm-menu ul li.has-droupdown ul.sub-menu li a:before {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border: 2px solid #6d8397;
    border-radius: 50%;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all .3s;
    transition: all .3s
}

.mm-menu ul li.has-droupdown.active>a {
    color: var(--bs-primary)
}

.mm-menu ul li.has-droupdown.active>a:after {
    -webkit-transform: rotate(-135deg) translateY(-50%);
    transform: rotate(-135deg) translateY(-50%)
}

.mm-menu ul li.has-droupdown.active ul.sub-menu.active li a {
    height: 46px;
    visibility: visible;
    opacity: 1
}

.mm-menu ul li.has-droupdown.active ul.sub-menu.active li a:before {
    visibility: visible;
    opacity: 1
}

.sidebar__areas.open .cartmini__wrapper {
    right: 0
}

.header__cart--responsive {
    display: none
}

@media(max-width:575px), only screen and (min-width:576px)and (max-width:767px) {
    .header__cart--responsive {
        display: inline-block;
        position: inherit;
        padding: 0;
        margin-right: 8px;
        margin-top: 5px
    }
}

.header__cart--responsive__white .header__cart-icon svg .st0 {
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round
}

.sticky .header__cart--responsive__white .header__cart-icon svg .st0 {
    fill: none;
    stroke: #0e1133;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round
}

.hero__height {
    min-height: 950px
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .hero__height {
        min-height: 850px
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .hero__height {
        min-height: 750px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .hero__height {
        min-height: 1100px
    }
}

@media(max-width:575px) {
    .hero__height {
        min-height: 850px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .hero__height-2 {
        min-height: 1350px
    }
}

@media(max-width:575px) {
    .hero__height-2 {
        min-height: 850px
    }
}

.hero__title {
    font-size: 60px;
    line-height: 1
}

@media only screen and (min-width:1200px)and (max-width:1399px) {
    .hero__title {
        font-size: 55px
    }
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .hero__title {
        font-size: 45px
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .hero__title {
        font-size: 35px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .hero__title {
        font-size: 50px
    }
}

@media(max-width:575px) {
    .hero__title {
        font-size: 30px
    }
}

.hero__title span:first-child {
    font-weight: 300;
    display: block
}

.hero__title span.yellow-shape {
    position: relative;
    z-index: 1
}

.hero__title span.yellow-shape img {
    position: absolute;
    bottom: 22px;
    left: -8px;
    z-index: -1;
    -webkit-animation: section-animation 3s infinite;
    animation: section-animation 3s infinite
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .hero__title span.yellow-shape img {
        bottom: 12px;
        height: 40% !important
    }
}

.hero__title-2 {
    margin-bottom: 20px
}

.hero__content {
    z-index: 2
}

.hero__content p {
    font-size: 22px;
    color: #0e1133;
    padding-right: 130px;
    line-height: 30px;
    margin-bottom: 28px
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .hero__content p {
        padding-right: 30px
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .hero__content p {
        padding-right: 0
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .hero__content p {
        padding-right: 0
    }
}

@media(max-width:575px) {
    .hero__content p {
        padding-right: 0
    }
}

.hero__content-2 .h4, .hero__content-2 h4 {
    font-size: 22px;
    margin-bottom: 0
}

.hero__content-2 p {
    color: #53545b;
    font-size: 16px;
    margin-bottom: 37px
}

.hero__thumb {
    z-index: 1
}

.hero__thumb-big {
    position: relative
}

.hero__thumb-big img {
    max-width: 400px;
    max-height: 460px;
    border-radius: 40px 4px 40px 4px;
    -webkit-box-shadow: 0 30px 60px 0 rgba(1, 11, 60, .14);
    box-shadow: 0 30px 60px 0 rgba(1, 11, 60, .14)
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .hero__thumb-big img {
        width: 100%
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .hero__thumb-big img {
        margin-top: 50px;
        width: 100%
    }
}

@media(max-width:575px) {
    .hero__thumb-big img {
        margin-top: 50px;
        width: 100%
    }
}

@media only screen and (min-width:1200px)and (max-width:1399px) {
    .hero__thumb-sm {
        margin-left: -50px
    }
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .hero__thumb-sm {
        margin-left: -160px;
        margin-top: -95px
    }
}

.hero__thumb-sm img {
    max-width: 200px;
    max-height: 240px;
    border-radius: 4px 40px 4px 40px;
    -webkit-box-shadow: 0 30px 60px 0 rgba(1, 11, 60, .14);
    box-shadow: 0 30px 60px 0 rgba(1, 11, 60, .14)
}

.hero__thumb-shape img {
    position: absolute;
    z-index: -1
}

.hero__thumb-shape img.hero-1-dot {
    bottom: -25px;
    left: -30px;
    -webkit-animation: hero-dot-1 5s linear 0s infinite alternate;
    animation: hero-dot-1 5s linear 0s infinite alternate
}

.hero__thumb-shape img.hero-1-circle-3 {
    bottom: -47px;
    right: 1%
}

.hero__thumb-shape img.hero-1-circle-4 {
    top: -50px;
    right: 10%
}

.hero__thumb-2 {
    position: relative;
    z-index: 1
}

@media only screen and (min-width:1200px)and (max-width:1399px) {
    .hero__thumb-2 img.hero-big {
        width: 100%
    }
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .hero__thumb-2 img.hero-big {
        width: 100%
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .hero__thumb-2 img.hero-big {
        width: 100%
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .hero__thumb-2 img.hero-big {
        width: 100%
    }
}

@media(max-width:575px) {
    .hero__thumb-2 img.hero-big {
        width: 100%
    }
}

.hero__thumb-2 img.hero-shape-purple {
    position: absolute;
    left: 0;
    bottom: 19%;
    z-index: -1
}

@media only screen and (min-width:1200px)and (max-width:1399px) {
    .hero__thumb-2 img.hero-shape-purple {
        width: 100%
    }
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .hero__thumb-2 img.hero-shape-purple {
        width: 100%
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .hero__thumb-2 img.hero-shape-purple {
        width: 100%
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .hero__thumb-2 img.hero-shape-purple {
        width: 100%
    }
}

@media(max-width:575px) {
    .hero__thumb-2 img.hero-shape-purple {
        width: 100%
    }
}

.hero__quote {
    position: absolute;
    bottom: 60px;
    right: -200px;
    padding: 15px 25px 20px;
    background: #fff;
    border-radius: 4px;
    -webkit-box-shadow: 0 30px 60px 0 rgba(1, 11, 60, .14);
    box-shadow: 0 30px 60px 0 rgba(1, 11, 60, .14)
}

@media only screen and (min-width:1200px)and (max-width:1399px) {
    .hero__quote {
        right: -145px
    }
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .hero__quote {
        right: 60px;
        bottom: -50px
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .hero__quote {
        right: 30px;
        bottom: -50px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .hero__quote {
        right: 30px;
        bottom: -50px
    }
}

@media(max-width:575px) {
    .hero__quote {
        right: 15px;
        bottom: -50px
    }
}

.hero__quote span {
    font-size: 14px;
    color: #474956
}

.hero__quote .h4, .hero__quote h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 1
}

.hero__quote-animation {
    -webkit-animation-duration: 2.5s;
    animation-duration: 2.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: hero-bounce;
    animation-name: hero-bounce
}

.hero__shape img {
    position: absolute
}

.hero__shape img.hero-1-circle {
    top: 25%;
    left: 5%;
    -webkit-animation: hero-circle-1 6s linear 0s infinite alternate;
    animation: hero-circle-1 6s linear 0s infinite alternate
}

.hero__shape img.hero-1-circle-2 {
    bottom: 29%;
    left: 0
}

.hero__shape img.hero-1-dot-2 {
    right: 0;
    bottom: 38%
}

.hero__search-input {
    position: relative;
    width: 370px
}

@media(max-width:575px) {
    .hero__search-input {
        width: 100%
    }
}

.hero__search-input input {
    width: 100%;
    height: 54px;
    line-height: 54px;
    padding: 0 35px 0 24px;
    font-size: 14px;
    border-radius: 4px;
    outline: none;
    border: none
}

.hero__search-input input::-webkit-input-placeholder {
    color: #8d8e9a
}

.hero__search-input input::-moz-placeholder {
    color: #8d8e9a
}

.hero__search-input input:-ms-input-placeholder {
    color: #8d8e9a
}

.hero__search-input input::placeholder {
    color: #8d8e9a
}

.hero__search-input button {
    position: absolute;
    top: 53%;
    right: 24px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 18px;
    background: transparent
}

.hero__search-input button i:after {
    color: #0e1133;
    opacity: 1
}

.hero__search-input button i:before {
    color: #8c8faa
}

.hero__search p {
    font-size: 16px;
    color: #6d6e75
}

.hero__promotion {
    position: absolute !important;
    left: -7% !important;
    top: 44% !important;
    border-radius: 4px;
    -webkit-box-shadow: 0 30px 60px 0 rgba(1, 11, 60, .14);
    box-shadow: 0 30px 60px 0 rgba(1, 11, 60, .14);
    padding: 15px 20px 10px
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .hero__promotion {
        left: 20% !important;
        top: 71% !important
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .hero__promotion {
        left: 9% !important;
        top: 71% !important
    }
}

@media(max-width:575px) {
    .hero__promotion {
        left: 9% !important;
        top: 52% !important
    }
}

.hero__promotion.education {
    bottom: 28% !important;
    top: auto !important;
    left: -11% !important
}

.hero__promotion-icon span {
    display: inline-block;
    width: 36px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    border-radius: 50%;
    background: #ff7921
}

.hero__promotion-icon span svg {
    height: 24px;
    fill: #fff
}

.hero__promotion-icon span.cap {
    background: #5773ff
}

.hero__promotion-text .h5, .hero__promotion-text h5 {
    font-size: 16px;
    margin-bottom: 0
}

.hero__promotion-text p {
    font-size: 14px;
    color: #474956;
    margin-bottom: 0
}

.hero__class {
    position: absolute;
    left: auto !important;
    top: auto !important;
    bottom: 33%;
    right: -12%;
    -webkit-box-shadow: 0 30px 60px 0 rgba(1, 11, 60, .14);
    box-shadow: 0 30px 60px 0 rgba(1, 11, 60, .14);
    background: rgba(255, 255, 255, .9);
    border-radius: 4px;
    padding: 20px
}

@media only screen and (min-width:1200px)and (max-width:1399px) {
    .hero__class {
        right: -5%
    }
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .hero__class {
        right: 1%;
        bottom: 15%
    }
}

.hero__class-thumb img {
    width: 34px;
    height: 34px;
    border-radius: 50%
}

.hero__class-text .h5, .hero__class-text h5 {
    font-size: 16px;
    margin-bottom: 0
}

.hero__class-text p {
    font-size: 14px;
    color: #474956;
    margin-bottom: 0
}

.hero__class-text a {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    font-weight: 500;
    padding: 0 15px;
    border-radius: 4px;
    background: #e233fb
}

.hero__mic {
    position: absolute;
    top: 30%;
    right: 10%;
    -webkit-animation-duration: 2.5s;
    animation-duration: 2.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: hero-bounce;
    animation-name: hero-bounce
}

.hero__mic span {
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #ff3c4e;
    -webkit-box-shadow: 0 14px 30px 0 rgba(131, 2, 14, .2);
    box-shadow: 0 14px 30px 0 rgba(131, 2, 14, .2);
    border-radius: 6px
}

.hero__mic span svg {
    height: 28px;
    fill: #fff
}

@-webkit-keyframes hero-dot-1 {
    0% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px)
    }

    to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes hero-dot-1 {
    0% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px)
    }

    to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@-webkit-keyframes hero-bounce {
    0%, 20%, 50%, 80%, to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px)
    }

    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px)
    }
}

@keyframes hero-bounce {
    0%, 20%, 50%, 80%, to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px)
    }

    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px)
    }
}

@-webkit-keyframes hero-circle-1 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes hero-circle-1 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@media(max-width:575px) {
    .hero__content-wrapper.mt-90 {
        margin-top: 160px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .hero__content-wrapper.mt-90 {
        margin-top: 125px
    }
}

.category__item {
    padding: 20px 40px;
    border-radius: 6px;
    border: 2px solid #eef0f6
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .category__item {
        padding-left: 30px;
        padding-right: 30px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .category__item {
        padding-left: 15px;
        padding-right: 15px
    }
}

@media(max-width:575px) {
    .category__item {
        padding-left: 30px;
        padding-right: 30px
    }
}

.category__item:hover {
    background: var(--bs-primary);
    border-color: var(--bs-primary);
    -webkit-box-shadow: 0 20px 30px 0 rgba(4, 23, 118, .3);
    box-shadow: 0 20px 30px 0 rgba(4, 23, 118, .3)
}

.category__item:hover .category__title {
    color: #fff
}

.category__item:hover svg .st0, .category__item:hover svg .st1, .category__item:hover svg .st2, .category__item:hover svg .st4, .category__item:hover svg .st5 {
    fill: #fff
}

.category__item:hover .category__content p {
    color: #fff
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .category__icon {
        margin-right: 15px
    }
}

.category__icon svg {
    width: 40px;
    height: 40px
}

.category__icon svg .st0 {
    fill: #cee1f2
}

.category__icon svg .st1, .category__icon svg .st4 {
    fill: #3444f1
}

.category__icon svg .st2, .category__icon svg .st5 {
    fill: #ffb31f
}

.category__title {
    font-size: 20px;
    line-height: 1;
    margin-bottom: 0
}

.category__content p {
    font-weight: 500;
    margin-bottom: 0
}

.banner__item {
    padding: 50px 40px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .banner__item {
        padding-left: 25px;
        padding-right: 25px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .banner__item {
        padding-left: 25px;
        padding-right: 25px
    }
}

.banner__item span {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background: #fc4389;
    display: inline-block;
    height: 20px;
    line-height: 22px;
    padding: 0 14px;
    margin-bottom: 15px;
    border-radius: 10px;
    text-transform: capitalize
}

.banner__item span.orange {
    background: #ff652e
}

.banner__title {
    font-size: 26px;
    margin-bottom: 30px
}

.banner__title a:hover {
    color: var(--bs-primary)
}

.banner__thumb {
    position: absolute;
    top: 56%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 10px
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .banner__thumb {
        right: 0
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .banner__thumb {
        right: 0
    }
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .banner__thumb img {
        width: 240px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .banner__thumb img {
        width: 240px
    }
}

.banner__thumb-2 {
    top: 63%
}

.banner__content {
    position: relative;
    z-index: 1
}

.col___3 {
    padding: 10px;
    font-size: 16px;
    color: #53545b;
    font-weight: 500
}

.thumb {
    cursor: pointer;
}

.course__item {
    border-radius: 6px;
    box-shadow: rgb(2 2 26 / 6%) 0 0 20px 0;
    min-height: 9rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    
}

.course__item-3 {
    -webkit-box-shadow: none;
    box-shadow: none
}

.course__item-3 .course__more {
    border: 1px solid #eef0f6
}

.course__item-3 .course__content {
    border: 1px solid #eef0f6;
    border-top: none;
    border-bottom: none
}

.course__item:hover .course__thumb img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
}

.course__item-2 {
    padding: 40px
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .course__item-2 {
        padding: 20px
    }
}

@media(max-width:575px) {
    .course__item-2 {
        padding: 20px
    }
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .course__thumb-list, .course__thumb-list img {
        height: 100%
    }
}

.course__tag {
    position: absolute;
    top: 20px;
    left: 20px
}

.course__tag a {
    display: inline-block;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background: #b128ff;
    padding: 0 10px;
    border-radius: 4px
}

.course__tag a.sky-blue {
    background: #0fa0dd
}

.course__tag a.green {
    background: #30a820
}

.course__tag a.blue {
    background: #3b60ff
}

.course__tag a.blue-2 {
    background: #5744cb
}

.course__tag a.orange {
    background: #fa7919
}

.course__tag a.pink {
    background: #f2277e
}

.course__tag a.yellow {
    background: #ffa507
}

.course__tag-2 i {
    color: var(--bs-primary);
    padding-right: 8px
}

.course__tag-2 a {
    font-size: 16px;
    color: #53545b;
    font-weight: 500
}

.course__tag-2 a:hover {
    color: var(--bs-primary)
}

.course__title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .course__title {
        font-size: 18px
    }
}

.course__title a:hover {
    color: var(--bs-primary)
}

.course__title-2 {
    font-size: 24px;
    margin-bottom: 2px
}

.course__title-2 a:hover {
    color: var(--bs-primary)
}

.course__title-3 {
    font-size: 36px
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .course__title-3 {
        font-size: 30px
    }
}

@media(max-width:575px) {
    .course__title-3 {
        font-size: 20px
    }
}

.course__meta {
    margin-bottom: 7px
}

.course__lesson span {
    font-size: 16px
}

.course__lesson span i {
    color: #53545b;
    padding-right: 7px
}

.blog__content {
    padding: 250px 300px
}

.course__content {
    padding: 25px 30px
}

.course__content-2 {
    padding-top: 25px
}

.course__content-2 p {
    margin-bottom: 30px
}

.course__content-3 {
    padding-left: 80px;
    padding-right: 70px
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .course__content-3 {
        padding-top: 15px;
        padding-bottom: 80px
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .course__content-3 {
        padding-left: 45px;
        padding-bottom: 85px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .course__content-3 {
        padding-left: 45px;
        padding-bottom: 85px
    }
}

@media(max-width:575px) {
    .course__content-3 {
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 85px
    }
}

.course__content-4 {
    padding-left: 50px;
    padding-right: 50px
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .course__content-4 {
        padding-bottom: 70px
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .course__content-4 {
        padding-left: 45px;
        padding-bottom: 85px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .course__content-4 {
        padding-left: 45px;
        padding-bottom: 85px
    }
}

@media(max-width:575px) {
    .course__content-4 {
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 85px
    }
}

.course__summary p {
    font-size: 18px
}

@media(max-width:575px) {
    .course__summary p {
        font-size: 16px
    }
}

.course__right {
    position: relative;
    height: 100%
}

.course__rating span {
    font-size: 16px
}

.course__rating span i {
    padding-right: 5px;
    color: #ff9415
}

.course__rating-2 .h5, .course__rating-2 h5 {
    font-size: 14px;
    color: #53545b;
    font-weight: 600;
    margin-bottom: 0
}

.course__rating-2 ul li {
    display: inline-block
}

.course__rating-2 ul li a {
    font-size: 14px;
    color: #ff9415
}

.course__rating-inner p {
    font-size: 16px;
    color: #0e1133;
    font-weight: 600;
    margin-bottom: 0;
    padding-left: 3px
}

.course__teacher-thumb img {
    width: 35px;
    height: 35px;
    border: 2px solid #fff;
    border-radius: 50%;
    -webkit-box-shadow: 0 10px 14px 0 rgba(1, 11, 60, .1);
    box-shadow: 0 10px 14px 0 rgba(1, 11, 60, .1)
}

.course__teacher-thumb-2 {
    position: relative
}

.course__teacher-thumb-2 img {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    -webkit-box-shadow: 0 10px 20px 0 rgba(0, 5, 52, .1);
    box-shadow: 0 10px 20px 0 rgba(0, 5, 52, .1)
}

.course__teacher-thumb-3 img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 2px solid #fff;
    -webkit-box-shadow: 0 10px 14px 0 rgba(1, 11, 60, .1);
    box-shadow: 0 10px 14px 0 rgba(1, 11, 60, .1)
}

.course__teacher .h6, .course__teacher h6 {
    font-size: 16px;
    font-weight: 500;
    color: #53545b;
    margin-bottom: 0;
    line-height: 1;
    padding-top: 2px
}

.course__teacher .h6 a:hover, .course__teacher h6 a:hover {
    color: var(--bs-primary)
}

.course__teacher-info .h6, .course__teacher-info h6 {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 0
}

.course__teacher-info .h6 a, .course__teacher-info h6 a {
    line-height: 1
}

.course__teacher-info span {
    color: #6d6e75;
    font-size: 14px;
    font-weight: 600
}

.course__teacher-info-3 .h5, .course__teacher-info-3 h5 {
    font-size: 14px;
    color: #53545b;
    font-weight: 600;
    margin-bottom: 0
}

.course__teacher-info-3 p {
    font-size: 16px;
    color: #0e1133;
    font-weight: 600;
    margin-bottom: 0
}

.course__teacher-rating {
    position: absolute;
    top: -1px;
    right: -7px
}

.course__teacher-rating i {
    display: inline-block;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    font-size: 10px;
    color: #fff;
    background: #ff9415;
    border-radius: 50%
}

.course__update .h5, .course__update h5 {
    font-size: 14px;
    color: #53545b;
    font-weight: 600;
    margin-bottom: 0
}

.course__update p {
    font-size: 16px;
    color: #0e1133;
    font-weight: 600;
    margin-bottom: 0
}

.course__status span {
    font-size: 18px;
    color: #000000;
    font-weight: 600;
    padding-right: 5px;
}

.course__status span.sky-blue {
    color: #0fa0dd
}

.course__status span.green {
    color: #30a820
}

.course__status span.blue {
    color: #3b60ff
}

.course__status span.blue-2 {
    color: #5744cb
}

.course__status span.orange {
    color: #fa7919
}

.course__status span.pink {
    color: #f2277e
}

.course__status span.yellow {
    color: #ffa507
}

.course__status span.old-price {
    font-size: 14px;
    color: #6d6e75;
    text-decoration: line-through
}

.course__more {
    padding: 15px 30px 10px;
    border-top: 1px solid #f0f0f5
}

.course__more-2 {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding-left: 80px;
    padding-right: 70px;
    background: #fff
}

@media(max-width:575px) {
    .course__more-2 {
        padding-left: 25px;
        padding-right: 25px
    }
}

.course__more-3 {
    padding-left: 50px;
    padding-right: 50px
}

@media(max-width:575px) {
    .course__more-3 {
        padding-left: 25px;
        padding-right: 25px
    }
}

.course__btn .link-btn {
    color: #0e1133
}

.course__btn .link-btn:hover {
    color: var(--bs-primary)
}

.course__menu button {
    font-size: 16px;
    font-weight: 500;
    color: #0e1133;
    background: transparent;
    text-transform: capitalize;
    position: relative
}

.course__menu button .tag {
    position: absolute;
    top: -25px;
    right: -4px;
    display: inline-block;
    height: 20px;
    line-height: 20px;
    padding: 0 7px;
    background: var(--bs-primary);
    color: #fff;
    border-radius: 4px;
    font-size: 12px;
    text-transform: capitalize;
    -webkit-box-shadow: 0 8px 20px 0 rgba(1, 23, 133, .3);
    box-shadow: 0 8px 20px 0 rgba(1, 23, 133, .3)
}

.course__menu button .tag:after {
    position: absolute;
    content: "";
    bottom: -5px;
    left: 10px;
    width: 0;
    height: 0;
    border-left: 5px solid var(--bs-primary);
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent
}

.course__menu button.active, .course__menu button:hover {
    color: var(--bs-primary)
}

.course__menu button:not(:first-child) {
    margin-left: 25px
}

@media(max-width:575px) {
    .course__menu button:not(:first-child) {
        margin-left: 0
    }

    .course__menu button {
        margin-right: 10px
    }
}

.course__tab-inner {
    padding: 15px 20px;
    border-radius: 4px
}

.course__tab-btn .react-tabs__tab-list {
    border: none !important;
    margin-bottom: 0
}

.course__tab-btn .react-tabs__tab-list .react-tabs__tab {
    border: none !important;
    padding: 0 !important;
    background: none !important;
    bottom: 0 !important
}

.course__tab-btn .react-tabs__tab-list .react-tabs__tab .nav-link {
    border-radius: 4px;
    line-height: 31px;
    border: none;
    width: 30px;
    height: 30px;
    background: transparent;
    padding: 0;
    margin-right: 6px
}

.course__tab-btn .react-tabs__tab-list .react-tabs__tab .nav-link svg {
    width: 14px;
    height: 14px !important
}

.course__tab-btn .react-tabs__tab-list .react-tabs__tab .nav-link svg.grid .st0 {
    fill: none;
    stroke: #53545b;
    stroke-width: 1.5;
    stroke-linecap: round;
    stroke-linejoin: round
}

.course__tab-btn .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected {
    border: none !important;
    padding: 0 !important;
    background: none !important
}

.course__tab-btn .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected .nav-link {
    background: var(--bs-primary)
}

.course__tab-btn .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected .nav-link svg.grid .st0 {
    fill: none;
    stroke: #fff
}

.course__tab-btn .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected .nav-link svg .st0 {
    fill: #fff
}

.course__tab-2 .react-tabs__tab-list {
    border: none;
    border-radius: 4px;
    overflow: hidden
}

.course__tab-2 .react-tabs__tab-list .react-tabs__tab {
    padding: 0 !important;
    border: none !important;
    margin: 0 !important;
    bottom: 0 !important;
    width: 25%
}

.course__tab-2 .react-tabs__tab-list .react-tabs__tab:not(:last-child) .nav-link {
    border-right: 1px solid #d2d3dc
}

.course__tab-2 .react-tabs__tab-list .react-tabs__tab:not(:last-child) .nav-link.active {
    border-color: var(--bs-primary)
}

.course__tab-2 .react-tabs__tab-list .react-tabs__tab .nav-link {
    background: #edeef3;
    font-size: 16px;
    font-weight: 600;
    color: #0e1133;
    width: 100%;
    height: 54px;
    line-height: 54px;
    padding: 0;
    text-align: center;
    border-radius: 0
}

.course__tab-2 .react-tabs__tab-list .react-tabs__tab .nav-link i {
    padding-right: 5px
}

@media(max-width:575px) {
    .course__tab-2 .react-tabs__tab-list .react-tabs__tab .nav-link span {
        display: none
    }
}

.course__tab-2 .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected {
    padding: 0 !important;
    border: none !important;
    margin: 0 !important
}

.course__tab-2 .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected .nav-link {
    background: var(--bs-primary);
    color: #fff
}

.course__view {
    margin-left: 12px
}

.course__view .h4, .course__view h4 {
    font-size: 15px;
    font-weight: 500;
    color: #53545b;
    display: inline-block;
    margin-bottom: 0
}

@media(max-width:575px) {
    .course__sort {
        margin-top: 15px
    }
}

.course__sort-inner {
    position: relative
}

.course__sort-inner:before {
    position: absolute;
    content: " ";
    display: inline-block;
    border-bottom: 1px solid #53545b;
    border-right: 1px solid #53545b;
    height: 8px;
    width: 8px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 10px;
    right: 15px
}

.course__sort-inner select {
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    border: none;
    outline: none;
    width: 160px;
    height: 35px;
    line-height: 37px;
    padding: 0 20px;
    font-size: 15px;
    color: #53545b;
    font-weight: 500;
    text-transform: capitalize;
    border-radius: 4px
}

.course__sort-inner select:hover {
    cursor: pointer
}

.course__description .h3, .course__description h3 {
    font-size: 26px;
    margin-bottom: 15px
}

.course__description p {
    font-size: 18px;
    color: #53545b
}

.course__description-list .h4, .course__description-list h4 {
    font-size: 26px;
    margin-bottom: 15px
}

.course__description-list ul li {
    font-size: 18px;
    color: #53545b;
    margin-bottom: 7px
}

.course__description-list ul li i {
    font-size: 16px;
    color: #0e1133;
    padding-right: 5px
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .course__sidebar {
        padding-left: 0
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .course__sidebar {
        padding-left: 0;
        margin-top: 50px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .course__sidebar {
        margin-top: 50px;
        padding-left: 0
    }
}

@media(max-width:575px) {
    .course__sidebar {
        margin-top: 0px;
        padding-left: 0
    }
}

.course__sidebar-widget {
    border-radius: 4px;
    padding: 17px;
    margin-bottom: 3px
}

.course__sidebar-widget-2 {
    padding: 30px;
    -webkit-box-shadow: 0 30px 50px 0 rgba(1, 11, 60, .1);
    box-shadow: 0 30px 50px 0 rgba(1, 11, 60, .1);
    border-radius: 4px
}

.course__sidebar-title {
    font-size: 15px !important;
    margin-bottom: 27px;
    text-transform: capitalize;
    height: max-content;
}

.course__sidebar-search {
    position: relative
}

.course__sidebar-search input {
    width: 100%;
    height: 50px;
    line-height: 58px;
    padding: 0 45px 0 17px;
    background: #f3f4f8;
    border-radius: 4px;
    border: 2px solid #f3f4f8;
    outline: none;
}

.course__sidebar-search input::-webkit-input-placeholder {
    color: #8d8e9a;
    font-weight: 500
}

.course__sidebar-search input::-moz-placeholder {
    color: #8d8e9a;
    font-weight: 500
}

.course__sidebar-search input:-ms-input-placeholder {
    color: #8d8e9a;
    font-weight: 500
}

.course__sidebar-search input::placeholder {
    color: #8d8e9a;
    font-weight: 500
}

.course__sidebar-search input:focus {
    border-color: var(--bs-primary);
    background: #fff
}

.course__sidebar-search button {
    width: 45px;
    height: 100%;
    background: #0095c7;
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    padding: 13px;
    border-radius: 0 5px 5px 0;
}

.course__sidebar-search button svg .st0 {
    fill: #8c8faa
}

.course__sidebar-search button svg .st1 {
    fill: #0e1133
}

.course__sidebar-check input {
    margin: 0;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    display: block;
    width: 18px;
    height: 18px;
    background: #fff;
    border: 1px solid #c7c9d2;
    border-radius: 4px;
    outline: none
}

.course__sidebar-check input:checked {
    position: relative;
    background-color: var(--bs-primary);
    border-color: transparent
}

.course__sidebar-check input:checked:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    content: "";
    position: absolute;
    font-size: 10px;
    color: #fff;
    top: 2px;
    left: 5px;
    height: 10px;
    width: 6px;
    /* border-right: 2px solid #fff;
    border-bottom: 2px solid #fff; */
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.course__sidebar-check input:hover {
    cursor: pointer
}

.course__sidebar-check label {
    font-size: 15px;
    font-weight: 500;
    color: #53545b;
    padding-left: 14px
}

.course__sidebar-check label:hover {
    cursor: pointer;
    color: var(--bs-primary)
}

.course__sm-thumb img {
    width: 60px;
    height: 60px;
    border-radius: 6px
}

.course__sm-content .h5, .course__sm-content h5 {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 600;
    line-height: 1
}

.course__sm-content .h5 a:hover, .course__sm-content h5 a:hover {
    color: var(--bs-primary)
}

.course__sm-price span {
    font-size: 14px;
    color: var(--bs-primary);
    font-weight: 600
}

.course__sm-rating ul li {
    display: inline-block
}

.course__sm-rating ul li a {
    font-size: 10px;
    color: #ff9415;
    line-height: 1
}

.course__instructor .h3, .course__instructor h3 {
    font-size: 26px
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .course__instructor-item {
        margin-right: 40px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .course__instructor-item {
        margin-top: 25px;
        margin-right: 0
    }
}

@media(max-width:575px) {
    .course__instructor-item {
        margin-top: 25px;
        margin-right: 0
    }
}

.course__instructor-thumb img {
    width: 50px;
    height: 50px;
    border-radius: 50%
}

.course__instructor-content .h3, .course__instructor-content h3 {
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1
}

.course__instructor-content p {
    font-size: 14px;
    color: #53545b;
    margin-bottom: 0
}

.course__share .h3, .course__share h3 {
    font-size: 20px
}

.course__share ul li {
    display: inline-block;
    margin-right: 10px
}

.course__share ul li a {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 44px;
    text-align: center;
    font-size: 13px;
    color: #0e1133;
    background: #f3f4f8;
    border-radius: 4px
}

.course__share ul li a.fb {
    color: #285da1;
    background: rgba(40, 93, 161, .1)
}

.course__share ul li a.fb:hover {
    color: #fff;
    background: #285da1
}

.course__share ul li a.tw {
    color: #03a9f4;
    background: rgba(3, 169, 244, .1)
}

.course__share ul li a.tw:hover {
    color: #fff;
    background: #03a9f4
}

.course__share ul li a.pin {
    color: #d8163f;
    background: rgba(216, 22, 63, .1)
}

.course__share ul li a.pin:hover {
    color: #fff;
    background: #d8163f
}

.course__curriculum-content {
    padding: 13px 30px;
    border-bottom: 1px solid #edeef2;
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.course__curriculum-content:hover {
    background: #f6f7fb
}

.course__curriculum-info svg {
    width: 16px;
    height: 16px;
    margin-right: 7px
}

.course__curriculum-info svg .st0 {
    fill: none;
    stroke: #6d6e75;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round
}

.course__curriculum-info .h3, .course__curriculum-info h3 {
    display: inline-block;
    font-size: 16px;
    color: #53545b;
    font-weight: 400;
    margin-bottom: 0
}

.course__curriculum-info .h3 span, .course__curriculum-info h3 span {
    color: #0e1133;
    font-weight: 500
}

.course__curriculum-meta span.time {
    font-size: 16px;
    color: #53545b
}

.course__curriculum-meta span.time i {
    margin-right: 7px
}

.course__curriculum-meta span.question {
    display: inline-block;
    height: 24px;
    line-height: 24px;
    color: #fff;
    padding: 0 11px;
    border-radius: 4px;
    background: #f2277e;
    margin-left: 20px
}

.course__curriculum .accordion-item {
    padding: 0
}

.course__curriculum .accordion-item .accordion-button {
    outline: none;
    border: 1px solid #edeef2;
    border-radius: 4px 4px 0 0;
    background: #f3f4f8;
    font-size: 20px;
    font-weight: 700;
    color: #0e1133;
    padding-top: 18px;
    padding-left: 30px;
    padding-right: 30px
}

.course__curriculum .accordion-item .accordion-button.collapsed {
    border-radius: 4px
}

.course__curriculum .accordion-item .accordion-button:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.course__curriculum .accordion-item .accordion-body {
    padding: 0
}

.course__review-rating-info {
    padding: 62px 0;
    border-right: 3px solid #fff;
    border-radius: 4px 0 0 4px
}

@media(max-width:575px) {
    .course__review-rating-info {
        border-right: 0;
        border-bottom: 3px solid #fff;
        border-radius: 4px 4px 0 0
    }
}

.course__review-rating-info .h5, .course__review-rating-info h5 {
    font-size: 100px;
    line-height: 70px;
    font-weight: 500;
    margin-bottom: 8px
}

.course__review-rating-info ul li {
    display: inline-block
}

.course__review-rating-info ul li a {
    color: #ff9415
}

.course__review-rating-info p {
    color: #53545b;
    margin-bottom: 0
}

.course__review-progress {
    width: -webkit-calc(100% - 60px - 60px);
    width: calc(100% - 60px - 60px);
    height: 4px;
    overflow: hidden;
    background: #d8dae3;
    border-radius: 2px
}

.course__review-progress .single-progress {
    background: var(--bs-primary);
    height: 100%
}

.course__review-details {
    padding: 35px 65px 10px 35px;
    border-radius: 0 4px 4px 0
}

.course__review-details>.h5, .course__review-details>h5 {
    font-size: 16px;
    font-weight: 500;
    color: #0e1133;
    margin-bottom: 20px
}

@media(max-width:575px) {
    .course__review-details {
        border-radius: 0 0 4px 4px
    }
}

.course__review-item {
    margin-bottom: 5px
}

.course__review-text span {
    font-size: 16px;
    color: #53545b
}

.course__review-percent {
    width: 40px
}

.course__review-percent .h5, .course__review-percent h5 {
    font-size: 16px;
    font-weight: 400;
    color: #53545b;
    margin-bottom: 0
}

.course__comment-box {
    background: #f3f4f8;
    border-radius: 4px;
    padding: 25px 40px 25px 30px;
    margin-bottom: 10px
}

.course__comment-box p {
    color: #53545b;
    margin-bottom: 0
}

.course__comment-thumb img {
    width: 50px;
    height: 50px;
    border-radius: 50%
}

.course__comment-info {
    margin-bottom: 5px
}

.course__comment-info .h4, .course__comment-info h4 {
    font-size: 16px;
    line-height: 1;
    margin-bottom: 0
}

.course__comment-info span {
    font-size: 14px;
    color: #53545b
}

.course__comment-rating ul li {
    display: inline-block
}

.course__comment-rating ul li a {
    font-size: 12px;
    color: #ff9415
}

.course__comment-rating ul li a.no-rating {
    color: #b8b9bf
}

.course__form .h3, .course__form h3 {
    font-size: 26px;
    margin-bottom: 40px
}

.course__form-input input, .course__form-input textarea {
    width: 100%;
    height: 56px;
    line-height: 54px;
    border: 2px solid #f3f4f8;
    outline: none;
    background: #f3f4f8;
    padding: 0 22px;
    font-size: 15px;
    border-radius: 4px;
    margin-bottom: 20px
}

.course__form-input input:focus, .course__form-input textarea:focus {
    border-color: var(--bs-primary);
    background: #fff
}

.course__form-input input::-webkit-input-placeholder, .course__form-input textarea::-webkit-input-placeholder {
    color: #53545b
}

.course__form-input input::-moz-placeholder, .course__form-input textarea::-moz-placeholder {
    color: #53545b
}

.course__form-input input:-ms-input-placeholder, .course__form-input textarea:-ms-input-placeholder {
    color: #53545b
}

.course__form-input input::placeholder, .course__form-input textarea::placeholder {
    color: #53545b
}

.course__form-input textarea {
    height: 150px;
    resize: none;
    padding: 20px;
    line-height: 1.1
}

.course__form-rating {
    margin-bottom: 10px
}

.course__form-rating span {
    color: #53545b
}

.course__form-rating ul, .course__form-rating ul li {
    display: inline-block
}

.course__form-rating ul li a {
    font-size: 14px;
    color: #ff9415
}

.course__form-rating ul li a.no-rating {
    color: #b8b9bf
}

.course__form-btn button {
    text-transform: capitalize
}

.course__member-item {
    padding: 25px 30px;
    background: #f3f4f8;
    border-radius: 4px;
    margin-bottom: 10px
}

.course__member-thumb {
    position: relative
}

.course__member-thumb:after {
    position: absolute;
    content: "";
    right: 0;
    top: -5px;
    width: 1px;
    height: 80px;
    background: #dddee4
}

@media(max-width:575px) {
    .course__member-thumb:after {
        display: none
    }
}

.course__member-thumb img {
    width: 70px;
    height: 70px;
    border-radius: 50%
}

.course__member-name .h5, .course__member-name h5 {
    font-size: 16px;
    line-height: 1;
    margin-bottom: 0
}

.course__member-name span {
    font-size: 15px;
    color: #53545b
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .course__member-info {
        padding-left: 0
    }
}

@media(max-width:575px) {
    .course__member-info {
        margin-top: 25px;
        padding-left: 0
    }
}

.course__member-info .h5, .course__member-info h5 {
    font-size: 16px;
    line-height: 1;
    margin-bottom: 0
}

.course__member-info span {
    font-size: 15px;
    color: #53545b
}

.course__slider .swiper-pagination {
    bottom: 20px
}

.course__slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background: #d6d7de;
    border-radius: 50%;
    margin: 0 5px;
    opacity: 1
}

.course__slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: var(--bs-primary)
}

.course__video-thumb {
    position: relative;
    overflow: hidden;
    border-radius: 4px
}

.course__video-thumb:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 3, 32, .5)
}

.course__video-play {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1
}

.course__video-price .h5, .course__video-price h5 {
    font-size: 26px;
    display: inline-block;
    margin-bottom: 0
}

.course__video-price .h5 span, .course__video-price h5 span {
    font-size: 20px;
    font-weight: 600
}

.course__video-price .old-price.h5, .course__video-price h5.old-price {
    text-decoration: line-through;
    color: #6d6e75;
    font-weight: 500;
    font-size: 16px;
    padding-left: 10px
}

.course__video-discount span {
    display: inline-block;
    font-size: 14px;
    height: 24px;
    line-height: 24px;
    padding: 0 12px;
    color: #ff4690;
    background: rgba(255, 70, 144, .08);
    font-weight: 600;
    border-radius: 4px
}

.course__video-content ul li i {
    -webkit-transform: translateY(3px);
    transform: translateY(3px);
    display: inline-block;
    color: var(--bs-primary)
}

.course__video-content ul li:not(:last-child) {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #eef0f6
}

.course__video-icon svg {
    width: 16px;
    height: 20px;
    margin-right: 12px
}

.course__video-icon svg .st0 {
    fill: none;
    stroke: var(--bs-primary);
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 2.6667
}

.course__video-info .h5, .course__video-info h5 {
    margin-bottom: 0;
    font-weight: 400;
    color: #6d6e75;
    font-size: 15px;
    line-height: 1
}

.course__video-info .h5 span, .course__video-info h5 span {
    font-weight: 500;
    color: #0e1133;
    padding-right: 5px
}

.course__payment .h3, .course__payment h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px
}

.course__enroll-btn i {
    padding-left: 4px;
    font-size: 12px;
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
    display: inline-block
}

.course__shape img {
    position: absolute
}

.course__shape img.course-dot {
    right: -30px;
    top: 150px;
    z-index: -1
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .course__shape img.course-dot {
        right: -20px
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .course__shape img.course-dot {
        right: -20px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .course__shape img.course-dot {
        right: -10px
    }
}

@media(max-width:575px) {
    .course__shape img.course-dot {
        right: -10px
    }
}

.course__menu .react-tabs__tab-list {
    border-bottom: 0 !important;
    margin: 0 !important;
    padding: 0 !important
}

.course__menu .react-tabs__tab--selected {
    background: none !important;
    border: none !important;
    color: inherit !important;
    border-radius: 0 !important
}

.course__menu .react-tabs__tab {
    border: none !important;
    padding: 0 13px !important
}

.course__menu .react-tabs__tab:last-child {
    padding-right: 0 !important
}

.course__curriculum .accordion__button:before {
    display: none
}

.course__curriculum .accordion__panel {
    padding: 0;
    border: 1px solid #edeef2;
    border-bottom: 0
}

.course__curriculum .accordion-button:not(.collapsed) {
    font-size: 20px;
    font-weight: 700;
    color: #0e1133;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #f3f4f8
}

.course__curriculum .accordion__item {
    margin-bottom: 50px
}

.course__curriculum .accordion__button {
    padding: 0
}

.course__curriculum .accordion-button {
    padding: 19px 30px 17px
}

.course__curriculum .accordion__item+.accordion__item {
    border-top: 0
}

.course__curriculum .accordion-button:not(.collapsed):after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg)
}

.w-30 {
    width: 30%
}

.w-100 {
    width: 100%
}

.w-none {
    width: 0
}

@media(max-width:575px) {
    .course__bottom.d-sm-flex.justify-content-between.align-items-center {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: start;
        justify-content: start;
        -webkit-align-items: start !important;
        align-items: start !important;
        text-align: left;
        width: 100%
    }
}

.events__shape img.events-1-shape {
    position: absolute;
    top: 60%;
    left: 53%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .events__shape img.events-1-shape {
        left: 50%;
        width: 90%
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .events__shape img.events-1-shape {
        left: 50%;
        width: 90%
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .events__shape img.events-1-shape {
        left: 50%;
        width: 90%
    }
}

@media(max-width:575px) {
    .events__shape img.events-1-shape {
        left: 50%;
        width: 90%
    }
}

.events__item {
    position: relative;
    z-index: 1
}

.events__item-inner {
    padding: 28px 50px;
    border-radius: 6px;
    -webkit-box-shadow: 0 30px 50px 0 rgba(1, 11, 60, .1);
    box-shadow: 0 30px 50px 0 rgba(1, 11, 60, .1)
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .events__item-inner {
        padding-left: 20px;
        padding-right: 20px
    }
}

@media(max-width:575px) {
    .events__item-inner {
        padding-left: 30px;
        padding-right: 30px
    }
}

.events__item:after {
    position: absolute;
    content: "";
    top: auto;
    bottom: 0;
    left: -3px;
    width: 10%;
    height: 0%;
    background: var(--bs-primary);
    border-radius: 6px;
    z-index: -1
}

.events__item.active:after, .events__item:hover:after {
    top: 0;
    bottom: auto;
    height: 100%
}

.events__title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0
}

.events__title a:hover {
    color: var(--bs-primary)
}

.events__meta {
    margin-bottom: 5px
}

.events__meta span {
    display: inline-block;
    position: relative;
    padding-right: 7px;
    margin-right: 7px
}

.events__meta span:not(:last-child):after {
    position: absolute;
    content: "";
    right: 0;
    top: 5px;
    width: 1px;
    height: 14px;
    background: #6d6e75
}

@media(max-width:575px) {
    .events__more {
        margin-top: 15px
    }
}

.events__more .link-btn {
    color: #0e1133
}

.events__more .link-btn:hover {
    color: var(--bs-primary)
}

.events__details .h3, .events__details h3 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 15px
}

.events__details p {
    font-size: 18px;
    line-height: 28px;
    color: #53545b
}

.events__allow .h3, .events__allow h3 {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 15px
}

.events__allow ul li {
    font-size: 16px;
    color: #0e1133
}

.events__allow ul li:not(:last-child) {
    margin-bottom: 5px
}

.events__allow ul li i {
    display: inline-block;
    width: 18px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    font-size: 10px;
    font-weight: 500;
    color: #30a820;
    background: rgba(48, 168, 32, .1);
    border-radius: 50%;
    margin-right: 10px
}

.events__tag {
    border-top: 1px solid #e4e6ef;
    padding-top: 25px
}

.events__tag span {
    font-size: 16px;
    color: var(--bs-primary);
    margin-right: 7px
}

.events__tag a {
    font-size: 16px;
    color: #53545b;
    font-weight: 600
}

.events__tag a:hover {
    color: var(--bs-primary)
}

.events__info-price .h5, .events__info-price h5 {
    font-size: 26px;
    display: inline-block;
    margin-bottom: 0
}

.events__info-price .h5 span, .events__info-price h5 span {
    font-size: 20px;
    font-weight: 600
}

.events__info-price .old-price.h5, .events__info-price h5.old-price {
    text-decoration: line-through;
    color: #6d6e75;
    font-weight: 500;
    font-size: 16px;
    padding-left: 10px
}

.events__info-discount span {
    display: inline-block;
    font-size: 14px;
    height: 24px;
    line-height: 24px;
    padding: 0 12px;
    color: #ff4690;
    background: rgba(255, 70, 144, .08);
    font-weight: 600;
    border-radius: 4px
}

.events__info-content ul li:not(:last-child) {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #eef0f6
}

.events__info-content ul li .events__info-icon i {
    color: var(--bs-primary);
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
    display: inline-block
}

.events__info-icon svg {
    width: 16px;
    height: 20px;
    margin-right: 12px
}

.events__info-icon svg .st0 {
    fill: none;
    stroke: var(--bs-primary);
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 2.6667
}

.events__info-item .h5, .events__info-item h5 {
    margin-bottom: 0;
    font-weight: 400;
    color: #6d6e75;
    font-size: 15px;
    line-height: 1
}

.events__info-item .h5 span, .events__info-item h5 span {
    font-weight: 500;
    color: #0e1133;
    padding-right: 5px
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .events__sidebar {
        padding-left: 0;
        margin-top: 50px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .events__sidebar {
        padding-left: 0;
        margin-top: 50px
    }
}

@media(max-width:575px) {
    .events__sidebar {
        padding-left: 0;
        margin-top: 50px
    }
}

.events__sidebar-widget {
    position: relative;
    padding: 30px;
    -webkit-box-shadow: 0 30px 50px 0 rgba(1, 11, 60, .1);
    box-shadow: 0 30px 50px 0 rgba(1, 11, 60, .1);
    border-radius: 4px
}

.events__sidebar-shape img {
    position: absolute;
    z-index: -1
}

.events__sidebar-shape img.events-sidebar-img-2 {
    top: 40px;
    left: -25px
}

.events__sidebar-shape img.events-sidebar-img-3 {
    bottom: 90px;
    right: -30px
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .events__sidebar-shape img.events-sidebar-img-3 {
        right: -15px
    }
}

@media(max-width:575px) {
    .events__sidebar-shape img.events-sidebar-img-3 {
        right: -10px
    }
}

.events__join-btn a {
    background: #ff4690
}

.events__join-btn a i {
    padding-left: 5px
}

.events__social .h4, .events__social h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
    margin-right: 10px
}

.events__social ul li {
    display: inline-block;
    margin-left: 5px
}

.events__social ul li a {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 44px;
    text-align: center;
    font-size: 13px;
    color: #0e1133;
    background: #f3f4f8;
    border-radius: 4px
}

.events__social ul li a.fb {
    color: #285da1;
    background: rgba(40, 93, 161, .1)
}

.events__social ul li a.fb:hover {
    color: #fff;
    background: #285da1
}

.events__social ul li a.tw {
    color: #03a9f4;
    background: rgba(3, 169, 244, .1)
}

.events__social ul li a.tw:hover {
    color: #fff;
    background: #03a9f4
}

.events__social ul li a.pin {
    color: #d8163f;
    background: rgba(216, 22, 63, .1)
}

.events__social ul li a.pin:hover {
    color: #fff;
    background: #d8163f
}

.events__sponsor-title {
    font-size: 20px;
    margin-bottom: 20px
}

.events__sponsor-info .h3, .events__sponsor-info h3 {
    font-size: 16px
}

.events__sponsor-info>.h4, .events__sponsor-info>h4 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px
}

.events__sponsor-info>.h4 span, .events__sponsor-info>h4 span {
    font-weight: 400;
    color: #6d6e75
}

@media only screen and (min-width:992px)and (max-width:1199px), only screen and (min-width:1200px)and (max-width:1399px) {
    .events__sidebar.pl-70 {
        padding-left: 0
    }
}

.price__item {
    padding: 45px 50px 60px;
    border-radius: 6px
}

.price__head .h3, .price__head h3 {
    font-size: 26px;
    margin-bottom: 5px
}

.price__head p {
    color: #53545b;
    margin-bottom: 17px
}

.price__tag {
    padding-bottom: 13px;
    border-bottom: 1px solid #e0e3ed
}

.price__tag .h4, .price__tag h4 {
    font-size: 50px;
    margin-bottom: 0
}

.price__tag .h4 span, .price__tag h4 span {
    font-size: 16px;
    font-weight: 400
}

.price__offer {
    position: absolute;
    top: 20px;
    right: -15px;
    z-index: 1
}

@media(max-width:575px) {
    .price__offer {
        right: 0
    }
}

.price__offer span {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    text-align: center;
    color: #fff;
    background: #f2277e;
    padding: 0 10px;
    border-radius: 4px 4px 0 4px;
    position: relative
}

@media(max-width:575px) {
    .price__offer span {
        border-radius: 4px
    }
}

.price__offer span:after {
    position: absolute;
    content: "";
    bottom: -10px;
    right: 0;
    width: 0;
    height: 0;
    border-left: 15px solid #960443;
    border-top: 0 solid transparent;
    border-bottom: 10px solid transparent
}

@media(max-width:575px) {
    .price__offer span:after {
        display: none
    }
}

.price__features ul li {
    font-size: 16px;
    font-weight: 500;
    color: #0e1133;
    position: relative;
    padding-left: 30px
}

.price__features ul li:not(:last-child) {
    margin-bottom: 10px
}

.price__features ul li i {
    position: absolute;
    left: 0;
    top: 3px;
    display: inline-block;
    color: #30a820;
    font-size: 8px;
    height: 18px;
    width: 18px;
    line-height: 19px;
    text-align: center;
    border-radius: 50%;
    background: rgba(48, 168, 32, .1)
}

.price__features ul li:hover {
    color: #30a820
}

.price__features ul li:hover i {
    background: #30a820;
    color: #fff
}

.price__tab-btn .react-tabs__tab-list {
    border: none !important
}

.price__tab-btn .react-tabs__tab-list .react-tabs__tab .nav-link {
    background: transparent;
    padding: 0 15px;
    font-size: 14px;
    color: #121317;
    font-weight: 500;
    height: 36px;
    line-height: 34px;
    border: 2px solid #edeef3;
    border-radius: 4px;
    text-transform: capitalize
}

.price__tab-btn .react-tabs__tab-list .react-tabs__tab:first-child .nav-link {
    border-right: 0;
    border-radius: 4px 0 0 4px
}

.price__tab-btn .react-tabs__tab-list .react-tabs__tab:last-child .nav-link {
    border-left: 0;
    border-radius: 0 4px 4px 0
}

.price__tab-btn .react-tabs__tab-list .react-tabs__tab--selected .nav-link {
    background: var(--bs-primary);
    color: #fff;
    border-color: var(--bs-primary)
}

.price__tab-btn .react-tabs__tab--selected {
    padding: 0 !important;
    border: none !important;
    color: inherit !important;
    border-radius: 0
}

.price__tab-btn .react-tabs__tab {
    bottom: 0 !important;
    padding: 0 !important;
    border: none !important
}

.price__tab-btn .nav-tabs {
    border-bottom: 0
}

.cta__inner {
    padding: 50px 100px 53px 70px;
    border-radius: 10px;
    -webkit-box-shadow: 0 30px 60px 0 rgba(0, 4, 48, .3);
    box-shadow: 0 30px 60px 0 rgba(0, 4, 48, .3);
    position: relative;
    z-index: 1
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .cta__inner {
        padding-left: 40px;
        padding-right: 40px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .cta__inner {
        padding-left: 40px;
        padding-right: 40px
    }
}

@media(max-width:575px) {
    .cta__inner {
        padding-left: 40px;
        padding-right: 40px
    }
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .cta__inner-2 {
        padding-right: 50px;
        padding-left: 50px
    }
}

.cta__inner-3 {
    padding-left: 100px;
    padding-right: 100px;
    border-radius: 6px
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .cta__inner-3 {
        padding-left: 80px;
        padding-right: 80px
    }
}

@media(max-width:575px) {
    .cta__inner-3 {
        padding-left: 25px;
        padding-right: 25px
    }
}

.cta__shape img {
    position: absolute;
    bottom: -48px;
    right: -148px;
    filter: invert(0.9);
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .cta__shape img {
        right: -80%
    }
}

@media(max-width:575px) {
    .cta__shape img {
        right: -120%
    }
}

.cta__shape-3 img {
    position: absolute
}

.cta__shape-3 img.cta-2 {
    left: -117px;
    top: -77px
}

.cta__shape-3 img.cta-3 {
    right: -16px;
    top: 100px
}

.cta__title {
    position: relative;
    z-index: 1;
    font-size: 46px;
    color: #fff;
    margin-bottom: 0
}

@media only screen and (min-width:1200px)and (max-width:1399px) {
    .cta__title {
        font-size: 45px
    }
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .cta__title {
        font-size: 40px
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .cta__title {
        font-size: 31px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .cta__title {
        font-size: 35px;
        margin-bottom: 10px
    }
}

@media(max-width:575px) {
    .cta__title {
        font-size: 25px;
        margin-bottom: 10px
    }
}

.cta__title-2 {
    font-size: 40px;
    margin-bottom: 33px
}

.cta__content span {
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    display: inline-block;
    padding: 0 10px;
    height: 22px;
    line-height: 22px;
    border-radius: 4px;
    background: #f2277e;
    margin-bottom: 14px
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .cta__more {
        margin-top: 30px
    }
}

@media(max-width:575px) {
    .cta__more {
        margin-top: 30px
    }
}

.cta__apps a {
    display: inline-block;
    height: 54px;
    line-height: 52px;
    border: 2px solid rgba(255, 255, 255, .2);
    border-radius: 4px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    padding: 0 26px 0 20px
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .cta__apps a {
        margin-bottom: 10px
    }
}

.cta__apps a i {
    margin-right: 2px
}

.cta__apps a.active, .cta__apps a:hover {
    color: #0e1133;
    border-color: #fff;
    background: #fff;
    -webkit-box-shadow: 0 16px 24px 0 rgba(107, 25, 0, .3);
    box-shadow: 0 16px 24px 0 rgba(107, 25, 0, .3)
}

@media(max-width:575px) {
    .cta__apps a {
        margin-bottom: 5px
    }
}

.cta__form-inner {
    margin-bottom: 13px
}

.cta__form-inner input {
    width: 325px;
    height: 56px;
    line-height: 56px;
    padding: 2px 22px 0;
    font-size: 15px;
    outline: none;
    background: #fff;
    border: 2px solid #fff;
    border-radius: 4px;
    margin-right: 20px
}

@media only screen and (min-width:1200px)and (max-width:1399px) {
    .cta__form-inner input {
        width: 290px
    }
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .cta__form-inner input {
        margin-bottom: 20px
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .cta__form-inner input {
        margin-bottom: 20px;
        width: 100%
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .cta__form-inner input {
        margin-bottom: 20px;
        width: 100%
    }
}

@media(max-width:575px) {
    .cta__form-inner input {
        margin-bottom: 20px;
        width: 100%
    }
}

.cta__form-inner input::-webkit-input-placeholder {
    color: #6d6e75
}

.cta__form-inner input::-moz-placeholder {
    color: #6d6e75
}

.cta__form-inner input:-ms-input-placeholder {
    color: #6d6e75
}

.cta__form-inner input::placeholder {
    color: #6d6e75
}

.cta__form-inner input:focus {
    border-color: var(--bs-primary)
}

.cta__form-inner button {
    height: 56px;
    line-height: 56px
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .cta__form-inner button {
        width: 100%
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .cta__form-inner button {
        width: 100%
    }
}

@media(max-width:575px) {
    .cta__form-inner button {
        width: 100%
    }
}

.cta__agree {
    padding-left: 5px
}

.cta__agree input {
    margin: 0;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    display: block;
    width: 16px;
    height: 16px;
    background: #fff;
    border: 1px solid #b9bac1;
    outline: none;
    border-radius: 4px
}

@media(max-width:360px) {
    .cta__agree input {
        width: 21px
    }
}

.cta__agree input:checked {
    position: relative;
    background-color: var(--bs-primary);
    border-color: transparent
}

.cta__agree input:checked:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    content: "";
    position: absolute;
    font-size: 10px;
    color: #fff;
    top: 1px;
    left: 4px;
    height: 10px;
    width: 6px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.cta__agree input:hover {
    cursor: pointer
}

.cta__agree label {
    padding-left: 8px;
    color: #53545b
}

@media(max-width:575px) {
    .cta__agree label {
        font-size: 14px
    }
}

.cta__agree label a {
    color: #0e1133;
    font-weight: 600
}

.cta__agree label a:hover {
    color: var(--bs-primary)
}

.cta__agree label:hover {
    cursor: pointer
}

.product__sm {
    padding: 20px
}

.product__sm ul li {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 20px
}

.product__sm-thumb img {
    width: 85px;
    height: 100px
}

.product__sm-content .h5, .product__sm-content h5 {
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 500
}

.product__sm-content .h5 a:hover, .product__sm-content h5 a:hover {
    color: var(--bs-primary)
}

.product__sm-price {
    color: var(--bs-primary);
    font-weight: 500
}

.product__sm-price-wrapper span {
    font-size: 14px
}

.cartmini__area {
    position: relative
}

.cartmini__wrapper {
    position: fixed;
    right: -360px;
    top: 0;
    width: 340px;
    height: 100%;
    background: #fff;
    -webkit-transition: all .6s cubic-bezier(.175, .885, .32, 1.275);
    transition: all .6s cubic-bezier(.175, .885, .32, 1.275);
    -webkit-box-shadow: -5px 0 20px -5px rgba(0, 0, 0, .5);
    box-shadow: -5px 0 20px -5px rgba(0, 0, 0, .5);
    z-index: 9999;
    overflow: hidden
}

.cartmini__wrapper.opened {
    right: 0
}

.cartmini__title {
    padding: 20px;
    border-bottom: 1px solid #eef0f6;
    -webkit-box-shadow: 0 0 10px 0 rgba(129, 129, 129, .2);
    box-shadow: 0 0 10px 0 rgba(129, 129, 129, .2)
}

.cartmini__title .h4, .cartmini__title h4 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0
}

.cartmini__close {
    position: absolute;
    top: 17px;
    right: 20px
}

.cartmini__close-btn {
    background: transparent;
    color: #0e1133;
    font-size: 22px
}

.cartmini__close-btn:hover {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.cartmini__widget {
    overflow-y: scroll;
    height: 100%
}

.cartmini__inner ul li {
    position: relative;
    display: -webkit-flex;
    display: flex;
    padding: 20px 35px 20px 20px;
    border-bottom: 1px solid rgba(129, 129, 129, .2);
    -webkit-transition: background-color .3s;
    transition: background-color .3s
}

.cartmini__inner ul li:hover {
    background: #f9f9f9
}

.cartmini__thumb {
    margin-right: 15px
}

.cartmini__thumb img {
    width: 70px;
    height: 90px
}

.cartmini__content .h5, .cartmini__content h5 {
    font-size: 13px;
    margin-bottom: 12px;
    font-weight: 500
}

.cartmini__content .h5 a:hover, .cartmini__content h5 a:hover {
    color: var(--bs-primary)
}

.cartmini__del {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    color: #0e1133;
    font-size: 12px;
    background: transparent;
    border-radius: 50%
}

.cartmini__del:hover {
    background: #fff
}

.cartmini__checkout {
    padding: 20px 20px 85px;
    width: 100%;
    background: #fff;
    border-top: 2px solid #eef0f6
}

.cartmini__checkout-title .h4, .cartmini__checkout-title h4 {
    font-size: 18px;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 0
}

.cartmini__checkout-title span {
    float: right;
    font-size: 20px;
    color: #0e1133;
    font-weight: 600;
    color: var(--bs-primary)
}

@media(max-width:575px), only screen and (min-width:576px)and (max-width:767px) {
    .cartmini__wrapper {
        width: 300px
    }
}

.cart-minus, .cart-plus {
    width: 25px;
    height: 30px;
    border: 2px solid #eef0f6;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-size: 14px
}

.cart-minus:hover, .cart-plus:hover {
    cursor: pointer;
    color: #fff;
    background: var(--bs-primary)
}

.cart-input {
    height: 30px;
    width: 32px;
    text-align: center;
    font-size: 14px;
    border: 2px solid #eef0f6;
    border-right: none;
    border-left: none;
    display: inline-block;
    vertical-align: middle;
    margin: 0 -3px;
    padding-bottom: 0
}

.cart-input:focus {
    outline: none
}

.cart-area .table-content table {
    background: #fff;
    border-radius: 0;
    border-color: #eaedff;
    border-style: solid;
    border-width: 1px 0 0 1px;
    text-align: center;
    width: 100%;
    margin-bottom: 0
}

.cart-area .table-content .product-quantity {
    float: none
}

.cart-area .table-content table td.product-name {
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize
}

.cart-area .table>:not(:last-child)>:last-child>* {
    border-bottom-color: #eaedff
}

.cart-area .table-content table td.product-name a:hover {
    color: #0e1133
}

.cart-area .table-content table td {
    border-top: none;
    padding: 20px 10px;
    vertical-align: middle;
    font-size: 16px
}

.cart-area .table-content table td, .cart-area .table-content table th {
    border-bottom: 1px solid #eaedff;
    border-right: 1px solid #eaedff
}

.cart-area .table td, .cart-area .table th {
    border-top: 1px solid #eaedff
}

.cart-area .table-content table td.product-subtotal {
    font-size: 16px
}

.cart-area .table-content table td .cart-plus-minus {
    float: none;
    margin: 0 auto
}

.cart-area .coupon-all {
    margin-top: 50px
}

.cart-area .coupon {
    float: left
}

@media(max-width:767px) {
    .cart-area .coupon {
        float: none
    }
}

.cart-area #coupon_code {
    height: 50px;
    border: 2px solid #eaedff;
    padding: 0 15px;
    margin-right: 10px;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.cart-area #coupon_code:focus {
    border-color: #0e1133
}

@media(max-width:767px) {
    .cart-area #coupon_code {
        margin-bottom: 15px
    }
}

.cart-area .coupon2 {
    float: right
}

@media(max-width:767px) {
    .cart-area .coupon2 {
        float: none;
        margin-top: 15px
    }
}

.cart-area .cart-page-total {
    padding-top: 50px
}

.cart-area .cart-page-total>.h2, .cart-area .cart-page-total>h2 {
    font-size: 25px;
    margin-bottom: 20px;
    text-transform: capitalize
}

.cart-area .cart-page-total>ul {
    border: 1px solid #eaedff
}

.cart-area .cart-page-total>ul>li {
    list-style: none;
    font-size: 15px;
    color: #6f7172;
    padding: 10px 30px;
    border-bottom: 1px solid #eaedff;
    font-weight: 400
}

.cart-area .cart-page-total ul>li>span {
    float: right
}

.cart-area .cart-page-total li:last-child {
    border-bottom: 0
}

.cart-area td.product-thumbnail img {
    width: 125px
}

.cart-area .product-quantity-form {
    margin: auto;
    width: 122px;
    height: 58px;
    border: 2px solid #dadada;
    text-align: center;
    position: relative;
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.cart-area .product-quantity-form:hover {
    border-color: #0e1133
}

.cart-area .product-quantity-form p {
    display: inline-block;
    font-size: 18px;
    line-height: 1;
    margin: 18px 0 0
}

.cart-area .cart-minus, .cart-area .cart-plus {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 45px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-size: 14px;
    background: transparent;
    border: none;
    outline: none
}

.cart-area .cart-minus:hover, .cart-area .cart-plus:hover {
    cursor: pointer;
    color: #0e1133;
    background: transparent
}

.cart-area .cart-plus {
    left: auto;
    right: 0
}

.cart-area .cart-input {
    height: 58px;
    width: 32px;
    text-align: center;
    font-size: 14px;
    border: none;
    display: inline-block;
    vertical-align: middle;
    margin: 0 -3px;
    padding-bottom: 4px;
    background: transparent
}

.cart-area .cart-input:focus {
    outline: none
}

.coupon-accordion .h3, .coupon-accordion h3 {
    background-color: #f6f6f6;
    border-top: 3px solid #0e1133;
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 25px;
    padding: 1em 2em 1em 3.5em;
    position: relative;
    width: auto
}

.coupon-accordion .h3:before, .coupon-accordion h3:before {
    content: "";
    left: 15px;
    top: 13px;
    position: absolute;
    color: #6f7172;
    font-family: Font Awesome\ 5 Pro;
    font-weight: 700
}

.coupon-accordion span {
    color: #6f7172;
    cursor: pointer;
    -webkit-transition: .3s;
    transition: .3s;
    font-weight: 500
}

.coupon-accordion span:hover, p.lost-password a:hover {
    color: #0e1133
}

.coupon-content {
    border: 1px solid #eaedff;
    display: none;
    margin-bottom: 20px;
    padding: 30px
}

.coupon-info p.coupon-text {
    margin-bottom: 15px
}

.coupon-info p {
    margin-bottom: 0
}

.coupon-info p.form-row-first label, .coupon-info p.form-row-last label {
    display: block;
    color: #6f7172
}

.coupon-info p.form-row-first label span.required, .coupon-info p.form-row-last label span.required {
    color: red;
    font-weight: 700
}

.coupon-info p.form-row-first input, .coupon-info p.form-row-last input {
    border: 1px solid #eaedff;
    height: 45px;
    margin: 0 0 14px;
    max-width: 100%;
    padding: 0 0 0 10px;
    width: 100%;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.coupon-info p.form-row-first input:focus, .coupon-info p.form-row-last input:focus {
    border-color: #0e1133
}

.coupon-info p.form-row input[type=submit]:hover, p.checkout-coupon input[type=submit]:hover {
    background: #3e976c none repeat scroll 0 0
}

.coupon-info p.form-row input[type=checkbox] {
    position: relative;
    top: 2px
}

.form-row>label {
    margin-top: 15px;
    margin-left: 15px;
    color: #6f7172
}

.buttons-cart a, .buttons-cart input, .coupon-info p.form-row input[type=submit], .coupon input[type=submit] {
    background: #252525 none repeat scroll 0 0;
    border: none;
    color: #fff;
    display: inline-block;
    float: left;
    font-size: 12px;
    font-weight: 700;
    height: 40px;
    line-height: 40px;
    margin-right: 15px;
    padding: 0 15px;
    text-transform: uppercase;
    -webkit-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

p.lost-password {
    margin-top: 15px
}

p.lost-password a {
    color: #6f6f6f
}

p.checkout-coupon input[type=text] {
    height: 45px;
    padding: 0 15px;
    width: 100%;
    border: 1px solid #eaedff;
    margin-bottom: 15px;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

p.checkout-coupon input[type=text]:focus {
    border-color: #0e1133
}

.coupon-checkout-content {
    display: none
}

.checkbox-form .h3, .checkbox-form h3 {
    border-bottom: 1px solid #eaedff;
    font-size: 20px;
    margin: 0 0 20px;
    padding-bottom: 10px;
    width: 100%
}

.country-select {
    margin-bottom: 30px;
    position: relative
}

.country-select select {
    width: 100%;
    background-color: transparent;
    border: 1px solid #eaedff;
    padding: 0 10px;
    height: 50px
}

.checkout-form-list label, .country-select label {
    color: #6f7172;
    display: block;
    margin: 0 0 5px
}

.checkout-form-list label span.required, .country-select label span.required {
    color: red
}

.country-select .nice-select {
    border: 1px solid #eaedff;
    height: 45px;
    padding-left: 10px;
    width: 100%;
    color: #6f7172;
    margin-bottom: 20px
}

.country-select .nice-select .list {
    width: 100%
}

.country-select .nice-select:after {
    border-top: 4px solid #6f7172;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent
}

.checkout-form-list {
    margin-bottom: 30px
}

.checkout-form-list label {
    color: #6f7172
}

.checkout-form-list input[type=email], .checkout-form-list input[type=password], .checkout-form-list input[type=text], .checkout-form-list input[type=number] {
    background: #fff;
    border: 1px solid #eaedff;
    border-radius: 0;
    height: 45px;
    padding: 0 0 0 10px;
    width: 100%;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.checkout-form-list input[type=email]:focus, .checkout-form-list input[type=password]:focus, .checkout-form-list input[type=text]:focus {
    border-color: #0e1133
}

.checkout-form-list input[type=email]::-webkit-input-placeholder, .checkout-form-list input[type=password]::-webkit-input-placeholder, .checkout-form-list input[type=text]::-webkit-input-placeholder {
    color: #6f7172;
    opacity: 1
}

.checkout-form-list input[type=email]::-moz-placeholder, .checkout-form-list input[type=password]::-moz-placeholder, .checkout-form-list input[type=text]::-moz-placeholder {
    color: #6f7172;
    opacity: 1
}

.checkout-form-list input[type=email]:-ms-input-placeholder, .checkout-form-list input[type=password]:-ms-input-placeholder, .checkout-form-list input[type=text]:-ms-input-placeholder {
    color: #6f7172;
    opacity: 1
}

.checkout-form-list input[type=email]::placeholder, .checkout-form-list input[type=password]::placeholder, .checkout-form-list input[type=text]::placeholder {
    color: #6f7172;
    opacity: 1
}

.checkout-form-list input[type=checkbox] {
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: 1px;
    display: none
}

.ship-different-title input {
    display: none
}

.create-acc label {
    color: #6f7172;
    display: inline-block
}

.ship-different-title .h3 label, .ship-different-title h3 label {
    display: inline-block;
    margin-right: 20px;
    color: #6f7172
}

.order-notes textarea {
    border: 1px solid #eaedff;
    height: 90px;
    width: 100%;
    padding: 5px 15px 15px
}

.order-notes textarea::-moz-placeholder {
    color: #6f7172;
    opacity: 1
}

.order-notes textarea::-webkit-input-placeholder {
    color: #6f7172;
    opacity: 1
}

.order-notes textarea:-ms-input-placeholder {
    color: #6f7172;
    opacity: 1
}

.order-notes textarea::placeholder {
    color: #6f7172;
    opacity: 1
}

.panel-default>.panel-heading, .panel-group .panel {
    border-radius: 0
}

.your-order {
    padding: 30px 40px 45px;
    border: 3px solid #eaedff
}

@media(max-width:767px) {
    .your-order {
        padding: 15px
    }
}

.your-order .h3, .your-order h3 {
    border-bottom: 1px solid #eaedff;
    font-size: 30px;
    margin: 0 0 20px;
    padding-bottom: 10px;
    width: 100%
}

.your-order-table table {
    background: none;
    border: 0;
    width: 100%
}

.your-order-table table td, .your-order-table table th {
    border-bottom: 1px solid #eaedff;
    border-right: none;
    color: #6f7172;
    font-size: 14px;
    padding: 15px 0;
    text-align: left
}

@media(max-width:767px) {
    .your-order-table table td, .your-order-table table th {
        padding-right: 10px
    }
}

.your-order-table table th {
    border-top: none;
    color: #6f7172;
    font-weight: 400;
    text-align: left;
    vertical-align: middle;
    white-space: nowrap;
    width: 250px
}

.panel-body>p {
    color: #222
}

.your-order-table table .shipping ul li input {
    position: relative;
    top: 0;
    margin-right: 4px
}

.your-order-table table .shipping ul li label {
    color: #6f7172
}

.your-order-table table .shipping th {
    vertical-align: top
}

.your-order-table table .order-total th {
    border-bottom: 0;
    font-size: 14px
}

.your-order-table table .order-total td {
    border-bottom: none
}

.your-order-table table tr.cart_item:hover {
    background: #f9f9f9
}

.your-order-table table tr.order-total td span {
    color: #0e1133;
    font-size: 18px;
    font-weight: 500
}

.payment-method {
    margin-top: 40px
}

.payment-method .accordion-item:last-of-type {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.payment-method .accordion-item {
    background-color: #fff;
    border: 0;
    border-bottom: 1px solid #f0f0f5
}

.payment-method .accordion-button {
    font-size: 16px;
    font-weight: 500;
    color: #0e1133;
    padding: 23px 0;
    border: none
}

.payment-method .accordion-button:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.payment-method .accordion-button:after {
    position: absolute;
    content: "";
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: Font Awesome\ 5 Pro;
    font-size: 16px;
    font-weight: 400;
    margin-left: 0;
    background-image: none
}

.payment-method .accordion-button:not(.collapsed) {
    color: #0e1133;
    background-color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none
}

.payment-method .accordion-button:not(.collapsed):after {
    content: ""
}

.payment-method .accordion-body {
    padding: 8px 0 40px
}

.payment-method .accordion-collapse {
    border: none
}

.panel-title>a {
    display: block
}

.order-button-payment input {
    background: #232323 none repeat scroll 0 0;
    border: none;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    height: 40px;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    -webkit-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    width: 100%
}

.order-button-payment input:hover {
    background: #3e976c none repeat scroll 0 0
}

.payment-method .btn-link {
    -moz-user-select: none;
    background: no-repeat;
    border: none;
    border-radius: 0;
    color: #444;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 1;
    margin-bottom: 0;
    padding: 3px 10px;
    text-align: center;
    text-transform: uppercase;
    -webkit-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    vertical-align: middle;
    white-space: nowrap;
    text-decoration: none
}

.payment-method .card {
    background-color: #fff;
    border: 1px solid #eaedff;
    border-radius: 0;
    margin-bottom: 10px
}

.payment-method .accordion .card:first-of-type {
    border: 1px solid #eaedff
}

.card-header:first-child {
    border-radius: 0
}

.payment-method .card-header {
    background-color: #fff;
    border-bottom: 1px solid #eaedff
}

.order-button-payment button {
    width: 100%
}

.coupon-accordion .accordion__button, .payment-method .accordion__button {
    background: none;
    padding: 0;
    color: inherit;
    position: relative
}

.coupon-accordion .accordion-button:after, .payment-method .accordion-button:after {
    display: none
}

.coupon-accordion .coupon-checkout-content, .coupon-accordion .coupon-content, .payment-method .coupon-checkout-content, .payment-method .coupon-content {
    display: block
}

.coupon-accordion .accordion__panel, .payment-method .accordion__panel {
    padding: 0
}

.coupon-accordion .coupon-info p.form-row input[type=checkbox], .payment-method .coupon-info p.form-row input[type=checkbox] {
    position: relative;
    top: 0;
    margin-right: 5px
}

.coupon-accordion .accordion__button:before, .payment-method .accordion__button:before {
    position: absolute;
    top: 19px;
    right: auto;
    z-index: 5;
    left: 14px
}

.payment-method .accordion-button:after {
    display: none
}

.payment-method .accordion__button:before {
    position: absolute;
    top: 27px;
    right: 0;
    z-index: 5;
    left: auto
}

.coupon-accordion .h3:before, .coupon-accordion h3:before {
    display: none
}

.services__item {
    position: relative;
    padding: 40px 40px 45px;
    -webkit-box-shadow: 0 30px 40px 0 rgba(3, 24, 128, .2);
    box-shadow: 0 30px 40px 0 rgba(3, 24, 128, .2);
    border-radius: 6px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: transform .3s cubic-bezier(.21, .6, .44, 2.18);
    -webkit-transition: -webkit-transform .3s cubic-bezier(.21, .6, .44, 2.18);
    transition: -webkit-transform .3s cubic-bezier(.21, .6, .44, 2.18);
    transition: transform .3s cubic-bezier(.21, .6, .44, 2.18);
    transition: transform .3s cubic-bezier(.21, .6, .44, 2.18), -webkit-transform .3s cubic-bezier(.21, .6, .44, 2.18)
}

@media only screen and (min-width:1200px)and (max-width:1399px) {
    .services__item {
        padding-left: 30px;
        padding-right: 30px
    }
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .services__item {
        padding-left: 15px;
        padding-right: 15px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .services__item {
        padding-left: 25px;
        padding-right: 25px
    }
}

.services__item:hover {
    -webkit-transform: translateY(-5px) translate3d(0, -5px, 0);
    transform: translateY(-5px) translate3d(0, -5px, 0)
}

.services__item:hover p {
    visibility: hidden;
    opacity: 0
}

.services__item:hover .link-btn-2 {
    bottom: 45px;
    visibility: visible;
    opacity: 1
}

@media(max-width:575px) {
    .services__item:hover .link-btn-2 {
        bottom: 30px
    }
}

.services__title {
    font-size: 22px;
    color: #fff;
    margin-bottom: 17px
}

.services__icon {
    margin-bottom: 43px
}

.services__icon svg {
    width: 50px;
    height: 50px
}

.services__icon svg path {
    fill: #fff
}

.services__content p {
    color: #fff;
    opacity: .8;
    line-height: 22px;
    margin-bottom: 0
}

.services__content .link-btn-2 {
    position: absolute;
    left: 40px;
    bottom: 20px;
    visibility: hidden;
    opacity: 0;
    color: #fff
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .services__content .link-btn-2 {
        left: 15px
    }
}

.services__content .link-btn-2:hover {
    border-color: #fff
}

.about__thumb img {
    width: 370px;
    border-radius: 6px
}

@media(max-width:575px) {
    .about__thumb img {
        width: 100%
    }
}

.about__thumb-wrapper {
    position: relative
}

@media(max-width:575px) {
    .about__banner {
        margin-top: -25px
    }
}

.about__banner img {
    border-radius: 6px;
    max-width: 240px
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .about__content {
        padding-left: 55px
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .about__content {
        margin-top: 50px;
        padding-left: 0
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .about__content {
        margin-top: 50px;
        padding-left: 0
    }
}

@media(max-width:575px) {
    .about__content {
        margin-top: 50px;
        padding-left: 0;
        padding-right: 0
    }

    .about__student {
        margin-top: 20px;
        margin-left: 0
    }
}

.about__student img {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 2px solid #fff;
    -webkit-box-shadow: 0 10px 20px 0 rgba(0, 5, 52, .1);
    box-shadow: 0 10px 20px 0 rgba(0, 5, 52, .1)
}

.about__student img+img {
    margin-left: -12px
}

.about__student p {
    margin-top: 4px;
    margin-bottom: 0
}

.about__student p span {
    font-weight: 600;
    color: #0e1133
}

.about__review {
    position: absolute;
    left: -25px;
    top: 100px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.about__review .h5, .about__review h5 {
    font-size: 16px;
    font-weight: 400;
    color: #6d6e75;
    margin-bottom: 0
}

.about__review .h5 span, .about__review h5 span {
    font-weight: 600;
    color: #0e1133
}

.about__list ul li {
    font-size: 16px;
    font-weight: 500;
    color: #0e1133;
    margin-bottom: 5px
}

.about__list ul li i {
    font-size: 16px;
    color: #30a820;
    padding-right: 9px
}

@media(max-width:575px) {
    .about__thumb.ml-100 {
        margin-left: 0
    }

    .about__banner.mt--210, .about__review {
        display: none
    }

    .about__student {
        margin-top: 30px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .about__thumb img {
        width: 100%
    }
}

.what__item {
    border-radius: 6px
}

.what__item:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(14, 17, 51, .5)
}

.what__item:hover:after {
    /* background: rgba(43, 78, 255, .9) */
    background: var(--bs-primary);
    filter: opacity(0.5);
}

.what__item:hover .what__thumb img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
}

.what__title {
    font-size: 30px;
    margin-bottom: 18px
}

@media(max-width:575px) {
    .what__title {
        font-size: 18px
    }
}

.what__content {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1
}

@media(max-width:575px) {
    .what__content {
        width: 90%
    }
}

.why__thumb {
    position: relative;
    z-index: 1;
    margin-left: -35px
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .why__thumb {
        margin-top: 50px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .why__thumb {
        margin-top: 50px
    }
}

@media(max-width:575px) {
    .why__thumb {
        margin-top: 50px
    }

    .why__thumb img {
        width: 100%
    }
}

.why__thumb img.why-green {
    position: absolute;
    z-index: -1;
    bottom: 0;
    right: 0
}

@media only screen and (min-width:1200px)and (max-width:1399px) {
    .why__thumb img.why-green {
        right: -23px
    }
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .why__thumb img.why-green {
        right: -19px
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .why__thumb img.why-green {
        right: -19px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .why__thumb img.why-green {
        right: 41px
    }
}

@media(max-width:575px) {
    .why__thumb img.why-green {
        right: 0;
        width: 100%
    }
}

.why__thumb img.why-pink {
    position: absolute;
    z-index: -2;
    bottom: -6%;
    left: 15%
}

@media(max-width:575px) {
    .why__thumb img.why-pink {
        width: 80%
    }
}

.why__thumb img.why-dot {
    position: absolute;
    right: 10%;
    bottom: 6%
}

@media(max-width:575px) {
    .why__thumb img.why-dot {
        width: inherit
    }
}

.why__thumb img.why-line {
    position: absolute;
    right: 0;
    top: 28%
}

@media(max-width:575px) {
    .why__thumb img.why-line {
        width: inherit
    }
}

.why__btn .link-btn {
    color: #0e1133
}

.why__btn .link-btn:hover {
    color: var(--bs-primary)
}

@media(max-width:575px) {
    .why__content {
        padding-right: 0
    }
}

@media (max-width:575px), only screen and (min-width:576px)and (max-width:767px), only screen and (min-width:768px)and (max-width:991px) {
    .product-quantity button {
        white-space: nowrap
    }
}

.counter__icon svg {
    height: 34px
}

.counter__icon.user svg {
    fill: #fc4d93
}

.counter__icon.book svg {
    fill: #ff8f21
}

.counter__icon.graduate svg {
    fill: #14c0da
}

.counter__icon.globe svg {
    fill: #516eff
}

.counter__content .h4, .counter__content h4 {
    font-size: 26px;
    margin-bottom: 0;
    line-height: 1
}

.counter__content p {
    font-weight: 500
}

.counter__pl-80 {
    padding-left: 80px
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .counter__pl-80 {
        padding-left: 30px
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .counter__pl-80 {
        padding-left: 0
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .counter__pl-80 {
        padding-left: 0
    }
}

@media(max-width:575px) {
    .counter__pl-80 {
        padding-left: 0
    }
}

.counter__pl-34 {
    padding-left: 34px
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .counter__pl-34 {
        padding-left: 0
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .counter__pl-34 {
        padding-left: 0
    }
}

@media(max-width:575px) {
    .counter__pl-34 {
        padding-left: 0
    }
}

.counter__pl-70 {
    padding-left: 70px
}

@media only screen and (min-width:1200px)and (max-width:1399px) {
    .counter__pl-70 {
        padding-left: 0
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .counter__pl-70 {
        padding-left: 0
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .counter__pl-70 {
        padding-left: 0
    }
}

@media(max-width:575px) {
    .counter__pl-70 {
        padding-left: 0
    }
}

.testimonial__area {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover
}

.testimonial__overlay {
    position: relative
}

.testimonial__overlay:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(43, 78, 255, .84)
}

.testimonial__slider .swiper-button-next, .testimonial__slider .swiper-button-prev {
    width: inherit
}

.testimonial__slider .swiper-button-next:after, .testimonial__slider .swiper-button-prev:after {
    display: inline-block;
    font-size: 14px;
    width: 50px;
    height: 50px;
    line-height: 46px;
    text-align: center;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, .2);
    background: transparent;
    color: #fff
}

.testimonial__slider .swiper-button-next:after:hover, .testimonial__slider .swiper-button-prev:after:hover {
    background: #fff;
    border-color: #fff;
    color: var(--bs-primary)
}

.testimonial__slider .swiper-nav i {
    display: inline-block;
    font-size: 14px;
    width: 50px;
    height: 50px;
    line-height: 46px;
    text-align: center;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, .2);
    background: transparent;
    color: #fff
}

.testimonial__slider .swiper-nav i:hover {
    background: #fff;
    border-color: #fff;
    color: var(--bs-primary)
}

.testimonial__slider .swiper-nav:after {
    display: none
}

.testimonial__slider-3 .testimonial-nav {
    width: 290px
}

.testimonial__slider-3 .testimonial-nav .swiper-slide {
    width: 70px
}

.testimonial__slider-3 .testimonial-nav .swiper-slide img {
    width: 70px;
    height: 70px;
    border-radius: 50%
}

.testimonial__slider-3 .testimonial-nav .swiper-slide.swiper-slide-thumb-active .testimonial__nav-thumb:after {
    border-color: rgba(255, 255, 255, .3)
}

.testimonial__slider-3 .swiper-container {
    margin-left: inherit;
    margin-right: inherit
}

.testimonial__nav-thumb {
    position: relative;
    width: 70px;
    height: 70px;
    margin: 10px
}

.testimonial__nav-thumb img {
    border-radius: 50%
}

.testimonial__nav-thumb:after {
    position: absolute;
    content: "";
    left: -10px;
    top: -10px;
    width: -webkit-calc(100% + 20px);
    width: calc(100% + 20px);
    height: -webkit-calc(100% + 20px);
    height: calc(100% + 20px);
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, .3)
}

.testimonial__item-3 p {
    font-size: 24px;
    line-height: 40px;
    color: #fff
}

.testimonial__title {
    font-size: 40px;
    color: #fff
}

.testimonial__thumb {
    margin-bottom: 30px
}

.testimonial__thumb img {
    width: 80px;
    height: 80px;
    border-radius: 50%
}

.testimonial__thumb-3 {
    height: 300px;
    width: 100%
}

.testimonial__thumb-3 iframe {
    width: 100%;
    height: 100%
}

.testimonial__content p {
    font-size: 16px;
    color: var(--bs-black-rgb);
    line-height: 22px;
    font-weight: 500;
    /* padding: 0 290px; */
    margin-bottom: 10px;
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .testimonial__content p {
        padding: 0 150px
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .testimonial__content p {
        padding: 0 80px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .testimonial__content p {
        padding: 0 80px
    }
}

@media(max-width:575px) {
    .testimonial__content p {
       
    font-size: 14px;
    line-height: 18px;

    }
}

.testimonial__info .h4, .testimonial__info h4 {
    font-size: 20px;
    color: var(--bs-black-rgb);
    margin-bottom: 0
}

.testimonial__info span {
    font-size: 14px;
    color: var(--bs-black-rgb);
    opacity: .7
}

.testimonial__info-2 .h4, .testimonial__info-2 h4 {
    font-size: 22px;
    color: #fff;
    display: inline-block
}

.testimonial__info-2 span {
    font-size: 18px;
    color: #e2ece9
}

.testimonial__video {
    border-radius: 6px;
    -webkit-box-shadow: 0 30px 70px 0 rgba(0, 32, 29, .14);
    box-shadow: 0 30px 70px 0 rgba(0, 32, 29, .14)
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .testimonial__video {
        margin-left: 0;
        margin-top: 50px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .testimonial__video {
        margin-left: 0;
        margin-top: 50px
    }
}

@media(max-width:575px) {
    .testimonial__video {
        margin-left: 0;
        margin-top: 50px
    }
}

.testimonial__video-icon span {
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 65px;
    border-radius: 50%;
    background: #0fa0dd;
    text-align: center
}

.testimonial__video-icon span i {
    font-size: 30px;
    color: #fff
}

.testimonial__video-content {
    background: #fff;
    padding: 30px 50px 60px 40px
}

.testimonial__video-text .h4, .testimonial__video-text h4 {
    font-size: 26px
}

.testimonial__video-text p {
    color: #53545b;
    margin-bottom: 0
}

.blog__item {
    border-radius: 6px;
    -webkit-box-shadow: 0 30px 40px 0 rgba(1, 11, 60, .1);
    box-shadow: 0 30px 40px 0 rgba(1, 11, 60, .1)
}

.blog__item:hover .blog__thumb img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
}

.blog__title {
    font-size: 20px;
    margin-bottom: 35px
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .blog__title {
        font-size: 18px
    }
}

.blog__title a:hover {
    color: var(--bs-primary)
}

.blog__content {
    padding: 30px 30px 35px
}

.blog__tag {
    margin-bottom: 13px
}

.blog__tag a {
    display: inline-block;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    color: #0fa0dd;
    background: rgba(15, 160, 221, .1);
    border-radius: 4px;
    font-weight: 500;
    padding: 0 6px
}

.blog__tag a:hover {
    background: #0fa0dd;
    color: #fff
}

.blog__tag a.purple {
    background: rgba(131, 48, 254, .1);
    color: #8330fe
}

.blog__tag a.purple:hover {
    background: #8330fe;
    color: #fff
}

.blog__tag a.pink {
    background: rgba(242, 39, 126, .1);
    color: #f2277e
}

.blog__tag a.pink:hover {
    background: #f2277e;
    color: #fff
}

.blog__tag a.green {
    background: rgba(48, 168, 32, .1);
    color: #30a820
}

.blog__tag a.green:hover {
    background: #30a820;
    color: #fff
}

.blog__tag a.orange {
    background: rgba(250, 121, 25, .1);
    color: #fa7919
}

.blog__tag a.orange:hover {
    background: #fa7919;
    color: #fff
}

.blog__tag a.blue {
    background: rgba(43, 78, 255, .1);
    color: var(--bs-primary)
}

.blog__tag a.blue:hover {
    background: var(--bs-primary);
    color: #fff
}

.blog__tag-2 a {
    display: inline-block;
    height: 30px;
    line-height: 32px;
    color: #53545b;
    font-weight: 500;
    background: #f3f4f8;
    padding: 0 12px;
    font-size: 15px;
    margin-right: 8px;
    border-radius: 4px
}

.blog__tag-2 a:hover {
    background: var(--bs-primary);
    color: #fff
}

.blog__author-thumb img {
    width: 35px;
    height: 35px;
    border: 2px solid #fff;
    border-radius: 50%;
    -webkit-box-shadow: 0 10px 14px 0 rgba(1, 11, 60, .1);
    box-shadow: 0 10px 14px 0 rgba(1, 11, 60, .1)
}

.blog__author-thumb-3 img {
    width: 50px;
    height: 50px;
    border-radius: 50%
}

.blog__author-info .h5, .blog__author-info h5 {
    font-size: 15px;
    font-weight: 500;
    color: #53545b;
    margin-bottom: 0
}

.blog__author-info-2 .h5, .blog__author-info-2 h5 {
    color: #fff
}

.blog__author-3 {
    padding: 30px 60px 35px 30px;
    border-radius: 4px
}

@media(max-width:575px) {
    .blog__author-content {
        margin-top: 25px
    }
}

.blog__author-content .h4, .blog__author-content h4 {
    font-size: 16px;
    margin-bottom: 0
}

.blog__author-content span {
    color: #53545b;
    display: inline-block;
    margin-bottom: 10px
}

.blog__author-content p {
    font-size: 20px;
    line-height: 1.2;
    color: #0e1133;
    margin-bottom: 0
}

.blog__date i {
    color: #53545b;
    margin-right: 10px;
    line-height: 1;
    -webkit-transform: translateY(1px);
    transform: translateY(1px)
}

.blog__date span {
    font-size: 14px;
    color: #53545b;
    font-weight: 500
}

.blog__date-2 i, .blog__date-2 span {
    color: #fff
}

.blog__text .h3, .blog__text h3 {
    font-size: 30px;
    margin-bottom: 12px
}

.blog__text p {
    font-size: 18px;
    line-height: 28px;
    color: #53545b;
    margin-bottom: 27px
}

.blog__quote {
    padding: 40px 50px;
    border-radius: 4px
}

@media(max-width:575px) {
    .blog__quote {
        padding: 20px 30px
    }
}

.blog__quote blockquote {
    margin-bottom: 0
}

.blog__quote p {
    font-size: 24px;
    color: #0e1133;
    font-weight: 500;
    line-height: 1.3;
    margin-bottom: 25px
}

.blog__quote .h4, .blog__quote h4 {
    font-size: 20px;
    padding-left: 30px;
    position: relative;
    margin-bottom: 0
}

.blog__quote .h4:after, .blog__quote h4:after {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 19px;
    height: 2px;
    background: #0e1133
}

.blog__quote img.quote {
    position: absolute;
    bottom: -34px;
    right: 50px
}

.blog__link p {
    font-size: 26px;
    line-height: 1.4;
    font-weight: 500;
    color: #0e1133
}

@media(max-width:575px) {
    .blog__link p {
        font-size: 24px
    }
}

.blog__link p a {
    color: var(--bs-primary)
}

.blog__img img {
    border-radius: 6px
}

@media(max-width:575px) {
    .blog__social {
        margin-top: 30px
    }
}

.blog__social .h4, .blog__social h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
    margin-right: 10px
}

.blog__social ul li {
    display: inline-block;
    margin-left: 5px
}

.blog__social ul li a {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 44px;
    text-align: center;
    font-size: 13px;
    color: #0e1133;
    background: #f3f4f8;
    border-radius: 4px
}

.blog__social ul li a.fb {
    color: #285da1;
    background: rgba(40, 93, 161, .1)
}

.blog__social ul li a.fb:hover {
    color: #fff;
    background: #285da1
}

.blog__social ul li a.tw {
    color: #03a9f4;
    background: rgba(3, 169, 244, .1)
}

.blog__social ul li a.tw:hover {
    color: #fff;
    background: #03a9f4
}

.blog__social ul li a.pin {
    color: #d8163f;
    background: rgba(216, 22, 63, .1)
}

.blog__social ul li a.pin:hover {
    color: #fff;
    background: #d8163f
}

.blog__line {
    margin-bottom: 28px;
    width: 100%;
    height: 1px;
    background: #f0f0f5
}

.blog__comment .h3, .blog__comment h3 {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 40px
}

.blog__comment-input input, .blog__comment-input textarea {
    width: 100%;
    height: 56px;
    line-height: 56px;
    border: 2px solid #f3f4f8;
    background: #f3f4f8;
    color: #0e1133;
    font-size: 15px;
    outline: none;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 0 24px
}

.blog__comment-input input::-webkit-input-placeholder, .blog__comment-input textarea::-webkit-input-placeholder {
    font-size: 15px;
    color: #6d6e75
}

.blog__comment-input input::-moz-placeholder, .blog__comment-input textarea::-moz-placeholder {
    font-size: 15px;
    color: #6d6e75
}

.blog__comment-input input:-ms-input-placeholder, .blog__comment-input textarea:-ms-input-placeholder {
    font-size: 15px;
    color: #6d6e75
}

.blog__comment-input input::placeholder, .blog__comment-input textarea::placeholder {
    font-size: 15px;
    color: #6d6e75
}

.blog__comment-input input:focus, .blog__comment-input textarea:focus {
    background: #fff;
    border-color: var(--bs-primary)
}

.blog__comment-input textarea {
    height: 180px;
    resize: none;
    line-height: 1.2;
    padding: 19px 23px 23px;
    margin-bottom: 13px
}

.blog__comment-agree {
    padding-left: 5px
}

@media(max-width:575px), only screen and (min-width:576px)and (max-width:767px) {
    .blog__comment-agree {
        display: inherit !important
    }
}

.blog__comment-agree input {
    margin: 0;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    display: block;
    width: 14px;
    height: 14px;
    background: #fff;
    border: 1px solid #b9bac1;
    outline: none;
    border-radius: 4px
}

@media(max-width:575px), only screen and (min-width:576px)and (max-width:767px) {
    .blog__comment-agree input {
        display: inline-block
    }
}

.blog__comment-agree input:checked {
    position: relative;
    background-color: var(--bs-primary);
    border-color: transparent
}

.blog__comment-agree input:checked:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    content: "";
    position: absolute;
    font-size: 10px;
    color: #fff;
    top: -1px;
    left: 3px;
    height: 10px;
    width: 6px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.blog__comment-agree input:hover {
    cursor: pointer
}

.blog__comment-agree label {
    padding-left: 8px;
    color: #53545b
}

@media(max-width:575px), only screen and (min-width:576px)and (max-width:767px) {
    .blog__comment-agree label {
        display: inline
    }
}

.blog__comment-agree label a {
    color: #0e1133;
    font-weight: 600;
    padding-left: 4px
}

.blog__comment-agree label a:hover {
    color: var(--bs-primary)
}

.blog__comment-agree label:hover {
    cursor: pointer
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .blog__sidebar {
        padding-left: 0
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .blog__sidebar {
        padding-left: 0;
        margin-top: 50px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .blog__sidebar {
        padding-left: 0;
        margin-top: 50px
    }
}

@media(max-width:575px) {
    .blog__sidebar {
        padding-left: 0;
        margin-top: 50px
    }
}

.sidebar__widget-title {
    font-size: 20px;
    padding-bottom: 7px;
    border-bottom: 1px solid #f0f0f5
}

.sidebar__search input {
    width: 100%;
    height: 60px;
    line-height: 56px;
    background: #f3f4f8;
    border: 2px solid #f3f4f8;
    border-radius: 4px;
    padding-left: 30px;
    padding-right: 45px
}

.sidebar__search input::-webkit-input-placeholder {
    color: #8d8e9a;
    font-weight: 500
}

.sidebar__search input::-moz-placeholder {
    color: #8d8e9a;
    font-weight: 500
}

.sidebar__search input:-ms-input-placeholder {
    color: #8d8e9a;
    font-weight: 500
}

.sidebar__search input::placeholder {
    color: #8d8e9a;
    font-weight: 500
}

.sidebar__search button {
    position: absolute;
    top: 55%;
    right: 20px;
    left: auto;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.sidebar__search button svg {
    width: 18px;
    height: 18px
}

.sidebar__search button svg .st0 {
    fill: #8c8faa
}

.sidebar__search button svg .st1 {
    fill: #0e1133
}

.sidebar__category ul li:not(:last-child) {
    margin-bottom: 15px
}

.sidebar__category ul li a {
    font-size: 16px;
    font-weight: 500;
    color: #53545b;
    padding-left: 20px;
    position: relative
}

.sidebar__category ul li a:after {
    position: absolute;
    content: "";
    left: 0;
    top: 9px;
    width: 6px;
    height: 6px;
    background: #adaeba;
    border-radius: 50%
}

.sidebar__category ul li a:hover {
    color: var(--bs-primary)
}

.sidebar__category ul li a:hover:after {
    background: var(--bs-primary)
}

.sidebar__tag a {
    display: inline-block;
    height: 36px;
    line-height: 38px;
    padding: 0 15px;
    font-size: 15px;
    font-weight: 500;
    color: #53545b;
    background: #f3f4f8;
    border-radius: 4px;
    margin-right: 8px;
    margin-bottom: 10px
}

.sidebar__tag a:hover {
    color: #fff;
    background: var(--bs-primary)
}

.rc__post:not(:last-child) {
    margin-bottom: 30px
}

.rc__meta span {
    color: #53545b;
    font-weight: 500;
    font-size: 15px;
    line-height: 1;
    display: inline-block;
    margin-bottom: 10px
}

.rc__thumb img {
    width: 75px;
    height: 75px;
    border-radius: 6px
}

.rc__title {
    font-size: 18px;
    color: #0e1133;
    margin-bottom: 0;
    font-weight: 600;
    line-height: 1.1
}

.rc__title a:hover {
    color: var(--bs-primary)
}

.latest-comments .h3, .latest-comments h3 {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 20px
}

.latest-comments ul li {
    margin-bottom: 10px
}

.latest-comments ul li.children {
    margin-left: 100px
}

@media(max-width:575px) {
    .latest-comments ul li.children {
        margin-left: 15px
    }
}

.latest-comments ul li ul li.children-2 {
    margin-left: 100px;
    margin-top: 10px
}

@media(max-width:575px) {
    .latest-comments ul li ul li.children-2 {
        margin-left: 15px
    }
}

.comments-box {
    border-radius: 4px;
    padding: 25px 40px 30px 30px
}

.comments-avatar img {
    width: 50px;
    height: 50px;
    border-radius: 50%
}

.avatar-name {
    margin-bottom: 5px
}

.avatar-name .h5, .avatar-name h5 {
    font-size: 16px;
    line-height: 1;
    margin-bottom: 0
}

.avatar-name span {
    font-size: 14px;
    color: #53545b
}

@media(max-width:575px) {
    .comments-text {
        margin-left: 0;
        margin-top: 15px
    }
}

.comments-text p {
    font-size: 16px;
    color: #53545b;
    margin-bottom: 15px
}

.comments-replay {
    margin-top: 10px
}

.comments-replay a {
    display: inline-block;
    color: var(--bs-primary);
    background: rgba(43, 78, 255, .1);
    height: 20px;
    line-height: 22px;
    padding: 0 8px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px
}

.comments-replay a:hover {
    color: #fff;
    background: var(--bs-primary)
}

.slider__wrapper .swiper-slide-active .slider__title, .slider__wrapper .swiper-slide-active a, .slider__wrapper .swiper-slide-active p, .slider__wrapper .swiper-slide-active span {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.slider__height {
    min-height: 950px
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .slider__height {
        min-height: 650px
    }
}

@media(max-width:575px) {
    .slider__height {
        min-height: 580px
    }
}

.slider__overlay {
    position: relative
}

.slider__overlay:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 3, 32, .66)
}

.slider__content {
    position: relative;
    z-index: 1
}

.slider__content span {
    font-size: 18px;
    color: #ffb352;
    margin-bottom: 12px;
    display: inline-block
}

.slider__content p {
    font-size: 22px;
    line-height: 30px;
    padding-right: 160px;
    margin-bottom: 28px;
    opacity: .7;
    color: #fff
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .slider__content p {
        padding-right: 0
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .slider__content p {
        padding-right: 0
    }
}

@media(max-width:575px) {
    .slider__content p {
        padding-right: 0
    }
}

.slider__content>span, .slider__content a, .slider__content p, .slider__title {
    position: relative;
    z-index: 1;
    padding-right: 25px;
    -webkit-transform: translateY(70px);
    transform: translateY(70px);
    -webkit-transition: 1s;
    transition: 1s
}

.slider__title {
    font-size: 70px;
    color: #fff;
    line-height: 1.05;
    margin-bottom: 5px
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .slider__title {
        font-size: 50px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .slider__title {
        font-size: 40px
    }
}

@media(max-width:575px) {
    .slider__title {
        font-size: 30px
    }
}

.slider__title span {
    font-size: 70px;
    color: #fff;
    position: relative;
    margin-bottom: 0
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .slider__title span {
        font-size: 50px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .slider__title span {
        font-size: 40px
    }
}

@media(max-width:575px) {
    .slider__title span {
        font-size: 30px
    }
}

.slider__title span img {
    position: absolute;
    left: -14px;
    bottom: 11px;
    z-index: -1;
    -webkit-animation: section-animation 3s infinite;
    animation: section-animation 3s infinite
}

@media(max-width:575px) {
    .slider__title span img {
        height: 15px;
        bottom: 0
    }
}

.slider__btn {
    height: 50px;
    line-height: 48px;
    border: 2px solid rgba(255, 255, 255, .3);
    padding: 0 22px;
    background: transparent
}

.slider__btn:hover {
    background: #fff;
    color: #000320;
    border-color: #fff
}

.slider__nav {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%
}

.slider__nav-item {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100px;
    -webkit-transition: height .3s ease-in-out;
    transition: height .3s ease-in-out;
    position: relative
}

.slider__nav-item:hover {
    cursor: pointer
}

.slider__nav-item:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(247, 100, 30, .8)
}

.slider__nav-item.blue-bg:after {
    background: rgba(45, 105, 240, .8)
}

.slider__nav-item.pink-bg:after {
    background: rgba(221, 36, 110, .8)
}

.slider__nav-item.green-bg:after {
    background: rgba(12, 174, 116, .8)
}

.slider__nav-content {
    position: relative;
    z-index: 1;
    padding: 23px 50px
}

@media only screen and (min-width:1200px)and (max-width:1399px) {
    .slider__nav-content {
        padding-left: 25px;
        padding-right: 25px
    }
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .slider__nav-content {
        padding: 15px
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .slider__nav-content {
        padding: 15px
    }
}

.slider__nav-content span {
    display: inline-block;
    font-size: 15px;
    color: #fff;
    opacity: .8
}

.slider__nav-content .h4, .slider__nav-content h4 {
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0
}

.swiper-thumbs {
    margin-bottom: 30px
}

@media (max-width:575px), only screen and (min-width:576px)and (max-width:767px), only screen and (min-width:768px)and (max-width:991px), only screen and (min-width:992px)and (max-width:1199px) {
    .slider__nav-item {
        display: none
    }
}

.swiper-fade .swiper-slide .swiper-slide {
    pointer-events: auto !important
}

.brand__title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 50px
}

.brand__more {
    margin-top: 52px
}

.brand__more .link-btn {
    color: #0e1133
}

.brand__more .link-btn:hover {
    color: var(--bs-primary)
}

.teacher__item {
    border-radius: 6px;
    padding: 30px
}

.teacher__item:hover {
    background: #fff;
    -webkit-box-shadow: 0 30px 40px 0 rgba(1, 11, 60, .1);
    box-shadow: 0 30px 40px 0 rgba(1, 11, 60, .1)
}

.teacher__item:hover .teacher__thumb img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
}

.teacher__content {
    padding-top: 25px
}

.teacher__content span {
    font-size: 16px;
    color: #53545b
}

.teacher__title {
    display: inline-block;
    font-size: 20px;
    margin-bottom: 10px
}

.teacher__social ul li {
    display: inline-block;
    margin: 0 5px
}

.teacher__social ul li a {
    display: inline-block;
    width: 36px;
    height: 36px;
    line-height: 36px;
    font-size: 13px;
    text-align: center;
    border: 2px solid #d7d9e3;
    border-radius: 4px;
    color: #0e1133
}

.teacher__social ul li a:hover {
    background: var(--bs-primary);
    color: #fff;
    border-color: var(--bs-primary)
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .teacher__social-2 {
        margin-bottom: 30px
    }
}

@media(max-width:575px) {
    .teacher__social-2 {
        margin-bottom: 30px
    }
}

.teacher__social-2 .h4, .teacher__social-2 h4 {
    font-size: 16px;
    font-weight: 500;
    color: #53545b;
    margin-bottom: 0
}

.teacher__social-2 ul li {
    display: inline-block
}

.teacher__social-2 ul li a {
    font-size: 14px;
    color: #898a93;
    margin-right: 8px
}

.teacher__social-2 ul li a:hover {
    color: var(--bs-primary)
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .teacher__rating {
        margin-bottom: 30px
    }
}

@media(max-width:575px) {
    .teacher__rating {
        margin-bottom: 30px
    }
}

.teacher__rating span {
    font-size: 16px
}

.teacher__rating span i {
    padding-right: 5px;
    color: #ff9415
}

.teacher__rating .h5, .teacher__rating h5 {
    font-size: 14px;
    color: #53545b;
    font-weight: 600;
    margin-bottom: 0
}

.teacher__rating ul li {
    display: inline-block
}

.teacher__rating ul li a {
    font-size: 14px;
    color: #ff9415
}

.teacher__rating-inner p {
    font-size: 16px;
    color: #0e1133;
    font-weight: 600;
    margin-bottom: 0;
    padding-left: 3px
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .teacher__info {
        margin-bottom: 30px
    }
}

@media(max-width:575px) {
    .teacher__info {
        margin-bottom: 30px
    }
}

.teacher__info .h4, .teacher__info h4 {
    font-size: 40px;
    margin-bottom: 0
}

.teacher__info span {
    font-size: 16px;
    color: #53545b
}

.teacher__follow-btn {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    text-align: center;
    padding: 0 25px;
    border: 2px solid #eef0f6;
    border-radius: 4px;
    color: #0e1133;
    font-weight: 16px;
    font-weight: 500;
    text-transform: capitalize;
    background: #fff
}

.teacher__follow-btn:hover {
    background: var(--bs-primary);
    color: #fff;
    border-color: var(--bs-primary)
}

.teacher__follow-btn i {
    font-size: 13px;
    padding-left: 2px
}

.teacher__top {
    padding-bottom: 20px
}

.teacher__bio, .teacher__top {
    border-bottom: 1px solid #e8eaf0
}

.teacher__bio {
    padding-bottom: 50px;
    padding-top: 35px
}

.teacher__bio .h3, .teacher__bio h3 {
    font-size: 20px;
    margin-bottom: 15px
}

.teacher__bio p {
    font-size: 16px;
    line-height: 26px;
    color: #53545b;
    margin-bottom: 0
}

.teacher__courses .section__title {
    font-size: 30px
}

.teacher__details-thumb img {
    border-radius: 6px
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .teacher__details-thumb {
        margin-bottom: 50px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .teacher__details-thumb {
        margin-bottom: 50px
    }
}

@media(max-width:575px) {
    .teacher__details-thumb {
        margin-bottom: 50px;
        padding-right: 0
    }
}

.teacher__details-shape img {
    position: absolute;
    z-index: -1;
    width: auto
}

.teacher__details-shape img.teacher-details-shape-1 {
    right: 0;
    bottom: 70px
}

.teacher__details-shape img.teacher-details-shape-2 {
    left: -27px;
    top: 55px
}

.page__title {
    font-size: 50px;
    color: #fff;
    line-height: 62px;
    margin-bottom: 0
}

@media(max-width:575px) {
    .page__title {
        font-size: 31px;
        line-height: 37px;
    }
    .page__title-wrapper .breadcrumb .breadcrumb-item a {
        font-size: 13px !important;
        font-weight: 300 !important;
        color: #fff;
    }
    .course__sidebar-title {
        font-size: 17px;
        margin-bottom: 27px;
        text-transform: capitalize;
        font-weight: 600 !important;
    }
    .course__sidebar-widget {
        border-radius: 4px;
        padding: 12px 20px;
        margin-bottom: 3px;
    }
}

.page__title-2 {
    font-size: 60px;
    line-height: 1.17;
    color: #fff;
    margin-bottom: 20px
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .page__title-2 {
        font-size: 50px
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .page__title-2 {
        font-size: 45px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .page__title-2 {
        font-size: 33px
    }
}

@media(max-width:575px) {
    .page__title-2 {
        font-size: 35px
    }
}

.page__title-3 {
    font-size: 50px;
    line-height: 1.1;
    margin-top: 12px
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .page__title-3 {
        font-size: 40px
    }
}

@media(max-width:575px) {
    .page__title-3 {
        font-size: 26px
    }
}

.page__title-pre {
    display: inline-block;
    height: 24px;
    line-height: 26px;
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    background: #30a820;
    border-radius: 4px;
    padding: 0 7px;
    margin-bottom: 12px
}

.page__title-pre.purple-bg {
    background: #b128ff
}

.page__title-wrapper {
    position: relative;
    z-index: 1
}

.page__title-wrapper .breadcrumb {
    margin-bottom: 0
}

.page__title-wrapper .breadcrumb .breadcrumb-item.active {
    font-size: 15px;
    color: #fff
}

.page__title-wrapper .breadcrumb .breadcrumb-item.active:before {
    content: ".";
    font-family: Hind, sans-serif;
    font-size: 30px;
    color: #fff;
    line-height: 18px;
    padding-right: 13px
}

.page__title-wrapper .breadcrumb .breadcrumb-item a {
    font-size: 15px;
    font-weight: 400;
    color: #fff
}

.page__title-wrapper .breadcrumb .breadcrumb-item+.breadcrumb-item {
    padding-left: 13px
}

.page__title-wrapper .breadcrumb .breadcrumb-item+.breadcrumb-item:before {
    content: ".";
    font-family: Hind, sans-serif;
    font-size: 30px;
    color: #fff;
    line-height: 18px;
    padding-right: 13px
}

.page__title-height {
    min-height: 450px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover
}

.page__title-height-2 {
    min-height: 700px
}

.page__title-overlay {
    position: relative
}

.page__title-overlay:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 3, 32, .6)
}

.page__title-shape img {
    position: absolute;
    z-index: 1
}

.page__title-shape img.page-title-shape-1 {
    left: 0;
    bottom: 105px
}

.page__title-shape img.page-title-shape-2 {
    left: 105px;
    top: 35%
}

@media only screen and (min-width:1400px)and (max-width:1600px) {
    .page__title-shape img.page-title-shape-2 {
        left: 40px
    }
}

@media only screen and (min-width:1200px)and (max-width:1399px) {
    .page__title-shape img.page-title-shape-2 {
        top: 28%
    }
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .page__title-shape img.page-title-shape-2 {
        top: 24%
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .page__title-shape img.page-title-shape-2 {
        top: 29%
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .page__title-shape img.page-title-shape-2 {
        left: 50px;
        top: 28%
    }
}

@media(max-width:575px) {
    .page__title-shape img.page-title-shape-2 {
        left: 30px;
        top: 28%
    }
}

.page__title-shape img.page-title-shape-3 {
    bottom: 35%;
    right: 0
}

.page__title-shape img.page-title-shape-4 {
    bottom: 41%;
    right: 0
}

.page__title-shape img.page-title-shape-5 {
    top: 61%;
    left: 0;
    z-index: -1
}

.page__title-shape img.page-title-shape-6 {
    top: 43%;
    left: 5%;
    z-index: -1
}

@media only screen and (min-width:1200px)and (max-width:1399px) {
    .page__title-shape img.page-title-shape-6 {
        top: 20%;
        left: 3%
    }
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .page__title-shape img.page-title-shape-6 {
        top: 20%;
        left: 3%
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .page__title-shape img.page-title-shape-6 {
        top: 20%;
        left: 3%
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .page__title-shape img.page-title-shape-6 {
        top: 18%;
        left: 3%
    }
}

@media(max-width:575px) {
    .page__title-shape img.page-title-shape-6 {
        top: 16%;
        left: 3%
    }
}

.page__title-shape img.page-title-shape-7 {
    top: 27%;
    right: 0;
    z-index: -1
}

.page__title-shape img.page-title-shape-8 {
    top: 38%;
    right: 25%;
    z-index: -1
}

.page__title-breadcrumb .breadcrumb {
    margin-bottom: 30px
}

.page__title-breadcrumb .breadcrumb .breadcrumb-item {
    font-size: 14px;
    font-weight: 400;
    color: #53545b
}

.page__title-breadcrumb .breadcrumb .breadcrumb-item+.breadcrumb-item:before, .page__title-breadcrumb .breadcrumb .breadcrumb-item.active:before {
    content: ".";
    font-family: Hind, sans-serif;
    font-size: 30px;
    color: #53545b;
    line-height: 18px;
    padding-right: 13px
}

.contact__form-input input, .contact__form-input textarea {
    width: 100%;
    height: 56px;
    line-height: 54px;
    padding: 0 23px;
    background: #f3f4f8;
    border-radius: 4px;
    border: 2px solid #f3f4f8;
    color: #0e1133;
    font-size: 15px;
    margin-bottom: 20px
}

.contact__form-input input::-webkit-input-placeholder, .contact__form-input textarea::-webkit-input-placeholder {
    font-size: 15px;
    color: #6d6e75
}

.contact__form-input input::-moz-placeholder, .contact__form-input textarea::-moz-placeholder {
    font-size: 15px;
    color: #6d6e75
}

.contact__form-input input:-ms-input-placeholder, .contact__form-input textarea:-ms-input-placeholder {
    font-size: 15px;
    color: #6d6e75
}

.contact__form-input input::placeholder, .contact__form-input textarea::placeholder {
    font-size: 15px;
    color: #6d6e75
}

.contact__form-input input:focus, .contact__form-input textarea:focus {
    border-color: var(--bs-primary);
    outline: none;
    background: #fff
}

.contact__form-input textarea {
    height: 180px;
    padding: 23px 25px;
    line-height: 1.1;
    resize: none;
    margin-bottom: 13px
}

.contact__form-agree {
    padding-left: 5px
}

.contact__form-agree input {
    margin: 0;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    display: block;
    width: 14px;
    height: 14px;
    background: #fff;
    border: 1px solid #b9bac1;
    outline: none;
    border-radius: 4px
}

.contact__form-agree input:checked {
    position: relative;
    background-color: var(--bs-primary);
    border-color: transparent
}

.contact__form-agree input:checked:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    content: "";
    position: absolute;
    font-size: 10px;
    color: #fff;
    top: 1px;
    left: 4px;
    height: 8px;
    width: 5px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.contact__form-agree input:hover {
    cursor: pointer
}

.contact__form-agree label {
    padding-left: 8px;
    color: #53545b
}

.contact__form-agree label a {
    color: #0e1133;
    font-weight: 600;
    padding-left: 4px
}

.contact__form-agree label a:hover {
    color: var(--bs-primary)
}

.contact__form-agree label:hover {
    cursor: pointer
}

.contact__info-inner {
    padding: 45px 70px 45px 40px;
    border-radius: 4px;
    -webkit-box-shadow: 0 30px 50px 0 rgba(1, 11, 60, .1);
    box-shadow: 0 30px 50px 0 rgba(1, 11, 60, .1);
    position: relative;
    z-index: 1
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .contact__info-inner {
        margin-top: 50px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .contact__info-inner {
        margin-top: 50px
    }
}

@media(max-width:575px) {
    .contact__info-inner {
        margin-top: 50px;
        padding-right: 35px
    }
}

.contact__info-icon svg {
    fill: none;
    stroke: var(--bs-primary);
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round
}

.contact__info-icon svg.map {
    width: 16px;
    height: 20px
}

.contact__info-icon svg.call, .contact__info-icon svg.mail {
    width: 18px;
    height: 18px
}

.contact__info-text .h4, .contact__info-text h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 6px
}

.contact__info-text p {
    margin-bottom: 0;
    color: #53545b
}

.contact__info-text p a:hover {
    color: var(--bs-primary)
}

.contact__social .h4, .contact__social h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 13px
}

.contact__social ul li {
    display: inline-block;
    margin-right: 10px
}

.contact__social ul li a {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 44px;
    text-align: center;
    font-size: 13px;
    color: #0e1133;
    background: #f3f4f8;
    border-radius: 4px
}

.contact__social ul li a.fb {
    color: #285da1;
    background: rgba(40, 93, 161, .1)
}

.contact__social ul li a.fb:hover {
    color: #fff;
    background: #285da1
}

.contact__social ul li a.tw {
    color: #03a9f4;
    background: rgba(3, 169, 244, .1)
}

.contact__social ul li a.tw:hover {
    color: #fff;
    background: #03a9f4
}

.contact__social ul li a.pin {
    color: #d8163f;
    background: rgba(216, 22, 63, .1)
}

.contact__social ul li a.pin:hover {
    color: #fff;
    background: #d8163f
}

.contact__icon {
    margin-bottom: 28px
}

.contact__icon svg {
    width: 70px;
    height: 70px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: transform .3s cubic-bezier(.21, .6, .44, 2.18);
    -webkit-transition: -webkit-transform .3s cubic-bezier(.21, .6, .44, 2.18);
    transition: -webkit-transform .3s cubic-bezier(.21, .6, .44, 2.18);
    transition: transform .3s cubic-bezier(.21, .6, .44, 2.18);
    transition: transform .3s cubic-bezier(.21, .6, .44, 2.18), -webkit-transform .3s cubic-bezier(.21, .6, .44, 2.18)
}

.contact__icon svg .st0 {
    fill: none;
    stroke: var(--bs-primary);
    stroke-width: .5;
    stroke-linecap: round;
    stroke-linejoin: round
}

.contact__item {
    padding: 50px 80px 62px;
    border-radius: 6px;
    -webkit-box-shadow: 0 40px 50px 0 rgba(1, 11, 60, .08);
    box-shadow: 0 40px 50px 0 rgba(1, 11, 60, .08);
    position: relative;
    z-index: 1
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .contact__item {
        padding-left: 30px;
        padding-right: 30px
    }
}

@media(max-width:575px) {
    .contact__item {
        padding-left: 20px;
        padding-right: 20px
    }
}

.contact__item:hover .contact__icon svg {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0)
}

.contact__title {
    font-size: 26px;
    margin-bottom: 8px
}

.contact__content p {
    font-size: 16px;
    color: #53545b;
    margin-bottom: 30px
}

.contact__shape img {
    position: absolute
}

.contact__shape img.contact-shape-1 {
    bottom: 75px;
    left: -30px;
    z-index: -1
}

.contact__shape img.contact-shape-2 {
    top: 30px;
    right: -30px
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .contact__shape img.contact-shape-2 {
        right: -20px
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .contact__shape img.contact-shape-2 {
        right: -20px
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .contact__shape img.contact-shape-2 {
        right: -20px
    }
}

@media(max-width:575px) {
    .contact__shape img.contact-shape-2 {
        right: 0
    }
}

.contact__shape img.contact-shape-3 {
    right: -45%;
    top: 50%
}

@media only screen and (min-width:1400px)and (max-width:1600px) {
    .contact__shape img.contact-shape-3 {
        right: -20%
    }
}

@media only screen and (min-width:1200px)and (max-width:1399px) {
    .contact__shape img.contact-shape-3 {
        right: -10%
    }
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .contact__shape img.contact-shape-3 {
        right: -5%
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .contact__shape img.contact-shape-3 {
        right: -5%
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .contact__shape img.contact-shape-3 {
        right: -5%
    }
}

@media(max-width:575px) {
    .contact__shape img.contact-shape-3 {
        right: 0
    }
}

.contact__shape img.contact-shape-4 {
    right: 180px;
    bottom: -21%
}

.contact__shape img.contact-shape-5 {
    left: 0;
    bottom: 142px
}

.error__title {
    font-size: 70px;
    line-height: .9;
    margin-bottom: 10px
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .error__title {
        font-size: 60px
    }
}

@media(max-width:575px) {
    .error__title {
        font-size: 37px
    }
}

.error__content p {
    font-size: 20px;
    color: #53545b;
    margin-bottom: 45px
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .error__thumb img {
        width: 100%
    }
}

@media(max-width:575px) {
    .error__thumb img {
        width: 100%
    }
}

.sign__wrapper {
    padding: 50px 70px 45px;
    margin: 0 30px;
    border-radius: 10px;
    -webkit-box-shadow: 0 40px 80px 0 rgba(2, 2, 26, .14);
    box-shadow: 0 40px 80px 0 rgba(2, 2, 26, .14);
    z-index: 11
}

@media(max-width:575px) {
    .sign__wrapper {
        margin: 0;
        padding: 30px 20px 25px
    }
}

.sign__shape img {
    position: absolute;
    z-index: -1
}

.sign__shape img.man-1 {
    left: 28%;
    top: 60%;
    z-index: -1
}

@media only screen and (min-width:1400px)and (max-width:1600px) {
    .sign__shape img.man-1 {
        left: 19%
    }
}

@media only screen and (min-width:1200px)and (max-width:1399px) {
    .sign__shape img.man-1 {
        left: 16%
    }
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .sign__shape img.man-1 {
        left: 7%
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .sign__shape img.man-1 {
        left: 2%
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .sign__shape img.man-1 {
        left: 1%
    }
}

@media(max-width:575px) {
    .sign__shape img.man-1 {
        display: none
    }
}

.sign__shape img.man-2 {
    right: 24%;
    top: 65%
}

@media only screen and (min-width:1400px)and (max-width:1600px) {
    .sign__shape img.man-2 {
        top: 55%;
        right: 20%
    }
}

@media only screen and (min-width:1200px)and (max-width:1399px) {
    .sign__shape img.man-2 {
        top: 60%;
        right: 15%
    }
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .sign__shape img.man-2 {
        top: 60%;
        right: 7%
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .sign__shape img.man-2 {
        top: 60%;
        right: 0;
        z-index: 1
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .sign__shape img.man-2 {
        top: 60%;
        right: 0;
        z-index: 1
    }
}

@media(max-width:575px) {
    .sign__shape img.man-2 {
        display: none
    }
}

.sign__shape img.man-2.man-22 {
    top: 50%
}

@media only screen and (min-width:1400px)and (max-width:1600px) {
    .sign__shape img.man-2.man-22 {
        top: 55%;
        right: 20%
    }
}

@media only screen and (min-width:1200px)and (max-width:1399px) {
    .sign__shape img.man-2.man-22 {
        top: 60%;
        right: 15%
    }
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .sign__shape img.man-2.man-22 {
        top: 60%;
        right: 7%
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .sign__shape img.man-2.man-22 {
        top: 60%;
        right: 0;
        z-index: 1
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .sign__shape img.man-2.man-22 {
        top: 60%;
        right: 0;
        z-index: 1
    }
}

.sign__shape img.circle {
    right: 32%;
    top: 38%;
    animation: signCircle 5s linear 0s infinite alternate;
    -webkit-animation: signCircle 5s linear 0s infinite alternate
}

@media only screen and (min-width:1400px)and (max-width:1600px) {
    .sign__shape img.circle {
        right: 25%
    }
}

@media only screen and (min-width:1200px)and (max-width:1399px) {
    .sign__shape img.circle {
        right: 22%
    }
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .sign__shape img.circle {
        right: 11%
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .sign__shape img.circle {
        right: 4%
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .sign__shape img.circle {
        right: 2%
    }
}

@media(max-width:575px) {
    .sign__shape img.circle {
        display: none
    }
}

.sign__shape img.dot {
    right: 34%;
    top: 43%;
    animation: signDot 5s linear 0s infinite alternate;
    -webkit-animation: signDot 5s linear 0s infinite alternate
}

@media only screen and (min-width:1400px)and (max-width:1600px) {
    .sign__shape img.dot {
        right: 29%
    }
}

@media only screen and (min-width:1200px)and (max-width:1399px) {
    .sign__shape img.dot {
        right: 26%
    }
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .sign__shape img.dot {
        right: 18%
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .sign__shape img.dot {
        right: 5%
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .sign__shape img.dot {
        right: 3%
    }
}

@media(max-width:575px) {
    .sign__shape img.dot {
        display: none
    }
}

.sign__shape img.bg {
    left: 33%;
    top: 26%;
    opacity: .06;
    max-width: 100%;
    animation: signBg 5s linear 0s infinite alternate;
    -webkit-animation: signBg 5s linear 0s infinite alternate
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .sign__shape img.bg {
        left: 10%
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .sign__shape img.bg {
        left: 0
    }
}

@media(max-width:575px) {
    .sign__shape img.bg {
        display: none
    }
}

.sign__shape img.zigzag {
    left: 32%;
    top: 43%;
    animation: signZigzag 5s linear 0s infinite alternate;
    -webkit-animation: signZigzag 5s linear 0s infinite alternate
}

@media only screen and (min-width:1400px)and (max-width:1600px) {
    .sign__shape img.zigzag {
        left: 24%
    }
}

@media only screen and (min-width:1200px)and (max-width:1399px) {
    .sign__shape img.zigzag {
        left: 23%
    }
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .sign__shape img.zigzag {
        left: 11%
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .sign__shape img.zigzag {
        left: 3%
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .sign__shape img.zigzag {
        left: 2%
    }
}

@media(max-width:575px) {
    .sign__shape img.zigzag {
        display: none
    }
}

.sign__shape img.flower {
    bottom: 13%;
    right: 30%
}

@media only screen and (min-width:1400px)and (max-width:1600px) {
    .sign__shape img.flower {
        right: 24%
    }
}

@media only screen and (min-width:1200px)and (max-width:1399px) {
    .sign__shape img.flower {
        right: 24%
    }
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .sign__shape img.flower {
        right: 12%
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .sign__shape img.flower {
        right: 3%
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .sign__shape img.flower {
        right: 1%
    }
}

@media(max-width:575px) {
    .sign__shape img.flower {
        right: 1%;
        bottom: 3%
    }
}

.sign__header {
    padding: 0 35px
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .sign__header {
        padding: 0 10px
    }
}

@media(max-width:575px) {
    .sign__header {
        padding: 0
    }
}

.sign__header p {
    margin-bottom: 0;
    color: #7a797f;
    position: relative;
    padding: 0 40px;
    display: inline-block
}

.sign__header p span {
    position: absolute;
    top: 40%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.sign__header p span:last-child {
    left: auto;
    right: 0
}

.sign__header p a:hover {
    color: var(--bs-primary)
}

.sign__social {
    display: block;
    height: 50px;
    background: #eeedf2;
    border-radius: 6px;
    position: relative;
    line-height: 52px;
    padding-left: 23px;
    z-index: 1;
    overflow: hidden;
    font-size: 16px
}

.sign__social:after {
    position: absolute;
    content: "";
    left: -55%;
    top: 45%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 220px;
    width: 220px;
    background: #3360bd;
    border-radius: 50%;
    z-index: -1
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .sign__social:after {
        width: 240px
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .sign__social:after {
        width: 290px
    }
}

@media(max-width:575px) {
    .sign__social:after {
        left: -62%;
        width: 270px
    }
}

.sign__social i {
    color: #fff;
    font-size: 16px;
    margin-right: 50px
}

@media(max-width:575px) {
    .sign__social i {
        margin-right: 35px
    }
}

.sign__social:hover {
    color: #fff
}

.sign__social:hover:after {
    width: 100%;
    height: 150%;
    left: 0;
    border-radius: 6px
}

.sign__social.g-plus:after {
    background: #e93e30
}

.sign__form .h5, .sign__form h5 {
    font-size: 16px;
    font-weight: 500;
    color: #0e1133;
    margin-bottom: 11px
}

.sign__input {
    position: relative
}

.sign__input input {
    width: 100%;
    height: 60px;
    line-height: 52px;
    padding: 0 50px;
    font-size: 14px;
    border: 2px solid transparent;
    background: #f6f6f7;
    color: #0e1133;
    border-radius: 6px
}

.sign__input input::-webkit-input-placeholder {
    color: #8e8c94
}

.sign__input input::-moz-placeholder {
    color: #8e8c94
}

.sign__input input:-ms-input-placeholder {
    color: #8e8c94
}

.sign__input input::placeholder {
    color: #8e8c94
}

.sign__input input:focus {
    outline: none;
    background: #fff;
    border-color: var(--bs-primary);
    -webkit-box-shadow: 0 1px 4px 0 rgba(8, 0, 42, .2);
    box-shadow: 0 1px 4px 0 rgba(8, 0, 42, .2)
}

.sign__input i {
    position: absolute;
    top: 51%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 25px;
    font-size: 14px;
    color: #8e8c94
}

.sign__agree input {
    margin: 0;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    display: block;
    width: 14px;
    height: 14px;
    background: transparent;
    border: 1px solid #7a797f;
    outline: none;
    border-radius: 4px
}

.sign__agree input:checked {
    position: relative;
    background-color: var(--bs-primary);
    border-color: transparent
}

.sign__agree input:checked:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    content: "";
    position: absolute;
    font-size: 10px;
    color: #fff;
    top: 0;
    left: 3px;
    height: 10px;
    width: 6px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.sign__agree input:hover {
    cursor: pointer
}

.sign__agree label {
    padding-left: 10px;
    font-size: 14px
}

.sign__agree label:hover {
    cursor: pointer
}

.sign__agree label a {
    color: #0e1133;
    font-weight: 500
}

.sign__agree label a:hover {
    color: var(--bs-primary)
}

.sign__forgot a {
    color: #7a797f
}

.sign__forgot a:hover {
    color: var(--bs-primary)
}

.sign__new p {
    color: #7a797f;
    margin-bottom: 0
}

.sign__new p a {
    color: var(--bs-primary)
}

@-webkit-keyframes signDot {
    0% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0)
    }

    to {
        transform: translateY(50px);
        -webkit-transform: translateY(50px);
        -moz-transform: translateY(50px);
        -ms-transform: translateY(50px);
        -o-transform: translateY(50px)
    }
}

@keyframes signDot {
    0% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0)
    }

    to {
        transform: translateY(50px);
        -webkit-transform: translateY(50px);
        -moz-transform: translateY(50px);
        -ms-transform: translateY(50px);
        -o-transform: translateY(50px)
    }
}

@-webkit-keyframes signZigzag {
    0% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0)
    }

    to {
        transform: translateY(50px);
        -webkit-transform: translateY(50px);
        -moz-transform: translateY(50px);
        -ms-transform: translateY(50px);
        -o-transform: translateY(50px)
    }
}

@keyframes signZigzag {
    0% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0)
    }

    to {
        transform: translateY(50px);
        -webkit-transform: translateY(50px);
        -moz-transform: translateY(50px);
        -ms-transform: translateY(50px);
        -o-transform: translateY(50px)
    }
}

@-webkit-keyframes signCircle {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes signCircle {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

.footer__widget-title {
    padding-top: 5px;
    font-size: 20px;
    color: #fff
}

.footer__widget-title-2 {
    color: #0e1133
}

.footer__widget-body p {
    font-size: 16px;
    line-height: 24px;
    color: #a1a2ab;
    margin-bottom: 30px
}

.footer__widget-body-2 p {
    color: #53545b
}

.footer__social ul li {
    display: inline-block
}

.footer__social ul li:not(:last-child) {
    margin-right: 10px
}

.footer__social ul li a {
    display: inline-block;
    font-size: 15px;
    width: 44px;
    height: 44px;
    line-height: 46px;
    text-align: center;
    border: 2px solid#40c351;
    background: #40c351;
    border-radius: 4px;
    color: #fff
}

.footer__social ul li a:hover {
    background: transparent;
    color: #40c351
}

.footer__social ul li a.tw {
    background: #03a9f4;
    border-color: #03a9f4
}

.footer__social ul li a.tw:hover {
    background: transparent;
    color: #03a9f4
}

.footer__social ul li a.pin {
    background: #d2173f;
    border-color: #d2173f
}

.footer__social ul li a.pin:hover {
    background: transparent;
    color: #d2173f
}

.footer__link ul li:not(:last-child) {
    margin-bottom: 4px
}

.footer__link ul li a {
    color: #a1a2ab
}

.footer__link ul li a:hover {
    color: #fff
}

.footer__link-2 ul li a {
    color: #53545b
}

.footer__link-2 ul li a:hover {
    color: var(--bs-primary)
}

.footer__subscribe-input {
    position: relative
}

.footer__subscribe-input input {
    width: 100%;
    height: 60px;
    padding: 0 50px 0 30px;
    border-radius: 4px;
    border: none;
    outline: none
}

.footer__subscribe-input input::-webkit-input-placeholder {
    font-size: 14px;
    color: #6d6e75
}

.footer__subscribe-input input::-moz-placeholder {
    font-size: 14px;
    color: #6d6e75
}

.footer__subscribe-input input:-ms-input-placeholder {
    font-size: 14px;
    color: #6d6e75
}

.footer__subscribe-input input::placeholder {
    font-size: 14px;
    color: #6d6e75
}

.footer__subscribe-input button {
    top: 0;
    right: 0;
    font-size: 18px;
    color: #fff;
    background: var(--bs-primary);
    width: 70px;
    height: 60px;
    padding-right: 25px;
    overflow: hidden
}

.footer__subscribe-input button, .footer__subscribe-input button i {
    position: absolute;
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease
}

.footer__subscribe-input button i {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    line-height: 0px
}

.footer__subscribe-input button i:last-child {
    left: 0;
    visibility: hidden;
    opacity: 0
}

.footer__subscribe-input button:hover i {
    left: 100%;
    visibility: hidden;
    opacity: 0
}

.footer__subscribe-input button:hover i:last-child {
    left: 50%;
    visibility: visible;
    opacity: 1
}

.footer__subscribe p {
    font-size: 14px;
    line-height: 20px;
    color: #83848a;
    padding-right: 60px
}

.footer__subscribe-2 p {
    color: #6d6e75
}

.footer__pl-70 {
    padding-left: 70px
}

@media only screen and (min-width:992px)and (max-width:1199px) {
    .footer__pl-70 {
        padding-left: 0
    }
}

@media only screen and (min-width:768px)and (max-width:991px) {
    .footer__pl-70 {
        padding-left: 0
    }
}

@media only screen and (min-width:576px)and (max-width:767px) {
    .footer__pl-70 {
        padding-left: 0
    }
}

@media(max-width:575px) {
    .footer__pl-70 {
        padding-left: 0
    }
}

.footer__bottom {
    padding: 20px 0;
    border-top: 1px solid #1c1f3f
}

.footer__bottom-2 {
    border-color: #e0e1e8
}

.footer__copyright p {
    font-size: 14px;
    color: #83848a;
    margin-bottom: 0
}

.footer__copyright p a {
    color: #fff
}

.footer__copyright-2 p {
    color: #6d6e75
}

.footer__copyright-2 p a {
    color: var(--bs-primary)
}