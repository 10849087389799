h1,
h2,
h3,
h4,
h5,
h6,
p,
span label {
  text-transform: capitalize;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.required {
  color: red;
}

.text-black {
  color: #000 !important;
}

@media screen and (min-width:786px) {
  .footer-pages-link {
    display: flex;
    justify-content: center;
  }
}

.country-img {
  height: 15rem;
  color: #ffffff;
  filter: 1;
  display: flex;
  align-items: flex-end;
  font-size: 1.5rem;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  box-shadow: 0px -20px 28px #000000ab inset !important;
  font-weight: 800;
  padding-left: 10px !important;
}

input[type=file] {
  cursor: pointer;
  left: 12px;
  top: 0;
  opacity: 0;
  position: absolute;
  font-size: inherit;
  width: 6.5rem;
  height: 2.25rem;
}

@media screen and (max-width:600px) {
  .university-intake-container {
    flex-direction: column;
    display: grid;
    margin-bottom: 50px;
    gap: 0.75rem;
  }

  .hero-banner {
    height: 18rem;
    object-fit: cover;
  }
}

@media screen and (min-width:600px) {
  .university-intake-container {
    /* max-width: 30rem;
    display: flex;
    justify-content: space-between; */
    margin-bottom: 40px;
  }

  .hero-banner {
    height: 50rem;
    object-fit: cover;
  }
}

/* @media screen and (min-width:768px) {
  .profile{

  }
} */

@media screen and (max-width:767px) {

  .profile {
    position: fixed;
    z-index: 100;
    background: white;
    min-height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    transform: translateY(100%);
    transition: all .75s cubic-bezier(.785, .135, .15, .86);
    padding: 0 !important;
  }

  .profile-active {
    position: fixed;
    z-index: 100;
    background: white;
    min-height: 100vh;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    transform: translateY(0%);
    transition: all .75s cubic-bezier(.785, .135, .15, .86);
    padding: 0 !important;
    overflow-y: scroll;
  }
}

.testimonial-container {
  background: #F7FDFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px #00000015;
  border-radius: 10px;
  opacity: 1;
  height: 100%;
  min-height: 15rem;
  width: 100%;
}

.testimonial-user-img {
  border-radius: 5rem;
  width: 5rem;
  height: 5rem;
  object-fit: cover;
}

.testimonial-user-img::before {
  content: '';
  position: absolute;
  background: #0088ff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

}

.slick-center .testimonial {
  display: flex !important;
  justify-content: center !important;
  width: 60% !important;
  margin: auto !important;
}

.testimonial {
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
  margin: auto !important;
}

/* .slick-center{
  width: 8% !important;
} */

.testimonial__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
}

.quotes--icon {
  width: 178.05px;
  height: 185.627px;
}

.testimonial__profile {
  display: flex;
}

@media screen and (max-width:786px) {
  .quotes--icon {
    width: 78.05px;
    height: 85.627px;
  }

  .testimonial__profile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .testimonial__info {
    align-items: center;
    margin-left: unset;
    margin-top: 5px;
  }

  .testimonial p {
    text-align: center;
    line-height: 15px;
    font-size: 14px;
  }

  .testimonial-container {
    min-height: 24rem;
  }

  .slick-center .testimonial {
    display: flex !important;
    justify-content: center !important;
    width: 85% !important;
    margin: auto !important;
  }



}

@media screen and (min-width:800px) {

  .slick-next:before,
  .slick-prev:before {
    font-size: 25px !important;
    line-height: 1;
    opacity: 1.75;
    color: #0095c7 !important;
  }

  .slick-prev {
    left: 150px !important;
    z-index: 1000;
  }

  .slick-next {
    right: 150px !important;
  }
}


.h-400 {
  min-height: 400px;
  height: 100%;
}

.h-300 {
  min-height: 300px;
  height: 100%;
}

.h-200 {
  min-height: 200px;
  height: 100%;
}

.h-100 {
  min-height: 100px;
  height: 100%;
}

.h-75 {
  min-height: 75px;
  height: 100%;
}

.h-50 {
  min-height: 50px;
  height: 100%;
}

.password-eye-container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin: auto 0px;
  right: 0px;
  display: flex;
  cursor: pointer;
}

.hero-banners {
  position: relative;
}

.banner-click-button {
  display: none;
  position: absolute;
  /* top: 10px;
  bottom: 0px;
  margin: auto 0px;
  right: 0px;
  display: flex; */
  bottom: 10%;
  right: 50%;
  cursor: pointer;
  z-index: 100;
}